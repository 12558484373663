import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Bell,
  ChevronRight,
  Home,
  Calendar,
  Briefcase,
  Heart,
  MessageSquare,
  Search,
  Sparkles,
} from "lucide-react";
import { Link } from "@tanstack/react-router";

export const Route = createFileRoute("/_provider/provider/shifts/new/")({
  component: ShiftMenu,
});

// Stub data fetch function
const fetchDashboardData = async () => {
  // In a real app, this would be an API call
  return {
    username: "Dr. Smith",
    activeApplications: 3,
    potentialMatches: 5,
  };
};

function ShiftMenu() {
  // Using TanStack Query to fetch dashboard data
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardData"],
    queryFn: fetchDashboardData,
    initialData: {
      username: "Dr. Smith",
      activeApplications: 3,
      potentialMatches: 5,
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <div className="p-2 pt-4 md:pt-8 md:p-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Shifts</h2>

      {/* Dashboard Cards */}
      <div className="space-y-4">
        {/* Active Applications */}
        {/* <Link
          to="/provider/applications"
          className="bg-white rounded-lg shadow p-6 block hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="View active applications"
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center mr-4">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                >
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    stroke="#10B981"
                    strokeWidth="2"
                  />
                  <path
                    d="M7 12L10 15L17 8"
                    stroke="#10B981"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <h2 className="text-xl font-bold text-gray-800">
                  Active Applications
                </h2>
                <p className="text-gray-600">
                  {data.activeApplications} in progress
                </p>
              </div>
            </div>
            <ChevronRight
              className="text-gray-400"
              size={24}
              aria-hidden="true"
            />
          </div>
        </Link> */}

        {/* Potential Matches */}
        <Link
          to="/provider/shifts/potential"
          className="bg-white rounded-lg shadow p-6 block hover:bg-blue-50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 border-2 border-blue-100"
          aria-label="View potential matches"
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mr-4">
                <Sparkles
                  className="text-blue-600"
                  size={24}
                  aria-hidden="true"
                />
              </div>
              <div>
                <h2 className="text-xl font-bold text-blue-700">
                  Potential Matches
                </h2>
                <div className="flex items-center">
                  <span className="bg-blue-100 text-blue-700 font-semibold px-2 py-1 rounded-md mr-2">
                    {data.potentialMatches} new
                  </span>
                  <p className="text-gray-700">
                    High-paying opportunities waiting for you!
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center">
              <ChevronRight
                className="text-blue-500"
                size={24}
                aria-hidden="true"
              />
            </div>
          </div>
        </Link>

        {/* Search Jobs */}
        {/* <Link
          to="/provider/search"
          className="bg-white rounded-lg shadow p-6 block hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="Search positions"
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-orange-100 flex items-center justify-center mr-4">
                <Search
                  className="text-orange-600"
                  size={24}
                  aria-hidden="true"
                />
              </div>
              <div>
                <h2 className="text-xl font-bold text-gray-800">
                  Search All Positions
                </h2>
                <p className="text-gray-600">
                  Browse all positions. Find the perfect fit for you.
                </p>
              </div>
            </div>
            <ChevronRight
              className="text-gray-400"
              size={24}
              aria-hidden="true"
            />
          </div>
        </Link> */}

        {/* Shift Preferences */}
        <Link
          to="/provider/shifts/preferences"
          className="bg-white rounded-lg shadow p-6 block hover:bg-gray-50 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
          aria-label="Set shift preferences"
        >
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="w-12 h-12 rounded-full bg-purple-100 flex items-center justify-center mr-4">
                <Calendar
                  className="text-purple-600"
                  size={24}
                  aria-hidden="true"
                />
              </div>
              <div>
                <h2 className="text-xl font-bold text-gray-800">
                  Shift Preferences
                </h2>
                <p className="text-gray-600">Set availability</p>
              </div>
            </div>
            <ChevronRight
              className="text-gray-400"
              size={24}
              aria-hidden="true"
            />
          </div>
        </Link>
      </div>
    </div>
  );
}
