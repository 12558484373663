import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

type BusinessUnitUser = {
  id: string;
  business_unit_id: string;
  user_id: string;
  created_at: string;
};

export const createBusinessUnitUser = async ({
  businessUnitId,
  userId,
}: {
  businessUnitId: string;
  userId: string;
}) => {
  return callSupabaseFnFromClient<BusinessUnitUser>({
    fnName: "admin_create_business_unit_user",
    args: {
      p_business_unit_id: businessUnitId,
      p_user_id: userId,
    },
  });
};
