import { createFileRoute } from "@tanstack/react-router";
import React, { useState, useRef, useEffect } from "react";
import { Building2, MapPin, Clock, Info, X, Check } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import RejectMatchDialog from "@/features/shifts/RejectMatchDialog";

export const Route = createFileRoute("/_provider/provider/shifts/potential/")({
  component: JobPostingDetail,
});

interface JobDetail {
  id: string;
  title: string;
  jobType: string;
  hourlyRate: number;
  facility: string;
  location: string;
  schedule: string;
  responsibilities: string[];
  qualifications: string[];
  coverageDates: string;
  workDays: string[];
  matchNumber: number;
  totalPositions: number;
}

// Stub API functions
const fetchJobDetail = async (jobId: string): Promise<JobDetail> => {
  // In a real app, this would be an API call with the jobId
  return {
    id: "job-123",
    title: "Urgent Care Physician",
    jobType: "Part-time",
    hourlyRate: 225,
    facility: "CityHealth Urgent Care",
    location: "Oakland, CA",
    schedule: "Weekend Coverage (10AM-8PM)",
    responsibilities: [
      "Diagnose and treat acute illnesses and injuries",
      "Perform minor surgical procedures",
      "Order and interpret diagnostic tests",
      "Maintain accurate medical records",
      "Collaborate with specialists and staff",
    ],
    qualifications: [
      "MD/DO with current license",
      "2+ years urgent care experience",
      "BLS/ACLS certification",
    ],
    coverageDates: "Feb 15 - Mar 1, 2025",
    workDays: ["Sat", "Sun"],
    matchNumber: 2,
    totalPositions: 5,
  };
};

type SwipeDirection = "left" | "right" | null;
type SwipeResult = "apply" | "reject" | null;

function JobPostingDetail() {
  // Use TanStack Query to fetch job details
  const {
    data: job,
    isLoading,
    isError,
  } = useQuery<JobDetail>({
    queryKey: ["jobDetail", "job-123"],
    queryFn: () => fetchJobDetail("job-123"),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  // Swipe functionality
  const [swipeDistance, setSwipeDistance] = useState(0);
  const [swiping, setSwiping] = useState(false);
  const [swipeResult, setSwipeResult] = useState<SwipeResult>(null);
  const [swipeDirection, setSwipeDirection] = useState<SwipeDirection>(null);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const startXRef = useRef(0);
  const cardRef = useRef<HTMLDivElement>(null);
  const currentSwipeDistanceRef = useRef(0);

  const SWIPE_THRESHOLD = 150; // Distance in pixels required for a successful swipe

  const handleTouchStart = (e: React.TouchEvent) => {
    startXRef.current = e.touches[0].clientX;
    setSwiping(true);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!swiping) return;
    const currentX = e.touches[0].clientX;
    const distance = currentX - startXRef.current;
    setSwipeDistance(distance);
    currentSwipeDistanceRef.current = distance;

    // Update swipe direction for visual feedback
    if (distance > 0) {
      setSwipeDirection("right");
    } else if (distance < 0) {
      setSwipeDirection("left");
    } else {
      setSwipeDirection(null);
    }
  };

  const handleTouchEnd = () => {
    setSwiping(false);
    const distance = currentSwipeDistanceRef.current;

    // Determine if swipe was far enough for an action
    if (distance > SWIPE_THRESHOLD) {
      // Swipe right - apply
      setSwipeResult("apply");
      setTimeout(() => {
        if (job) alert(`Applied to ${job.title} position`);
        setSwipeDistance(0);
        currentSwipeDistanceRef.current = 0;
        setSwipeResult(null);
        setSwipeDirection(null);
      }, 500);
    } else if (distance < -SWIPE_THRESHOLD) {
      // Swipe left - reject
      setSwipeResult("reject");
      setTimeout(() => {
        setSwipeDistance(0);
        currentSwipeDistanceRef.current = 0;
        setSwipeResult(null);
        setSwipeDirection(null);
        setShowRejectDialog(true);
      }, 500);
    } else {
      // Not far enough - reset with animation
      setSwipeDistance(0);
      currentSwipeDistanceRef.current = 0;
      setSwipeDirection(null);
    }
  };

  // Add mouse support for desktop testing
  const handleMouseDown = (e: React.MouseEvent) => {
    startXRef.current = e.clientX;
    setSwiping(true);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!swiping) return;
    e.preventDefault(); // Prevent text selection during swipe

    // Calculate the distance from the starting point
    const distance = e.clientX - startXRef.current;

    // Directly update the state with the calculated distance
    setSwipeDistance(distance);
    currentSwipeDistanceRef.current = distance;

    // Update swipe direction for visual feedback
    if (distance > 0) {
      setSwipeDirection("right");
    } else if (distance < 0) {
      setSwipeDirection("left");
    } else {
      setSwipeDirection(null);
    }

    // For debugging
    console.log(
      "Mouse move distance:",
      distance,
      "Current swipeDistance:",
      currentSwipeDistanceRef.current,
    );
  };

  const handleMouseUp = () => {
    if (!swiping) return;

    // Log the current swipe distance for debugging
    console.log("Mouse up swipeDistance:", currentSwipeDistanceRef.current);
    const distance = currentSwipeDistanceRef.current;

    // Determine if swipe was far enough for an action
    if (distance > SWIPE_THRESHOLD) {
      // Swipe right - apply
      setSwipeResult("apply");
      setTimeout(() => {
        if (job) alert(`Applied to ${job.title} position`);
        setSwipeDistance(0);
        currentSwipeDistanceRef.current = 0;
        setSwipeResult(null);
        setSwipeDirection(null);
      }, 500);
    } else if (distance < -SWIPE_THRESHOLD) {
      // Swipe left - reject
      setSwipeResult("reject");
      setTimeout(() => {
        setSwipeDistance(0);
        currentSwipeDistanceRef.current = 0;
        setSwipeResult(null);
        setSwipeDirection(null);
        setShowRejectDialog(true);
      }, 500);
    } else {
      // Not far enough - reset with animation
      setSwipeDistance(0);
      currentSwipeDistanceRef.current = 0;
      setSwipeDirection(null);
    }

    setSwiping(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // Clean up event listeners if component unmounts while swiping
  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const getSwipeStyles = () => {
    // Calculate transform and opacity based on swipe distance
    const rotate = swipeDistance / 20; // Slight rotation for visual feedback
    const opacity = Math.max(1 - Math.abs(swipeDistance) / 400, 0.5);

    // Apply color based on swipe direction
    let backgroundColor = "white";
    if (
      swipeResult === "apply" ||
      (Math.abs(swipeDistance) > 50 && swipeDirection === "right")
    ) {
      backgroundColor = "rgba(0, 255, 0, 0.05)";
    } else if (
      swipeResult === "reject" ||
      (Math.abs(swipeDistance) > 50 && swipeDirection === "left")
    ) {
      backgroundColor = "rgba(255, 0, 0, 0.05)";
    }

    // If swiping past threshold, show more intense background
    if (Math.abs(swipeDistance) > SWIPE_THRESHOLD) {
      backgroundColor =
        swipeDirection === "right"
          ? "rgba(0, 255, 0, 0.1)"
          : "rgba(255, 0, 0, 0.1)";
    }

    // For debugging
    console.log(
      "Applying styles with swipeDistance:",
      swipeDistance,
      "Ref value:",
      currentSwipeDistanceRef.current,
    );

    return {
      transform: `translateX(${swipeDistance}px) rotate(${rotate}deg)`,
      opacity: opacity,
      transition: swiping ? "none" : "transform 0.5s ease, opacity 0.5s ease",
      backgroundColor,
      boxShadow:
        Math.abs(swipeDistance) > 50
          ? `0 10px 15px -3px ${swipeDirection === "right" ? "rgba(0, 255, 0, 0.1)" : "rgba(255, 0, 0, 0.1)"}`
          : "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
    };
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-lg">Loading job details...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-lg text-red-500">
          Error loading job details. Please try again.
        </p>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-lg text-red-500">No job details found.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-gray-50 justify-center items-center min-h-screen py-4">
      {/* Header */}
      <div className="w-full max-w-md px-4 mb-3">
        <h1 className="text-base font-medium text-gray-600">
          {job.matchNumber} of {job.totalPositions} opportunities
        </h1>
      </div>

      {/* Job Card */}
      <div
        ref={cardRef}
        className="bg-white rounded-lg shadow-lg w-full max-w-md mx-4 mb-6 cursor-grab active:cursor-grabbing select-none"
        style={getSwipeStyles()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
      >
        <div className="p-5">
          {/* Job Title */}
          <h2 className="text-2xl font-bold text-gray-800 mb-3">{job.title}</h2>

          {/* Job Type and Rate */}
          <div className="flex items-center mb-4">
            <span className="bg-purple-100 text-purple-700 px-3 py-1 rounded-full text-sm mr-3">
              {job.jobType}
            </span>
            <span className="text-green-600 font-bold">
              ${job.hourlyRate}/hour
            </span>
          </div>

          {/* Company and Location */}
          <div className="space-y-2 mb-4">
            <div className="flex items-center text-gray-600">
              <Building2 className="mr-2" size={18} />
              <span>{job.facility}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <MapPin className="mr-2" size={18} />
              <span>{job.location}</span>
            </div>
            <div className="flex items-center text-gray-600">
              <Clock className="mr-2" size={18} />
              <span>In Person</span>
            </div>
          </div>

          {/* Coverage Dates */}
          <div className="mb-4">
            <h3 className="font-bold text-gray-800 mb-1">Coverage Dates</h3>
            <p className="text-gray-600">{job.coverageDates}</p>
          </div>

          {/* Schedule */}
          <div className="mb-4">
            <h3 className="font-bold text-gray-800 mb-1">Schedule</h3>
            <p className="text-gray-600">{job.workDays.join(", ")}: 10AM-8PM</p>
          </div>

          {/* Learn More Button */}
          <Button
            onClick={() => alert(`Learn more about ${job.title}`)}
            variant="outline"
            className="w-full"
          >
            <Info size={18} className="mr-2" />
            <span className="font-medium">Learn more about this position</span>
          </Button>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-center space-x-8 mt-2">
        <button
          onClick={() => {
            setSwipeResult("reject");
            setTimeout(() => {
              setSwipeResult(null);
              setShowRejectDialog(true);
            }, 500);
          }}
          className="w-14 h-14 bg-red-100 rounded-full flex items-center justify-center"
        >
          <X size={24} className="text-red-500" />
        </button>
        <button
          onClick={() => {
            setSwipeResult("apply");
            setTimeout(() => {
              alert(`Applied to ${job.title} position`);
              setSwipeResult(null);
            }, 500);
          }}
          className="w-14 h-14 bg-green-100 rounded-full flex items-center justify-center"
        >
          <Check size={24} className="text-green-500" />
        </button>
      </div>

      {/* Rejection Dialog */}
      {showRejectDialog && <RejectMatchDialog />}
    </div>
  );
}
