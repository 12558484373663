import { createFileRoute, redirect } from "@tanstack/react-router";
import React from "react";
import { useNavigate } from "@tanstack/react-router";
import { Stethoscope, ClipboardList } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const LOGO_PATH = "/static/images/logo.png";

export const Route = createFileRoute("/")({
  beforeLoad: () => {
    throw redirect({
      to: "/provider/home",
    });
  },

  // component: HomePage,
});

function HomePage() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-2xl mx-auto space-y-6">
        <div className="text-center space-y-4">
          <img
            src={LOGO_PATH}
            alt="Saile Company Logo"
            width={120}
            height={48}
            className="mx-auto"
          />
          <h1 className="text-3xl font-bold text-gray-900">Select Your Role</h1>
          <p className="text-gray-600 text-lg">
            Choose how you'll be using Saile
          </p>
        </div>

        <div className="space-y-8">
          <Card className="p-6 text-center space-y-4">
            <Stethoscope className="w-12 h-12 mx-auto text-blue-600" />
            <h2 className="text-2xl font-bold text-gray-900">
              Healthcare Provider
            </h2>
            <p className="text-gray-600 text-lg max-w-lg mx-auto">
              Upload your credentials, find available shifts, and manage your
              schedule. Perfect for doctors, nurses, and healthcare
              professionals.
            </p>
            <Button
              onClick={() => navigate({ to: "/provider/home" })}
              className="w-full py-8 px-6 rounded-lg text-lg font-semibold"
            >
              I am a Healthcare Provider
            </Button>
          </Card>

          <Card className="p-6 text-center space-y-4">
            <ClipboardList className="w-12 h-12 mx-auto text-blue-600" />
            <h2 className="text-2xl font-bold text-gray-900">
              Staffing Manager
            </h2>
            <p className="text-gray-600 text-lg max-w-lg mx-auto">
              Post shifts, review credentials, and manage staffing needs.
              Streamline your staffing process.
            </p>
            <Button
              onClick={() => navigate({ to: "/org/home" })}
              className="w-full py-8 px-6 rounded-lg text-lg font-semibold"
            >
              I am a Staffing Manager
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
