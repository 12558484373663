import { Link, useRouteContext } from "@tanstack/react-router";
import {
  ContactRound,
  HouseIcon,
  Menu,
  MessageSquare,
  Settings,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import SidebarItem from "@/components/Sidebar/SidebarItem";
import { UserAvatar } from "@/components/UserAvatar/UserAvatar";
import { useRouterState } from "@tanstack/react-router";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { getChatUnreadMessagesCountQueryOptions } from "@/ajax/queries";
import BottomBarItem from "../BottomBar/BottomBarItem";

const sideItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/org/home",
  },
  {
    icon: <ContactRound />,
    label: "Provider Profiles",
    route: "/org/provider-profiles",
  },
  {
    icon: <MessageSquare />,
    label: "Chats",
    route: "/org/chat",
    sidebarItem: ({ onClick }: { onClick: () => void }) => {
      const { data: unreadMessagesCount } = useQuery(
        getChatUnreadMessagesCountQueryOptions(),
      );

      const notificationCount =
        unreadMessagesCount?.reduce(
          (acc, message) => acc + message.unread_count,
          0,
        ) ?? 0;

      return (
        <SidebarItem
          notificationCount={notificationCount}
          item={{
            icon: <MessageSquare />,
            label: "Chats",
            route: "/org/chat",
          }}
          onClick={onClick}
        />
      );
    },
  },
  {
    icon: <Settings />,
    label: "Settings",
    route: "/org/settings",
  },
  // {
  //   icon: <Search />,
  //   label: "Provider Search",
  //   route: "/org/provider-search",
  // },
];

const mobileNavItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/org/home",
  },
  {
    icon: <ContactRound />,
    label: "Provider Profiles",
    route: "/org/provider-profiles",
  },
  {
    route: "/org/chat",
    label: "Chats",
    icon: <MessageSquare />,
    bottomMenutItem: ({
      key,
      item,
    }: {
      key: string;
      item: {
        icon: React.ReactNode;
        label: string;
        route: string;
      };
    }) => {
      const { data: unreadMessagesCount } = useQuery(
        getChatUnreadMessagesCountQueryOptions(),
      );

      const notificationCount =
        unreadMessagesCount?.reduce(
          (acc, message) => acc + message.unread_count,
          0,
        ) ?? 0;
      return (
        <BottomBarItem
          key={key}
          notificationCount={notificationCount}
          item={item}
        />
      );
    },
  },
];

export function OrgLayout({
  children,
  showSidebar = true,
  user,
}: {
  children: React.ReactNode;
  showSidebar?: boolean;
  user: {
    id: string;
    is_admin: boolean;
  };
}) {
  const [openSheet, setOpenSheet] = useState(false);
  const router = useRouterState();
  const chatEnabled = useFeatureFlagEnabled("chat");

  const getNavItems = (user: { is_admin: boolean }) => {
    return chatEnabled
      ? sideItems
      : sideItems.filter((item) => item.label !== "Chats");
  };

  const filteredMobileNavItems = chatEnabled
    ? mobileNavItems
    : mobileNavItems.filter((item) => item.label !== "Chats");

  return (
    <div
      className={`min-h-screen w-full ${showSidebar ? "grid md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]" : ""}`}
    >
      {showSidebar && (
        <div
          id="static-sidebar"
          className="hidden border-r bg-muted/40 md:block"
        >
          <div className="flex h-full max-h-screen flex-col gap-2">
            <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
              <Link
                to="/provider/home"
                className="flex items-center gap-2 font-semibold"
              >
                <img
                  alt="Saile Logo"
                  src="/static/images/logo.png"
                  className="w-[114px]"
                />
              </Link>
            </div>
            <div className="flex-1">
              <nav className="grid items-start px-2 mt-6 text-sm font-medium lg:px-4">
                {getNavItems(user).map((item) => {
                  const isSelected = router.location.pathname.startsWith(
                    item.route,
                  );
                  return (
                    <React.Fragment key={item.label}>
                      {item.sidebarItem ? (
                        item.sidebarItem({
                          onClick: () => {},
                        })
                      ) : (
                        <SidebarItem item={item} />
                      )}
                      {/* {item.subItems && isSelected && (
                          <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                            {item.subItems.map((subItem) => (
                              <SidebarItem
                                key={subItem.label}
                                item={subItem}
                                className="text-xs text-muted-foreground hover:text-primary py-1"
                              />
                            ))}
                          </div>
                        )} */}
                    </React.Fragment>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col h-screen">
        <header className="flex items-center gap-4 border-b bg-muted px-4">
          {showSidebar && (
            <Sheet open={openSheet} onOpenChange={setOpenSheet}>
              <SheetTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="shrink-0 md:hidden"
                >
                  <Menu className="h-5 w-5" />
                  <span className="sr-only">Toggle navigation menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent id="sidebar" side="left" className="flex flex-col">
                <img
                  alt="Saile Logo"
                  src="/static/images/logo.png"
                  className="ml-2 mb-4 w-[114px]"
                />
                <nav className="text-lg font-medium">
                  {getNavItems(user).map((item) => {
                    const isSelected = router.location.pathname.startsWith(
                      item.route,
                    );
                    return (
                      <React.Fragment key={item.label}>
                        {item.sidebarItem ? (
                          item.sidebarItem({
                            onClick: () => setOpenSheet(false),
                          })
                        ) : (
                          <SidebarItem
                            onClick={() => setOpenSheet(false)}
                            item={item}
                          />
                        )}
                        {/* {item.subItems && isSelected && (
                            <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                              {item.subItems.map((subItem) => (
                                <SidebarItem
                                  key={subItem.label}
                                  item={subItem}
                                  onClick={() => setOpenSheet(false)}
                                  className="text-sm text-muted-foreground hover:text-primary py-1"
                                />
                              ))}
                            </div>
                          )} */}
                      </React.Fragment>
                    );
                  })}
                </nav>
              </SheetContent>
            </Sheet>
          )}
          <div className="w-full flex-1"></div>
          <UserAvatar minimal={true} />
        </header>

        <main className="flex-1 relative overflow-y-auto bg-gray-100">
          {children}
        </main>

        {/* Mobile Bottom Navigation */}
        {showSidebar && (
          <div
            id="mobile-bottom-nav"
            className="border-t bg-white shadow-[0_-4px_10px_rgba(0,0,0,0.1)] md:hidden h-[70px]"
          >
            <nav className="flex justify-around items-center h-full">
              {filteredMobileNavItems.map((item) => {
                return item.bottomMenutItem ? (
                  item.bottomMenutItem({
                    key: item.label,
                    item: {
                      icon: item.icon,
                      label: item.label,
                      route: item.route,
                    },
                  })
                ) : (
                  <BottomBarItem key={item.label} item={item} />
                );
              })}
            </nav>
          </div>
        )}
      </div>
    </div>
  );
}
