"use server";

import { supabase } from "@/ajax/clients/supabase";
import { User } from "@supabase/supabase-js";
import posthog from "posthog-js";
type LoginError = {
  message: string;
};

export const signInOTPToken = async ({
  email,
  token,
}: {
  email: string;
  token: string;
}): Promise<{
  user: User | null;
  error: LoginError | null;
}> => {
  const { data, error } = await supabase.auth.verifyOtp({
    email,
    token,
    type: "email",
  });

  if (!error) {
    posthog.capture("signin.otp");
  }

  return {
    user: data.user,
    error,
  };
};
