export const asyncInterval = ({
  fn,
  interval = 1000,
  jitter = 0,
}: {
  fn: () => Promise<void>;
  interval?: number;
  jitter?: number;
}) => {
  let timeout: NodeJS.Timeout;
  const looper = async () => {
    await fn();
    timeout = setTimeout(looper, interval + Math.random() * jitter);
  };
  looper();

  return () => {
    clearTimeout(timeout);
  };
};
