import { Link } from "@tanstack/react-router";
import React, { useEffect, useMemo, useState } from "react";
import {
  Clock,
  Settings,
  AlertCircle,
  Upload,
  CalendarRangeIcon,
  BellRing,
  ExternalLink,
  Smartphone,
} from "lucide-react";
import { useProviderUser } from "@/hooks/useProviderUser";
import { differenceInDays, parse } from "date-fns";
import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { queryClient } from "@/ajax/queryClient";
import { filesQueryOptions, providerUserQueryOptions } from "@/ajax/queries";
import { useSuspenseQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { DOCUMENT_CATEGORIES } from "@/consts/document_categories";
import { FileType } from "@/ajax/documents/getFiles";
import { ExpirationBadgeTime } from "@/features/documents/components/Badge/ExpirationBadgeTime";
import { PushNotificationService } from "@/common/utils/PushNotificationService";
import { PushNotificationsForm } from "@/components/Forms/PushNotificationsForm";
import { Capacitor } from "@capacitor/core";

const FileRow = ({ file }: { file: FileType }) => {
  return (
    <li
      key={file.id}
      className="flex flex-col rounded-md py-4 last:pb-0 border-b last:border-b-0"
    >
      <Link
        to={`/provider/documents`}
        params={{ fileId: file.id }}
        className="w-full"
      >
        <div className="font-medium text-gray-900">
          {file.generated_title ?? file.file_name}
        </div>
      </Link>
      <div>
        <ExpirationBadgeTime expiresAt={file.expires_at} />{" "}
        {file.renew_url && (
          <a
            href={file.renew_url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-3 py-1.5 underline text-sm font-medium rounded "
          >
            <ExternalLink className="h-4 w-4 mr-1" />
            Renew
          </a>
        )}
      </div>
    </li>
  );
};

const renderDocumentStatus = (expiringFiles: FileType[]) => {
  if (expiringFiles.length === 0) {
    return (
      <div className="mt-4">
        <p className="mt-2 text-sm">
          You have no documents that need renewal in the next 60 days.
        </p>
      </div>
    );
  }

  return (
    <ul>
      {expiringFiles.map((file, index) => (
        <FileRow file={file} key={index} />
      ))}
    </ul>
  );
};

export const Route = createFileRoute("/_provider/provider/home/")({
  loader: () =>
    Promise.all([
      queryClient.ensureQueryData(providerUserQueryOptions()),
      queryClient.ensureQueryData(filesQueryOptions()),
    ]),
  component: HomePage,
});

const DOCUMENT_ALERT_COOKIE_NAME = "documentsAlertDismissed";
const NOTIFICATIONS_ALERT_COOKIE_NAME = "notificationsDismissed";

function HomePage() {
  const providerUser = useProviderUser();
  const filesQuery = useSuspenseQuery(filesQueryOptions());
  const filesData = filesQuery.data;
  const [showNotificationsCard, setShotNotificationsCard] = useState<
    boolean | null
  >(null);
  useEffect(() => {
    const checkPushStatus = async () => {
      const notificationsDismissed =
        Cookies.get(NOTIFICATIONS_ALERT_COOKIE_NAME) === "true";
      if (notificationsDismissed) {
        setShotNotificationsCard(false);
        return;
      }

      const status = await PushNotificationService.checkPermissionStatus();
      if (status) {
        setShotNotificationsCard(status.receive !== "granted");

        if (status.receive === "granted") {
          PushNotificationService.requestPermission();
        }
      }
    };
    checkPushStatus();
  }, []);

  // Calculate the missing required documents
  const missingDocuments = useMemo(
    () =>
      DOCUMENT_CATEGORIES.filter((document) => {
        return (
          !filesData.some((f) => document.fileTypeIds.includes(f.type_id)) &&
          !document.optional
        );
      }),
    [filesData],
  );

  const [showDocumentsAlert, setShowDocumentsAlert] = useState(false);

  useEffect(() => {
    const alertDismissed = Cookies.get(DOCUMENT_ALERT_COOKIE_NAME);
    if (alertDismissed !== "true") {
      setShowDocumentsAlert(true);
    }
  }, []);

  const dismissDocumentAlert = () => {
    Cookies.set(DOCUMENT_ALERT_COOKIE_NAME, "true", { expires: 30 }); // Cookie expires in 7 days
    setShowDocumentsAlert(false);
  };

  const dismissNotificationsCard = () => {
    Cookies.set(NOTIFICATIONS_ALERT_COOKIE_NAME, "true", { expires: 30 });
    setShotNotificationsCard(false);
  };

  if (!providerUser) {
    return;
  }

  const expiringFiles = filesData.filter((file) => {
    if (!file.expires_at) return false;
    const daysUntilExpire = differenceInDays(
      parse(file.expires_at, "yyyy-MM-dd", new Date()),
      new Date(),
    );
    return daysUntilExpire <= 60;
  });

  const isMobileWeb =
    /iPhone|iPad/i.test(navigator.userAgent) && !Capacitor.isNativePlatform();

  return (
    <div className="p-2 md:p-4 max-w-4xl mx-auto">
      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white p-6 rounded-lg border animate-fade-in-delay-1">
          <div className="flex items-center">
            <Clock className="h-6 w-6 mr-2" />
            <h2 className="text-xl font-semibold text-gray-800">
              {expiringFiles.length > 0
                ? "Expiring Documents"
                : "All Documents Up to Date"}
            </h2>
          </div>
          {renderDocumentStatus(expiringFiles)}
        </div>
        {showNotificationsCard && (
          <div className="bg-white p-6 rounded-lg border animate-fade-in-delay-2">
            <div className="flex items-center">
              <BellRing className="h-6 w-6 mr-3" />
              <h2 className="text-xl font-semibold text-gray-800">
                Notifications
              </h2>
            </div>
            <PushNotificationsForm onComplete={dismissNotificationsCard} />
          </div>
        )}

        {showDocumentsAlert && missingDocuments.length > 0 && (
          <div className="animate-fade-in-delay-2 p-5 border rounded-lg border-l-4 bg-white relative">
            <div className="flex items-center">
              <AlertCircle className="h-6 w-6 mr-3" />
              <h2 className="text-xl font-semibold text-gray-800">
                Required Documents
              </h2>
            </div>
            <p className="mt-2 text-sm">
              Please upload the following required documents to complete your
              profile:
            </p>
            <ul className="mt-3 space-y-2">
              {missingDocuments.map((doc, index) => (
                <li key={index} className="flex items-center">
                  <Upload className="h-4 w-4 mr-2" />
                  <span>{doc.name}</span>
                </li>
              ))}
            </ul>
            <div className="flex items-center mt-4">
              <Link to="/provider/documents">
                <Button variant="outline">Upload Documents</Button>
              </Link>
              <Button
                variant="ghost"
                onClick={dismissDocumentAlert}
                className="ml-2"
              >
                Dismiss
              </Button>
            </div>
          </div>
        )}

        <div className="bg-white p-6 rounded-lg border animate-fade-in-delay-3">
          <div className="flex items-center mb-4">
            <CalendarRangeIcon className="h-5 w-5 mr-2 text-indigo-500" />
            <h2 className="text-xl font-semibold text-gray-800">Shifts</h2>
          </div>
          <span className="px-5 py-2 font-medium text-blue-600 bg-blue-50 rounded-full">
            Coming Soon
          </span>
          <p className="mt-4 text-gray-600">
            Medical shifts will be available soon. While you wait, you can set
            up your shift preferences to ensure you're matched with the most
            suitable opportunities.
          </p>
          <div className="w-50">
            <Link to="/provider/shifts">
              <Button
                variant="outline"
                size="none"
                className="mt-4 flex items-center p-2"
              >
                <Settings className="h-4 w-4 mr-2" />
                Edit Shift Preferences
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
