import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export const adminUpdateUser = async ({
  email,
  userId,
}: {
  email: string;
  userId: string;
}): Promise<string> => {
  return callSupabaseFnFromClient<string>({
    fnName: "admin_update_user",
    args: {
      user_id: userId,
      email,
    },
  });
};
