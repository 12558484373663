import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
export type ReferralType = {
  id: string;
  referrer_user_id: string;
  referrer_first_name: string;
  referrer_last_name: string;
  referrer_email: string;
  referrer_created_at: string;
  referrer_deleted_at: string;
  referred_user_id: string;
  referred_first_name: string;
  referred_last_name: string;
  referred_email: string;
  referred_created_at: string;
  referred_deleted_at: string;
  referred_completed: boolean;
  referred_paid: boolean;
  referred_credentials_count: number;
  referred_max_onboarding_step: number;
};

export const adminGetReferrals = async ({
  search,
  paid,
  onboardingComplete,
  minCredentialCount,
}: {
  search?: string;
  paid?: boolean;
  onboardingComplete?: boolean;
  minCredentialCount?: number;
}): Promise<ReferralType[]> => {
  return await callSupabaseFnFromClient<ReferralType[]>({
    fnName: "admin_get_referrals",
    args: {
      search,
      paid,
      onboarding_complete: onboardingComplete,
      min_credentials_count: minCredentialCount,
    },
  });
};
