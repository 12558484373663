import AsyncSelect from "react-select/async";
import { useJsApiLoader } from "@react-google-maps/api";
import { getEnvVar } from "@/common/utils/environment";
import { MultiValue } from "react-select";

export type CityOption = {
  name: string;
  lat?: number;
  lng?: number;
};

type CityOptionWithPlaceId = {
  placeId: string;
  name: string;
  lat?: number;
  lng?: number;
};

interface CitySelectProps {
  value: CityOption[];
  onChange: (cities: CityOption[]) => void;
  multiple?: boolean;
}

const libraries = ["places"];

export function CitySelect({
  value,
  onChange,
  multiple = false,
}: CitySelectProps) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: getEnvVar("VITE_PUBLIC_GOOGLE_MAPS_API_KEY") || "",
    // @ts-ignore
    libraries,
  });

  const loadCityOptions = async (inputValue: string) => {
    if (!isLoaded) return [];

    const autocompleteService = new google.maps.places.AutocompleteService();

    try {
      const predictions = await autocompleteService.getPlacePredictions({
        input: inputValue,
        types: ["(cities)"],
        componentRestrictions: { country: "us" },
      });

      return predictions.predictions.map((prediction) => ({
        value: {
          placeId: prediction.place_id,
          name: prediction.description,
        },
        label: prediction.description,
      }));
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
      return [];
    }
  };

  const handleChange = async (
    selectedOptions:
      | { value: CityOptionWithPlaceId | CityOption; label: string }[]
      | {
          value: CityOptionWithPlaceId | CityOption;
          label: string;
        }
      | null,
  ) => {
    if (!selectedOptions) {
      onChange([]);
      return;
    }

    if (!isLoaded) return;
    const geocoder = new google.maps.Geocoder();

    try {
      if (Array.isArray(selectedOptions)) {
        const citiesWithCoords = await Promise.all(
          selectedOptions.map(async (option) => {
            if (
              (option.value?.lat && option.value?.lng) ||
              !("placeId" in option.value)
            ) {
              return {
                name: option.label,
                lat: option.value.lat,
                lng: option.value.lng,
              };
            }

            const result = await geocoder.geocode({
              placeId: option.value.placeId,
            });
            const location = result.results[0].geometry.location;
            return {
              name: option.value.name,
              lat: location.lat(),
              lng: location.lng(),
            };
          }),
        );
        onChange(citiesWithCoords);
      } else if (selectedOptions) {
        // If single selection already has coordinates
        if (
          (selectedOptions.value.lat && selectedOptions.value.lng) ||
          !("placeId" in selectedOptions.value)
        ) {
          onChange([selectedOptions.value]);
          return;
        }

        const result = await geocoder.geocode({
          placeId: selectedOptions.value.placeId,
        });
        const location = result.results[0].geometry.location;
        onChange([
          {
            name: selectedOptions.value.name,
            lat: location.lat(),
            lng: location.lng(),
          },
        ]);
      } else {
        onChange([]);
      }
    } catch (error) {
      console.error("Error getting place details:", error);
      onChange(
        Array.isArray(selectedOptions)
          ? selectedOptions.map((option) => option.value)
          : [selectedOptions.value],
      );
    }
  };

  return (
    <AsyncSelect
      id="city_input"
      isClearable
      placeholder="Select a city"
      classNames={{
        control: (state) => "py-3.5",
      }}
      loadOptions={loadCityOptions}
      value={value.map((city) => ({
        value: city,
        label: city.name,
      }))}
      isMulti={multiple}
      // @ts-ignore
      onChange={handleChange}
    />
  );
}
