import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

type BusinessUnitUser = {
  id: string;
  business_unit_id: string;
  user_id: string;
  created_at: string;
};

export const deleteBusinessUnitUser = async ({
  businessUnitUserId,
}: {
  businessUnitUserId: string;
}) => {
  return callSupabaseFnFromClient<BusinessUnitUser>({
    fnName: "admin_delete_business_unit_user",
    args: {
      p_id: businessUnitUserId,
    },
  });
};
