import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DollarSign,
  ShieldCheck,
  Clock,
  ShieldIcon,
  ArrowRight,
  Building2,
} from "lucide-react";
import { useProviderUser } from "@/hooks/useProviderUser";
import { useEffect, useState } from "react";
import { createBillingAccount } from "@/ajax/billing/createBillingAccount";
import { PROVIDER_USER_QUERY_KEY } from "@/ajax/queries";
import { queryClient } from "@/ajax/queryClient";
import StripeBillingDashboard from "@/features/billing/StripeBillingDashboard";
import StripeOnboarding from "@/features/billing/StripeOnboarding";
import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";
import { createBillingWebSessionForNative } from "@/ajax/billing/createBillingWebSessionForNative";
import { createBillingSession } from "@/ajax/billing/createBillingSession";

const BillingPage = () => {
  const providerUser = useProviderUser({ staleTime: undefined });
  const [onboardingComplete, setOnboardingComplete] = useState(false);
  const isNative = Capacitor.isNativePlatform();

  const handleAccountSetup = async () => {
    const response = await createBillingAccount();
    queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });
    console.log("Created stripe account", response);
  };

  const handleOnboardingComplete = async () => {
    // Trigger another session. A little hacky, but it will ensure the account gets updated in case the webhook failed.
    await createBillingSession();

    // Invalidate the query to refresh the data
    queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });

    // Set the onboarding complete state
    setOnboardingComplete(true);
    console.log("Onboarding complete");
  };

  if (isNative) {
    return (
      <div className="container max-w-4xl mx-auto py-8 px-4">
        <div className="text-center space-y-4">
          <h2 className="text-2xl font-semibold">Open Billing Settings</h2>
          <p className="text-gray-600">
            Please click the button below to access your billing settings.
          </p>
          <Button
            className="mt-4"
            onClick={async () => {
              const response = await createBillingWebSessionForNative();
              console.log(response);
              Browser.open({
                url: response.loginUrl,
                presentationStyle: "popover",
                windowName: "_self",
              });
            }}
          >
            Open Billing Settings <ArrowRight className="w-4 h-4 ml-2" />
          </Button>
        </div>
      </div>
    );
  }

  if (!providerUser.billing.has_account) {
    return (
      <div className="container max-w-4xl mx-auto py-8 px-4">
        <div className="space-y-8">
          <div className="text-center space-y-4">
            <h1 className="text-3xl font-bold">Set Up Your Payment Account</h1>
            <p className="text-lg max-w-2xl mx-auto">
              Before you can start receiving payments for your work, we need to
              set up your Stripe account. This ensures secure and timely
              payments directly to your bank account.
            </p>
          </div>

          <div className="grid gap-6 md:grid-cols-3">
            <Card className="hover:shadow-lg transition-shadow duration-200">
              <CardHeader className="text-center space-y-4">
                <div className="flex justify-center">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <DollarSign className="w-6 h-6 text-primary" />
                  </div>
                </div>
                <CardTitle className="text-xl">
                  Seamless Direct Deposits
                </CardTitle>
                <CardDescription className="text-sm">
                  Receive automatic payments directly to your bank account after
                  completing shifts, with no additional fees
                </CardDescription>
              </CardHeader>
            </Card>

            <Card className="hover:shadow-lg transition-shadow duration-200">
              <CardHeader className="text-center space-y-4">
                <div className="flex justify-center">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <ShieldCheck className="w-6 h-6 text-primary" />
                  </div>
                </div>
                <CardTitle className="text-xl">Bank-Level Security</CardTitle>
                <CardDescription className="text-sm">
                  Your financial information is protected with industry-leading
                  encryption and security protocols via Stripe
                </CardDescription>
              </CardHeader>
            </Card>

            <Card className="hover:shadow-lg transition-shadow duration-200">
              <CardHeader className="text-center space-y-4">
                <div className="flex justify-center">
                  <div className="p-3 bg-primary/10 rounded-full">
                    <Clock className="w-6 h-6 text-primary" />
                  </div>
                </div>
                <CardTitle className="text-xl">Rapid Processing</CardTitle>
                <CardDescription className="text-sm">
                  Quick and reliable payment processing ensures funds reach your
                  account within 2-3 business days
                </CardDescription>
              </CardHeader>
            </Card>
          </div>

          <Card className="mt-8">
            <CardContent className="pt-6">
              <div className="text-center space-y-6">
                <div className="space-y-2">
                  <h3 className="text-lg font-semibold">
                    Ready to Get Started?
                  </h3>
                  <p>
                    To complete your payment account setup, please have the
                    following information ready:
                  </p>
                </div>

                <div className="max-w-md mx-auto bg-muted/30 rounded-lg p-4">
                  <ul className="space-y-3 text-sm">
                    <li className="flex items-center gap-2">
                      <Building2 className="w-4 h-4 text-primary" />
                      <span>Bank account and routing numbers</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <ShieldIcon className="w-4 h-4 text-primary" />
                      <span>
                        Social security number for identity verification
                      </span>
                    </li>
                  </ul>
                </div>

                <Button
                  onClick={handleAccountSetup}
                  size="lg"
                  className="mt-2 px-8"
                  variant="default"
                >
                  Complete Account Setup
                  <ArrowRight className="ml-2 w-4 h-4" />
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  if (
    providerUser.billing.has_account &&
    !providerUser.billing.details_complete
  ) {
    return (
      <div className="max-w-4xl mx-auto py-8 px-4">
        <Card>
          <CardContent className="pt-6">
            <div className="text-center space-y-6">
              <StripeOnboarding onComplete={handleOnboardingComplete} />
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  return <StripeBillingDashboard />;
};

export const Route = createFileRoute("/_provider/provider/billing/")({
  component: BillingPage,
});
