import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type BusinessUnitUser = {
  id: string;
  business_unit_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
  created_at: string;
};

export const getBusinessUnitUsers = async ({
  businessUnitId,
}: {
  businessUnitId: string;
}) => {
  return callSupabaseFnFromClient<BusinessUnitUser[]>({
    fnName: "admin_get_business_unit_users",
    args: { p_business_unit_id: businessUnitId },
  });
};
