import React, { useState, useEffect, useRef } from "react";
import {
  Download,
  Pencil,
  Trash2,
  ExternalLink,
  Upload,
  BellOff,
} from "lucide-react";
import { Json } from "@/common/types/database.types";
import Loading from "../Loading";
import { filesQueryOptions } from "@/ajax/queries";
import {
  useSuspenseQuery,
  useMutation,
  useQueryClient,
  useQuery,
} from "@tanstack/react-query";
import { FilePreviewEdit } from "./FilePreviewEdit";
import { Button } from "../ui/button";
import { deleteFile } from "@/ajax/documents/deleteFile";
import { DeleteDialog } from "../DeleteDialog/DeleteDialog";
import { getFileSignedUrl } from "@/ajax/documents/getFileSignedUrl";
import PDFViewer from "../PDFViewer/PDFViewer";
import { parse, addWeeks } from "date-fns";
import { downloadFile } from "@/ajax/documents/downloadFile";
import { generateDocumentName } from "@/features/documents/utils/generateDocumentName";
import { snoozeFileNotifications } from "@/ajax/documents/snoozeFileNotifications";

type File = {
  generated_title: string | null;
  file_name: string;
  id: string;
  data: null | Json;
  url?: string;
  created_at: string;
  type_id: number;
  type_name: string;
  expires_at: string | null;
  renew_url?: string;
  user_credentials_file_id: string;
  expiry_notifications_enabled: boolean;
  expiry_notifications_snooze_until?: string | null;
};

async function updateVersionSource({
  version,
  userId,
}: {
  version: File;
  userId: string;
}) {
  let fileId = version.id;
  let extension = version.file_name.split(".").pop();

  if (extension?.toLowerCase() === "heic") {
    fileId += "_jpg";
    extension = "jpg";
  }
  const fileData = await getFileSignedUrl({
    userId,
    fileId,
  });
  return {
    ...version,
    url: fileData,
  };
}
const formatDateTime = (datetimeString: string) => {
  const date = new Date(datetimeString);
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
};

const formatDate = (dateString: string) => {
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date);
};

const FileVersionPreview = ({
  file,
  userId,
  onDelete,
  editable = true,
}: {
  file: File;
  userId: string;
  onDelete: () => void;
  editable?: boolean;
}) => {
  const [versionSource, setSelectedVersion] = useState<null | File>(null);
  const [showEditFile, setShowEditFile] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const queryClient = useQueryClient();

  const filesQuery = useQuery(
    filesQueryOptions({
      id: file.id,
      switchUserId: userId,
    }),
  );

  const deleteMutation = useMutation({
    mutationFn: deleteFile,
    onError: (error) => {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Please try again.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["files"] });
      queryClient.invalidateQueries({ queryKey: ["renewals"] });
      onDelete();
    },
  });

  const snoozeMutation = useMutation({
    mutationFn: snoozeFileNotifications,
    onError: (error) => {
      console.error("Error snoozing notifications:", error);
      alert("Failed to snooze notifications. Please try again.");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["files"] });
    },
  });

  useEffect(() => {
    (async () => {
      if (!filesQuery.data) {
        return;
      }

      const version = await updateVersionSource({
        version: filesQuery.data[0],
        userId,
      });
      setSelectedVersion(version);
    })();
  }, [filesQuery.data]);

  const handleDelete = async () => {
    deleteMutation.mutate({ id: file.id });
  };

  const handleDownload = async () => {
    if (versionSource?.url) {
      const fileName = generateDocumentName(versionSource);

      downloadFile({
        userId,
        fileId: versionSource.id,
        fileName,
      });
    }
  };

  const handleSnoozeNotifications = async () => {
    snoozeMutation.mutate({ id: file.user_credentials_file_id });
  };

  const renderOCRData = (data: any, level = 0) => {
    if (typeof data !== "object" || data === null) {
      return (
        <span className="text-gray-800">
          {data == null ? "None" : JSON.stringify(data)}
        </span>
      );
    }

    return (
      <ul className={`space-y-1 ${level === 0 ? "mt-2" : "ml-4"}`}>
        {Object.entries(data).map(([key, value]) => (
          <li key={key} className="font-mono">
            <span className="font-medium text-blue-600 break-all">{key}:</span>{" "}
            {Array.isArray(value) ? (
              <span>{renderOCRData(value, level + 1)}</span>
            ) : typeof value === "object" && value !== null ? (
              <span>{renderOCRData(value, level + 1)}</span>
            ) : (
              <span className="text-gray-800 break-all">
                {value == null ? "None" : JSON.stringify(value)}
              </span>
            )}
          </li>
        ))}
      </ul>
    );
  };

  const renderPreview = (version: File) => {
    let extension = version.file_name.split(".").pop();
    const isPDF = extension?.toLowerCase() === "pdf";
    if (isPDF && version.url) {
      return <PDFViewer url={version.url} />;
    } else if (version.url) {
      return (
        <img
          src={version.url}
          alt={version.generated_title || version.file_name || "Image"}
          className="max-w-full max-h-[70vh] object-contain mx-auto rounded"
        />
      );
    } else {
      return <div>Unable to load preview</div>;
    }
  };

  if (!versionSource) {
    return (
      <div className="flex w-full item-center justify-center">
        <Loading className="w-20 h-20" />
      </div>
    );
  }

  return (
    <div className={`w-full`}>
      <div className="flex flex-col md:flex-row md:space-x-4">
        <div className="w-full md:w-1/2 order-2 md:order-1 mb-4 md:mb-0">
          {renderPreview(versionSource)}
        </div>
        <div className="w-full md:w-1/2 order-1 md:order-2 mb-4 md:mb-0">
          <div className="flex justify-center md:justify-between items-start mb-4">
            <div className="text-center md:text-left">
              <h3 className="text-2xl font-medium break-words">
                {versionSource.generated_title || versionSource.file_name}
              </h3>
              {versionSource.generated_title && (
                <h4 className="text-md mt-1">
                  <span className="text-gray-500 break-all">
                    ({versionSource.file_name})
                  </span>
                </h4>
              )}
              <h4 className="mt-1">{versionSource.type_name}</h4>
              <p className="mt-1">{formatDateTime(versionSource.created_at)}</p>
              {versionSource.expires_at && (
                <p className="mt-1">
                  Expires: {formatDate(versionSource.expires_at)}
                </p>
              )}
              <div className="mt-4 flex flex-col space-y-2 max-w-sm mx-auto md:mx-0">
                {editable && versionSource.renew_url && (
                  <a
                    href={versionSource.renew_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full inline-flex items-center justify-center space-x-2 px-3 py-1.5 bg-blue-600 text-white text-sm font-medium rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <ExternalLink className="w-4 h-4" />
                    <span>Renew</span>
                  </a>
                )}
                {/* {editable && (
                    <Button
                      className="w-full flex items-center justify-center space-x-2"
                      variant={versionSource.renew_url ? "outline" : "default"}
                      // onClick={handleNewVersionClick}
                    >
                      <Upload className="w-4 h-4" />
                      <span>Upload New Version</span>
                    </Button>
                )} */}

                {editable && (
                  <Button
                    className="w-full flex items-center justify-center space-x-2"
                    variant="outline"
                    onClick={() => setShowEditFile(true)}
                  >
                    <Pencil className="w-4 h-4" />
                    <span>Edit Details</span>
                  </Button>
                )}
                {editable && versionSource.expiry_notifications_enabled && (
                  <>
                    {versionSource.expiry_notifications_snooze_until && (
                      <p className="text-sm text-gray-500 mb-2 text-center md:text-left">
                        Notifications snoozed until:{" "}
                        {formatDate(
                          versionSource.expiry_notifications_snooze_until,
                        )}
                      </p>
                    )}
                    <Button
                      className="w-full flex items-center justify-center space-x-2"
                      variant="outline"
                      onClick={handleSnoozeNotifications}
                      disabled={snoozeMutation.isPending}
                    >
                      {snoozeMutation.isPending ? (
                        <Loading className="w-4 h-4" />
                      ) : (
                        <BellOff className="w-4 h-4" />
                      )}
                      <span>Snooze Notifications (2 weeks)</span>
                    </Button>
                  </>
                )}
                <Button
                  className="w-full flex items-center justify-center space-x-2"
                  variant="outline"
                  onClick={handleDownload}
                >
                  <Download className="w-4 h-4" />
                  <span>Download</span>
                </Button>

                {editable && (
                  <Button
                    className="w-full flex items-center justify-center space-x-2 text-red-500"
                    variant="outline"
                    onClick={() => setShowDeleteDialog(true)}
                  >
                    <Trash2 className="w-4 h-4" />
                    <span>Delete</span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showEditFile && (
        <FilePreviewEdit
          file={versionSource}
          userId={userId}
          onClose={() => setShowEditFile(false)}
        />
      )}

      <DeleteDialog
        description={`This will permanently delete the file and and all associated renewals. Please make sure this is correct. This action cannot be undone.`}
        open={showDeleteDialog}
        onOpenChange={setShowDeleteDialog}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default FileVersionPreview;
