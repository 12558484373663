import { createRootRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { getUser, User } from "../ajax/auth/getUser";
import { PushNotificationService } from "../common/utils/PushNotificationService";
import { NotFoundComponent } from "@/components/NotFoundComponent";
import * as Sentry from "@sentry/browser";
import posthog, { PostHog } from "posthog-js";

PushNotificationService.setupPushListeners();

export const Route = createRootRoute({
  errorComponent: () => {
    const navigate = useNavigate();
    return (
      <div
        id="error-page"
        className="flex flex-col items-center justify-center min-h-screen p-8 text-center"
      >
        <div className="rounded-full bg-red-100 p-4 mb-6">
          <svg
            className="w-12 h-12 text-red-500"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <h2 className="text-2xl font-semibold mb-2">
          Oops! Something went wrong
        </h2>
        <p className="text-gray-600 mb-6">
          We encountered an unexpected error. Please try refreshing or contact
          support if the issue persists.
        </p>
        <button
          onClick={() => navigate({ to: "/" })}
          className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
        >
          Refresh App
        </button>
      </div>
    );
  },
  notFoundComponent: () => <NotFoundComponent />,
  beforeLoad: async (): Promise<{
    user: User | null;
  }> => {
    let user = null;
    try {
      user = await getUser();
    } catch (e) {
      console.log("Error fetching user", e);
    }

    Sentry.setUser({
      id: user?.id,
    });
    posthog.identify(user?.id);
    return {
      user,
    };
  },
});
