import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export const updateChatLastReadAt = async ({
  chatThreadId,
  lastReadAt,
}: {
  chatThreadId: string;
  lastReadAt: string;
}) => {
  return callSupabaseFnFromClient<string>({
    fnName: "update_chat_last_read_at",
    args: {
      thread_id: chatThreadId,
      last_read_at: lastReadAt,
    },
  });
};
