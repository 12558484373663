import React, { ReactNode } from "react";
import { Link } from "@tanstack/react-router";
import {
  BookOpenText,
  BriefcaseMedical,
  CheckIcon,
  Contact,
  GraduationCap,
  Hospital,
  MapPin,
  Network,
  Umbrella,
  Users,
} from "lucide-react";

const steps = [
  {
    title: "Personal Info",
    icon: ({ className }: { className: string }) => (
      <svg
        className={className}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 16"
      >
        <path d="M18 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2ZM6.5 3a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5ZM3.014 13.021l.157-.625A3.427 3.427 0 0 1 6.5 9.571a3.426 3.426 0 0 1 3.322 2.805l.159.622-6.967.023ZM16 12h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Zm0-3h-3a1 1 0 1 1 0-2h3a1 1 0 1 1 0 2Z"></path>
      </svg>
    ),
    url: "personal-information",
  },
  {
    title: "Professional IDs",
    icon: ({ className }: { className: string }) => (
      <BriefcaseMedical className={className} />
    ),
    url: "professional-ids",
  },
  {
    title: "Education & Professional Training",
    icon: ({ className }: { className: string }) => (
      <GraduationCap className={className} />
    ),
    url: "education-training",
  },
  {
    title: "Specialties",
    icon: ({ className }: { className: string }) => (
      <Network className={className} />
    ),
    url: "specialties",
  },
  {
    title: "Practice Locations",
    icon: ({ className }: { className: string }) => (
      <MapPin className={className} />
    ),
    url: "practice-locations",
  },
  {
    title: "Hospital Affiliations",
    icon: ({ className }: { className: string }) => (
      <Hospital className={className} />
    ),
    url: "hospital-affiliations",
  },
  {
    title: "Credentialing Contacts",
    icon: ({ className }: { className: string }) => (
      <Contact className={className} />
    ),
    url: "credentialing-contacts",
  },
  {
    title: "Professional Liability Insurance",
    icon: ({ className }: { className: string }) => (
      <Umbrella className={className} />
    ),
    url: "liability-insurance",
  },
  {
    title: "Employment Information",
    icon: ({ className }: { className: string }) => (
      <BookOpenText className={className} />
    ),
    url: "employment-info",
  },
  {
    title: "Professional References",
    icon: ({ className }: { className: string }) => (
      <Users className={className} />
    ),
    url: "professional-references",
  },
];

export default function CredentialsStepper({
  currentStepName,
}: {
  currentStepName: string;
}) {
  const stepIndex = steps.findIndex((step) => step.title === currentStepName);

  const renderStep = (
    step: {
      title: string;
      url: string;
      icon: ({ className }: { className: string }) => ReactNode;
    },
    index: number,
  ) => {
    let bgClassName =
      "absolute flex items-center justify-center w-10 h-10 rounded-full -start-5 ";
    let iconClassName = "w-4 h-4 text-white";

    if (stepIndex > index) {
      bgClassName += "bg-green-700";
    } else if (stepIndex === index) {
      bgClassName += "bg-blue-800";
    } else {
      bgClassName += "bg-slate-400";
    }

    return (
      <li key={step.title} className="mb-10 ms-8">
        <Link to={step.url}>
          <a
            className="cursor-pointer focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 rounded-lg block"
            aria-current={stepIndex === index ? "step" : undefined}
          >
            <span className={bgClassName}>
              {stepIndex > index ? (
                <CheckIcon className={iconClassName} />
              ) : (
                step.icon({ className: iconClassName })
              )}
            </span>
            <h3 className="font-medium leading-9">{step.title}</h3>
          </a>
        </Link>
      </li>
    );
  };

  const renderMobileStep = (
    step: {
      title: string;
      url: string;
      icon: ({ className }: { className: string }) => ReactNode;
    },
    index: number,
  ) => {
    let bgClassName =
      "flex items-center justify-center w-10 h-10 rounded-full shrink-0 ";
    let iconClassName = "w-3.5 h-3.5 text-white lg:w-4 lg:h-4";

    if (stepIndex > index) {
      bgClassName += "bg-green-700";
    } else if (stepIndex === index) {
      bgClassName += "bg-blue-800";
    } else {
      bgClassName += "bg-slate-400";
    }

    return (
      <li
        key={step.title}
        className={`flex items-center w-full ${
          index === steps.length - 1
            ? ""
            : "after:content-[''] after:w-full after:h-1 after:border-b after:border-slate-300 after:border-4 after:inline-block dark:after:border-blue-800"
        }`}
      >
        <Link to={step.url}>
          <a
            className="cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-full block"
            aria-current={stepIndex === index ? "step" : undefined}
          >
            <span className={bgClassName}>
              {stepIndex > index ? (
                <CheckIcon className={iconClassName} />
              ) : (
                step.icon({ className: iconClassName })
              )}
            </span>
          </a>
        </Link>
      </li>
    );
  };

  return (
    <div className="pl-5">
      <ol className="hidden lg:block relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
        {steps.map(renderStep)}
      </ol>
      <ol className="flex items-center w-full lg:hidden">
        {steps.map(renderMobileStep)}
      </ol>
    </div>
  );
}
