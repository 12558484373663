import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Search,
  Filter,
  MapPin,
  DollarSign,
  Calendar,
  Stethoscope,
  Building2,
  Clock,
  Info,
  X,
  Check,
  Share2,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Slider } from "@/components/ui/slider";
import { CitySelect, CityOption } from "@/components/Selects/CitySelect";
import { Checkbox } from "@/components/ui/checkbox";
import Loading from "@/components/Loading";
import { SpecialtiesSelect } from "@/components/Selects/SpecialtiesSelect";

// Mock data - would be replaced with actual API calls
const fetchPositions = async (filters: any) => {
  // Simulate API call
  await new Promise((resolve) => setTimeout(resolve, 1000));

  return [
    {
      id: 1,
      title: "Emergency Room Physician",
      facility: "Memorial Hospital",
      location: "San Francisco, CA",
      hourlyRate: 150,
      contractType: "Occasional Shifts",
      specialty: "Emergency Medicine",
      postedDate: "2023-05-15",
      coverageDates: "Jun 15 - Jul 30, 2023",
      workDays: ["Mon", "Wed", "Fri"],
      schedule: "8AM-6PM",
    },
    {
      id: 2,
      title: "Cardiologist",
      facility: "Heart Center",
      location: "Los Angeles, CA",
      hourlyRate: 200,
      contractType: "Contract (12 months)",
      specialty: "Cardiology",
      postedDate: "2023-05-10",
      coverageDates: "Jul 1 - Jun 30, 2024",
      workDays: ["Tue", "Thu"],
      schedule: "9AM-5PM",
    },
    {
      id: 3,
      title: "Family Physician",
      facility: "Community Health Center",
      location: "Seattle, WA",
      hourlyRate: 120,
      contractType: "Permanent",
      specialty: "Family Medicine",
      postedDate: "2023-05-05",
      coverageDates: "Ongoing",
      workDays: ["Mon", "Tue", "Wed", "Thu", "Fri"],
      schedule: "8AM-4PM",
    },
  ].filter((position) => {
    if (
      filters.searchTerm &&
      !position.title.toLowerCase().includes(filters.searchTerm.toLowerCase())
    ) {
      return false;
    }
    if (filters.minHourlyRate && position.hourlyRate < filters.minHourlyRate) {
      return false;
    }
    if (
      filters.contractType &&
      filters.contractType.length > 0 &&
      !filters.contractType.includes(position.contractType)
    ) {
      return false;
    }
    if (
      filters.specialty &&
      filters.specialty.length > 0 &&
      !filters.specialty.includes(position.specialty)
    ) {
      return false;
    }
    return true;
  });
};

const contractTypes = [
  { value: "Occasional Shifts", label: "Occasional Shifts" },
  { value: "Contract (6-24 months)", label: "Contract (6-24 months)" },
  { value: "Permanent", label: "Permanent" },
];

const PositionSearch: React.FC = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filters, setFilters] = useState({
    searchTerm: "",
    location: [] as CityOption[],
    minHourlyRate: 100,
    contractType: [] as string[],
    specialty: undefined,
  });

  const {
    data: positions,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["positions", filters],
    queryFn: () => fetchPositions(filters),
  });

  const handleFilterChange = (key: string, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleContractTypeChange = (type: string) => {
    setFilters((prev) => {
      const updatedTypes = prev.contractType.includes(type)
        ? prev.contractType.filter((t) => t !== type)
        : [...prev.contractType, type];
      return { ...prev, contractType: updatedTypes };
    });
  };

  const applyFilters = () => {
    refetch();
    if (window.innerWidth <= 768) {
      setIsFilterOpen(false);
    }
  };

  const resetFilters = () => {
    setFilters({
      searchTerm: "",
      location: [],
      minHourlyRate: 100,
      contractType: [],
      specialty: undefined,
    });
  };

  const handleExpressInterest = (position: any) => {
    alert(`Expressed interest in ${position.title} position`);
  };

  const handleSharePosition = (position: any) => {
    alert(`Sharing ${position.title} position`);
  };

  return (
    <div className="p-2 pt-4 md:pt-8 md:p-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Search Positions
      </h2>

      <div className="flex flex-col md:flex-row gap-6">
        {/* Filters Section */}
        <div
          className={`${isFilterOpen ? "block" : "hidden"} md:block md:w-1/3 lg:w-1/4 bg-white rounded-lg shadow p-4`}
        >
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium">Filters</h3>
            <Button variant="outline" size="sm" onClick={resetFilters}>
              Reset
            </Button>
          </div>

          <div className="space-y-6">
            <div>
              <Label htmlFor="location" className="block mb-2">
                Location
              </Label>
              <CitySelect
                value={filters.location}
                multiple={true}
                onChange={(option) => handleFilterChange("location", option)}
              />
            </div>

            <div>
              <Label htmlFor="hourlyRate" className="block mb-2">
                Minimum Hourly Rate: ${filters.minHourlyRate}
              </Label>
              <Slider
                id="hourlyRate"
                defaultValue={[filters.minHourlyRate]}
                max={300}
                min={50}
                step={10}
                onValueChange={(value) =>
                  handleFilterChange("minHourlyRate", value[0])
                }
                className="my-4"
              />
            </div>

            <div>
              <Label className="block mb-2">Contract Type</Label>
              <div className="space-y-2">
                {contractTypes.map((type) => (
                  <div key={type.value} className="flex items-center">
                    <Checkbox
                      id={`contract-${type.value}`}
                      checked={filters.contractType.includes(type.value)}
                      onCheckedChange={() =>
                        handleContractTypeChange(type.value)
                      }
                      className="mr-2"
                    />
                    <Label
                      htmlFor={`contract-${type.value}`}
                      className="cursor-pointer"
                    >
                      {type.label}
                    </Label>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <Label className="block mb-2">Specialty</Label>
              <div className="space-y-2 max-h-48 overflow-y-auto">
                <SpecialtiesSelect
                  value={filters.specialty}
                  onChange={(option) => handleFilterChange("specialty", option)}
                />
              </div>
            </div>

            <Button className="w-full" onClick={applyFilters}>
              Apply Filters
            </Button>
          </div>
        </div>

        {/* Search Results Section */}
        <div className="flex-1">
          <div className="mb-4 flex flex-col md:flex-row gap-2">
            <div className="relative flex-1">
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={20}
              />
              <Input
                placeholder="Search positions..."
                className="pl-10"
                value={filters.searchTerm}
                onChange={(e) =>
                  handleFilterChange("searchTerm", e.target.value)
                }
                onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              />
            </div>
            <Button
              variant="outline"
              className="md:hidden flex items-center gap-2"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <Filter size={18} />
              {isFilterOpen ? "Hide Filters" : "Show Filters"}
            </Button>
          </div>

          {isLoading ? (
            <div className="flex justify-center p-12">
              <Loading className="w-20 h-20" />
            </div>
          ) : positions && positions.length > 0 ? (
            <div className="space-y-4">
              {positions.map((position) => (
                <div
                  key={position.id}
                  className="bg-white rounded-lg shadow p-6 hover:shadow-md transition-shadow"
                >
                  <div className="flex justify-between">
                    <h3 className="text-2xl font-bold text-gray-800 mb-3">
                      {position.title}
                    </h3>
                    <span className="text-green-600 font-bold">
                      ${position.hourlyRate}/hr
                    </span>
                  </div>

                  {/* Job Type */}
                  <div className="flex items-center mb-4">
                    <span className="bg-purple-100 text-purple-700 px-3 py-1 rounded-full text-sm mr-3">
                      {position.contractType}
                    </span>
                    <span className="text-gray-600">{position.specialty}</span>
                  </div>

                  {/* Company and Location */}
                  <div className="space-y-2 mb-4">
                    <div className="flex items-center text-gray-600">
                      <Building2 className="mr-2" size={18} />
                      <span>{position.facility}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <MapPin className="mr-2" size={18} />
                      <span>{position.location}</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Clock className="mr-2" size={18} />
                      <span>In Person</span>
                    </div>
                  </div>

                  {/* Coverage Dates */}
                  <div className="mb-4">
                    <h3 className="font-bold text-gray-800 mb-1">
                      Coverage Dates
                    </h3>
                    <p className="text-gray-600">{position.coverageDates}</p>
                  </div>

                  {/* Schedule */}
                  <div className="mb-4">
                    <h3 className="font-bold text-gray-800 mb-1">Schedule</h3>
                    <p className="text-gray-600">
                      {position.workDays.join(", ")}: {position.schedule}
                    </p>
                  </div>

                  {/* Action Buttons */}
                  <div className="mt-4 flex flex-wrap gap-2">
                    <Button
                      variant="outline"
                      className="flex items-center gap-2"
                      onClick={() =>
                        alert(`Learn more about ${position.title}`)
                      }
                    >
                      <Info size={18} />
                      <span>View Details</span>
                    </Button>

                    <Button
                      className="flex items-center gap-2"
                      onClick={() => handleExpressInterest(position)}
                    >
                      <Check size={18} />
                      <span>Apply Now</span>
                    </Button>

                    <Button
                      variant="outline"
                      className="flex items-center gap-2"
                      onClick={() => handleSharePosition(position)}
                    >
                      <Share2 size={18} />
                      <span>Share</span>
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow p-8 text-center">
              <div className="text-gray-500 mb-2">
                No positions found matching your criteria
              </div>
              <Button variant="outline" onClick={resetFilters}>
                Reset Filters
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PositionSearch;
