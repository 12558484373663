import { ChatThreadMessage } from "@/ajax/chat/getChatThreadMessages";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { format, isToday, differenceInMinutes } from "date-fns";
import { Fragment, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChatMessage } from "./ChatMessage";

export const ChatMessageList = ({
  messages,
}: {
  messages: ChatThreadMessage[] | undefined;
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const shouldGroupWithPreviousMessage = (
    message: ChatThreadMessage,
    prevMessage: ChatThreadMessage | undefined,
  ) => {
    if (!prevMessage) return false;
    return (
      message.sender.id === prevMessage.sender.id &&
      Math.abs(
        differenceInMinutes(message.created_at, prevMessage.created_at),
      ) < 5
    );
  };

  if (!messages?.length) {
    return (
      <div className="flex-1 flex items-center justify-center bg-gray-100">
        <div className="text-center">
          <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <svg
              className="w-8 h-8 text-blue-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900">
            No messages yet
          </h3>
          <p className="mt-2 text-gray-600">
            Start the conversation by sending a message
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-100">
      <AnimatePresence initial={false}>
        {messages?.map((message, index) => {
          const prevMessage = messages[index - 1];
          const isGrouped = shouldGroupWithPreviousMessage(
            message,
            prevMessage,
          );

          if (isGrouped) {
            return (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className={`flex items-start space-x-2 mt-1 ${message.is_me ? "flex-row-reverse space-x-reverse" : ""}`}
              >
                {!message.is_me && <div className="w-8" />}
                <div
                  className={`flex flex-col ${message.is_me ? "items-end" : "items-start"}`}
                >
                  <div
                    className={`rounded-xl px-4 py-2 ${
                      message.is_me
                        ? "bg-blue-600 text-white"
                        : "bg-white shadow-sm"
                    }`}
                  >
                    <ChatMessage message={message.content.text} />
                  </div>
                </div>
              </motion.div>
            );
          }

          return (
            <motion.div
              key={message.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className={`flex items-start space-x-2 ${message.is_me ? "flex-row-reverse space-x-reverse" : ""}`}
            >
              {!message.is_me && (
                <Avatar className="h-8 w-8 mt-1">
                  <AvatarFallback className="bg-gradient-to-br from-gray-500 to-gray-600 text-white text-sm">
                    {message.sender.first_name?.[0] || "?"}
                  </AvatarFallback>
                </Avatar>
              )}
              <div
                className={`flex flex-col ${message.is_me ? "items-end" : "items-start"}`}
              >
                <div className="flex items-center gap-2 mb-1 ml-1">
                  {!message.is_me && (
                    <span className="text-sm font-semibold text-gray-600">
                      {message.sender.first_name}{" "}
                      {message.sender.last_name?.[0]}.
                    </span>
                  )}
                  <span className="text-xs text-gray-500">
                    {isToday(message.created_at)
                      ? format(message.created_at, "h:mm a")
                      : format(message.created_at, "MMM d, h:mm a")}
                  </span>
                </div>
                <div
                  className={`rounded-xl px-4 py-2 ${
                    message.is_me
                      ? "bg-blue-600 text-white"
                      : "bg-white shadow-sm"
                  }`}
                >
                  <ChatMessage message={message.content.text} />
                </div>
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
      <div ref={messagesEndRef} />
    </div>
  );
};
