import { useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { RefreshCw, Home, Cloud, CloudHail } from "lucide-react";

export function Error({ error }: { error: Error }) {
  const navigate = useNavigate();
  const isDev = process.env.NODE_ENV === "development";

  // Log error in production but don't show to user
  if (!isDev) {
    console.error(error);
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6 flex items-center justify-center">
      <Card className="max-w-lg w-full p-8 space-y-6 text-center">
        <div className="space-y-4">
          <CloudHail className="w-12 h-12 mx-auto text-red-500" />
          <h1 className="text-2xl font-bold text-gray-900">
            Oops! Something went wrong
          </h1>
          <p className="text-gray-600">
            {isDev
              ? error?.message ||
                "An unexpected error occurred. Please try again."
              : "We ran into an issue. Please try refreshing or return home."}
          </p>
        </div>

        <div className="flex flex-col sm:flex-row gap-4 justify-center">
          <Button
            onClick={() => window.location.reload()}
            className="flex items-center gap-2"
          >
            <RefreshCw className="w-4 h-4" />
            Refresh
          </Button>
          <Button
            onClick={() => navigate({ to: "/" })}
            variant="outline"
            className="flex items-center gap-2"
          >
            <Home className="w-4 h-4" />
            Go to Home
          </Button>
        </div>
      </Card>
    </div>
  );
}
