import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { formatDate } from "date-fns";
import { GraduationCap, Pencil } from "lucide-react";
import { EducationForm } from "./EducationForm/EducationForm";

export function EducationCard({
  editable,
  education,
  onEditChange,
  isEditing,
  userId,
}: {
  editable: boolean;
  education: any;
  onEditChange: (editing: boolean) => void;
  isEditing: boolean;
  userId: string;
}) {
  return (
    <Card
      className={cn("mt-4", {
        "shadow-none border-none": isEditing,
      })}
    >
      <CardHeader className="pb-1">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">Education</h3>
          {isEditing || !editable ? null : (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onEditChange(true)}
            >
              <Pencil className="h-4 w-4" />
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-2">
        {isEditing ? (
          <EducationForm
            education={education}
            userId={userId}
            onSave={() => onEditChange(false)}
          />
        ) : (
          <>
            {education.professional_training.map(
              (training: any, index: number) => (
                <div key={index} className="space-y-2">
                  <div className="flex items-center gap-3">
                    <GraduationCap className="h-5 w-5 text-gray-500" />
                    <div>
                      <div className="font-semibold">
                        {training.value.training_type}
                      </div>
                      <div className="text-sm text-gray-600">
                        {training.value.institution}
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatDate(training.value.start_date, "MMM yyyy")} -{" "}
                        {training.value.end_date
                          ? formatDate(training.value.end_date, "MMM yyyy")
                          : "Present"}
                      </div>
                      {training.value.program && (
                        <div className="text-sm text-gray-500">
                          Program: {training.value.program}
                        </div>
                      )}
                      {training.value.specialty && (
                        <div className="text-sm text-gray-500">
                          Specialty: {training.value.specialty}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ),
            )}
            {education.professional_education.map((edu: any, index: number) => (
              <div key={index} className="space-y-2">
                <div className="flex items-center gap-3">
                  <GraduationCap className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="font-semibold">{edu.value.degree}</div>
                    <div className="text-sm text-gray-600">
                      {edu.value.institution}
                    </div>
                    {edu.value.program && (
                      <div className="text-sm text-gray-500">
                        {edu.value.program}
                      </div>
                    )}
                    {edu.value.specialty && (
                      <div className="text-sm text-gray-500">
                        {edu.value.specialty}
                      </div>
                    )}
                    <div className="text-sm text-gray-500">
                      {formatDate(edu.value.start_date, "MMM yyyy")} -{" "}
                      {edu.value.end_date
                        ? formatDate(edu.value.end_date, "MMM yyyy")
                        : "Present"}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {education.fifth_pathway_education.map(
              (edu: any, index: number) => (
                <div key={index} className="space-y-2">
                  <div className="flex items-center gap-3">
                    <GraduationCap className="h-5 w-5 text-gray-500" />
                    <div>
                      <div className="font-semibold">{edu.value.degree}</div>
                      <div className="text-sm text-gray-600">
                        {edu.value.institution}
                      </div>
                      {edu.value.program && (
                        <div className="text-sm text-gray-500">
                          {edu.value.program}
                        </div>
                      )}
                      {edu.value.specialty && (
                        <div className="text-sm text-gray-500">
                          {edu.value.specialty}
                        </div>
                      )}
                      <div className="text-sm text-gray-500">
                        {formatDate(edu.value.start_date, "MMM yyyy")} -{" "}
                        {edu.value.end_date
                          ? formatDate(edu.value.end_date, "MMM yyyy")
                          : "Present"}
                      </div>
                    </div>
                  </div>
                </div>
              ),
            )}
            {education.undergraduate_education.map(
              (edu: any, index: number) => (
                <div key={index} className="space-y-2">
                  <div className="flex items-center gap-3">
                    <GraduationCap className="h-5 w-5 text-gray-500" />
                    <div>
                      <div className="font-semibold">{edu.value.degree}</div>
                      <div className="text-sm text-gray-600">
                        {edu.value.institution}
                      </div>
                      {edu.value.program && (
                        <div className="text-sm text-gray-500">
                          {edu.value.program}
                        </div>
                      )}
                      {edu.value.specialty && (
                        <div className="text-sm text-gray-500">
                          {edu.value.specialty}
                        </div>
                      )}
                      <div className="text-sm text-gray-500">
                        {formatDate(edu.value.start_date, "MMM yyyy")} -{" "}
                        {edu.value.end_date
                          ? formatDate(edu.value.end_date, "MMM yyyy")
                          : "Present"}
                      </div>
                    </div>
                  </div>
                </div>
              ),
            )}
            {education.professional_education.length === 0 &&
              education.professional_training.length === 0 &&
              education.fifth_pathway_education.length === 0 &&
              education.undergraduate_education.length === 0 && (
                <div className="text-center text-gray-500">
                  No education history added yet.
                </div>
              )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
