import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type Organization = {
  id: string;
  name: string;
  created_at: string;
};

export const createOrganization = async ({ name }: { name: string }) => {
  return callSupabaseFnFromClient<Organization>({
    fnName: "admin_create_organization",
    args: {
      org_name: name,
    },
  });
};
