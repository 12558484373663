/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as ProviderImport } from "./routes/_provider";
import { Route as OrgImport } from "./routes/_org";
import { Route as AdminImport } from "./routes/_admin";
import { Route as IndexImport } from "./routes/index";
import { Route as AuthSigninIndexImport } from "./routes/auth/signin/index";
import { Route as AuthProviderSigninIndexImport } from "./routes/auth-provider/signin/index";
import { Route as AuthOrgSigninIndexImport } from "./routes/auth-org/signin/index";
import { Route as ProviderProviderOnboardingIndexImport } from "./routes/_provider/provider-onboarding/index";
import { Route as AdminAdminIndexImport } from "./routes/_admin/admin/index";
import { Route as AuthSigninLinkIndexImport } from "./routes/auth/signin/link/index";
import { Route as ProviderProviderWorkHistoryIndexImport } from "./routes/_provider/provider/work-history/index";
import { Route as ProviderProviderShiftsIndexImport } from "./routes/_provider/provider/shifts/index";
import { Route as ProviderProviderSettingsIndexImport } from "./routes/_provider/provider/settings/index";
import { Route as ProviderProviderReferralsIndexImport } from "./routes/_provider/provider/referrals/index";
import { Route as ProviderProviderProfileIndexImport } from "./routes/_provider/provider/profile/index";
import { Route as ProviderProviderProcedureLogIndexImport } from "./routes/_provider/provider/procedure-log/index";
import { Route as ProviderProviderHomeIndexImport } from "./routes/_provider/provider/home/index";
import { Route as ProviderProviderDocumentsIndexImport } from "./routes/_provider/provider/documents/index";
import { Route as ProviderProviderChatIndexImport } from "./routes/_provider/provider/chat/index";
import { Route as ProviderProviderBillingIndexImport } from "./routes/_provider/provider/billing/index";
import { Route as OrgOrgSettingsIndexImport } from "./routes/_org/org/settings/index";
import { Route as OrgOrgProviderSearchIndexImport } from "./routes/_org/org/provider-search/index";
import { Route as OrgOrgProviderProfilesIndexImport } from "./routes/_org/org/provider-profiles/index";
import { Route as OrgOrgJobsIndexImport } from "./routes/_org/org/jobs/index";
import { Route as OrgOrgHomeIndexImport } from "./routes/_org/org/home/index";
import { Route as OrgOrgChatIndexImport } from "./routes/_org/org/chat/index";
import { Route as AdminAdminUsersIndexImport } from "./routes/_admin/admin/users/index";
import { Route as AdminAdminReferralsIndexImport } from "./routes/_admin/admin/referrals/index";
import { Route as AdminAdminProviderSearchIndexImport } from "./routes/_admin/admin/provider-search/index";
import { Route as AdminAdminOrganizationsIndexImport } from "./routes/_admin/admin/organizations/index";
import { Route as AdminAdminDocumentsIndexImport } from "./routes/_admin/admin/documents/index";
import { Route as AdminAdminChatIndexImport } from "./routes/_admin/admin/chat/index";
import { Route as ProviderProviderShiftsSearchIndexImport } from "./routes/_provider/provider/shifts/search/index";
import { Route as ProviderProviderShiftsPreferencesIndexImport } from "./routes/_provider/provider/shifts/preferences/index";
import { Route as ProviderProviderShiftsPotentialIndexImport } from "./routes/_provider/provider/shifts/potential/index";
import { Route as ProviderProviderShiftsNewIndexImport } from "./routes/_provider/provider/shifts/new/index";
import { Route as ProviderProviderCredentialsPersonalInformationIndexImport } from "./routes/_provider/provider/credentials/personal-information/index";
import { Route as OrgOrgProviderProfileUserIdIndexImport } from "./routes/_org/org/provider-profile/$userId/index";
import { Route as AdminAdminProviderProfileUserIdIndexImport } from "./routes/_admin/admin/provider-profile/$userId/index";
import { Route as AdminAdminOrganizationsOrgIdBusinessUnitsIndexImport } from "./routes/_admin/admin/organizations/$orgId/business-units/index";
import { Route as AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexImport } from "./routes/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/index";

// Create/Update Routes

const ProviderRoute = ProviderImport.update({
  id: "/_provider",
  getParentRoute: () => rootRoute,
} as any);

const OrgRoute = OrgImport.update({
  id: "/_org",
  getParentRoute: () => rootRoute,
} as any);

const AdminRoute = AdminImport.update({
  id: "/_admin",
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any);

const AuthSigninIndexRoute = AuthSigninIndexImport.update({
  id: "/auth/signin/",
  path: "/auth/signin/",
  getParentRoute: () => rootRoute,
} as any);

const AuthProviderSigninIndexRoute = AuthProviderSigninIndexImport.update({
  id: "/auth-provider/signin/",
  path: "/auth-provider/signin/",
  getParentRoute: () => rootRoute,
} as any);

const AuthOrgSigninIndexRoute = AuthOrgSigninIndexImport.update({
  id: "/auth-org/signin/",
  path: "/auth-org/signin/",
  getParentRoute: () => rootRoute,
} as any);

const ProviderProviderOnboardingIndexRoute =
  ProviderProviderOnboardingIndexImport.update({
    id: "/provider-onboarding/",
    path: "/provider-onboarding/",
    getParentRoute: () => ProviderRoute,
  } as any);

const AdminAdminIndexRoute = AdminAdminIndexImport.update({
  id: "/admin/",
  path: "/admin/",
  getParentRoute: () => AdminRoute,
} as any);

const AuthSigninLinkIndexRoute = AuthSigninLinkIndexImport.update({
  id: "/auth/signin/link/",
  path: "/auth/signin/link/",
  getParentRoute: () => rootRoute,
} as any);

const ProviderProviderWorkHistoryIndexRoute =
  ProviderProviderWorkHistoryIndexImport.update({
    id: "/provider/work-history/",
    path: "/provider/work-history/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderShiftsIndexRoute =
  ProviderProviderShiftsIndexImport.update({
    id: "/provider/shifts/",
    path: "/provider/shifts/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderSettingsIndexRoute =
  ProviderProviderSettingsIndexImport.update({
    id: "/provider/settings/",
    path: "/provider/settings/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderReferralsIndexRoute =
  ProviderProviderReferralsIndexImport.update({
    id: "/provider/referrals/",
    path: "/provider/referrals/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderProfileIndexRoute =
  ProviderProviderProfileIndexImport.update({
    id: "/provider/profile/",
    path: "/provider/profile/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderProcedureLogIndexRoute =
  ProviderProviderProcedureLogIndexImport.update({
    id: "/provider/procedure-log/",
    path: "/provider/procedure-log/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderHomeIndexRoute = ProviderProviderHomeIndexImport.update({
  id: "/provider/home/",
  path: "/provider/home/",
  getParentRoute: () => ProviderRoute,
} as any);

const ProviderProviderDocumentsIndexRoute =
  ProviderProviderDocumentsIndexImport.update({
    id: "/provider/documents/",
    path: "/provider/documents/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderChatIndexRoute = ProviderProviderChatIndexImport.update({
  id: "/provider/chat/",
  path: "/provider/chat/",
  getParentRoute: () => ProviderRoute,
} as any);

const ProviderProviderBillingIndexRoute =
  ProviderProviderBillingIndexImport.update({
    id: "/provider/billing/",
    path: "/provider/billing/",
    getParentRoute: () => ProviderRoute,
  } as any);

const OrgOrgSettingsIndexRoute = OrgOrgSettingsIndexImport.update({
  id: "/org/settings/",
  path: "/org/settings/",
  getParentRoute: () => OrgRoute,
} as any);

const OrgOrgProviderSearchIndexRoute = OrgOrgProviderSearchIndexImport.update({
  id: "/org/provider-search/",
  path: "/org/provider-search/",
  getParentRoute: () => OrgRoute,
} as any);

const OrgOrgProviderProfilesIndexRoute =
  OrgOrgProviderProfilesIndexImport.update({
    id: "/org/provider-profiles/",
    path: "/org/provider-profiles/",
    getParentRoute: () => OrgRoute,
  } as any);

const OrgOrgJobsIndexRoute = OrgOrgJobsIndexImport.update({
  id: "/org/jobs/",
  path: "/org/jobs/",
  getParentRoute: () => OrgRoute,
} as any);

const OrgOrgHomeIndexRoute = OrgOrgHomeIndexImport.update({
  id: "/org/home/",
  path: "/org/home/",
  getParentRoute: () => OrgRoute,
} as any);

const OrgOrgChatIndexRoute = OrgOrgChatIndexImport.update({
  id: "/org/chat/",
  path: "/org/chat/",
  getParentRoute: () => OrgRoute,
} as any);

const AdminAdminUsersIndexRoute = AdminAdminUsersIndexImport.update({
  id: "/admin/users/",
  path: "/admin/users/",
  getParentRoute: () => AdminRoute,
} as any);

const AdminAdminReferralsIndexRoute = AdminAdminReferralsIndexImport.update({
  id: "/admin/referrals/",
  path: "/admin/referrals/",
  getParentRoute: () => AdminRoute,
} as any);

const AdminAdminProviderSearchIndexRoute =
  AdminAdminProviderSearchIndexImport.update({
    id: "/admin/provider-search/",
    path: "/admin/provider-search/",
    getParentRoute: () => AdminRoute,
  } as any);

const AdminAdminOrganizationsIndexRoute =
  AdminAdminOrganizationsIndexImport.update({
    id: "/admin/organizations/",
    path: "/admin/organizations/",
    getParentRoute: () => AdminRoute,
  } as any);

const AdminAdminDocumentsIndexRoute = AdminAdminDocumentsIndexImport.update({
  id: "/admin/documents/",
  path: "/admin/documents/",
  getParentRoute: () => AdminRoute,
} as any);

const AdminAdminChatIndexRoute = AdminAdminChatIndexImport.update({
  id: "/admin/chat/",
  path: "/admin/chat/",
  getParentRoute: () => AdminRoute,
} as any);

const ProviderProviderShiftsSearchIndexRoute =
  ProviderProviderShiftsSearchIndexImport.update({
    id: "/provider/shifts/search/",
    path: "/provider/shifts/search/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderShiftsPreferencesIndexRoute =
  ProviderProviderShiftsPreferencesIndexImport.update({
    id: "/provider/shifts/preferences/",
    path: "/provider/shifts/preferences/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderShiftsPotentialIndexRoute =
  ProviderProviderShiftsPotentialIndexImport.update({
    id: "/provider/shifts/potential/",
    path: "/provider/shifts/potential/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderShiftsNewIndexRoute =
  ProviderProviderShiftsNewIndexImport.update({
    id: "/provider/shifts/new/",
    path: "/provider/shifts/new/",
    getParentRoute: () => ProviderRoute,
  } as any);

const ProviderProviderCredentialsPersonalInformationIndexRoute =
  ProviderProviderCredentialsPersonalInformationIndexImport.update({
    id: "/provider/credentials/personal-information/",
    path: "/provider/credentials/personal-information/",
    getParentRoute: () => ProviderRoute,
  } as any);

const OrgOrgProviderProfileUserIdIndexRoute =
  OrgOrgProviderProfileUserIdIndexImport.update({
    id: "/org/provider-profile/$userId/",
    path: "/org/provider-profile/$userId/",
    getParentRoute: () => OrgRoute,
  } as any);

const AdminAdminProviderProfileUserIdIndexRoute =
  AdminAdminProviderProfileUserIdIndexImport.update({
    id: "/admin/provider-profile/$userId/",
    path: "/admin/provider-profile/$userId/",
    getParentRoute: () => AdminRoute,
  } as any);

const AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute =
  AdminAdminOrganizationsOrgIdBusinessUnitsIndexImport.update({
    id: "/admin/organizations/$orgId/business-units/",
    path: "/admin/organizations/$orgId/business-units/",
    getParentRoute: () => AdminRoute,
  } as any);

const AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute =
  AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexImport.update(
    {
      id: "/admin/organizations/$orgId/business-units/$businessUnitId/users/",
      path: "/admin/organizations/$orgId/business-units/$businessUnitId/users/",
      getParentRoute: () => AdminRoute,
    } as any,
  );

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin": {
      id: "/_admin";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AdminImport;
      parentRoute: typeof rootRoute;
    };
    "/_org": {
      id: "/_org";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof OrgImport;
      parentRoute: typeof rootRoute;
    };
    "/_provider": {
      id: "/_provider";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof ProviderImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin/admin/": {
      id: "/_admin/admin/";
      path: "/admin";
      fullPath: "/admin";
      preLoaderRoute: typeof AdminAdminIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_provider/provider-onboarding/": {
      id: "/_provider/provider-onboarding/";
      path: "/provider-onboarding";
      fullPath: "/provider-onboarding";
      preLoaderRoute: typeof ProviderProviderOnboardingIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/auth-org/signin/": {
      id: "/auth-org/signin/";
      path: "/auth-org/signin";
      fullPath: "/auth-org/signin";
      preLoaderRoute: typeof AuthOrgSigninIndexImport;
      parentRoute: typeof rootRoute;
    };
    "/auth-provider/signin/": {
      id: "/auth-provider/signin/";
      path: "/auth-provider/signin";
      fullPath: "/auth-provider/signin";
      preLoaderRoute: typeof AuthProviderSigninIndexImport;
      parentRoute: typeof rootRoute;
    };
    "/auth/signin/": {
      id: "/auth/signin/";
      path: "/auth/signin";
      fullPath: "/auth/signin";
      preLoaderRoute: typeof AuthSigninIndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin/admin/chat/": {
      id: "/_admin/admin/chat/";
      path: "/admin/chat";
      fullPath: "/admin/chat";
      preLoaderRoute: typeof AdminAdminChatIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/admin/documents/": {
      id: "/_admin/admin/documents/";
      path: "/admin/documents";
      fullPath: "/admin/documents";
      preLoaderRoute: typeof AdminAdminDocumentsIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/admin/organizations/": {
      id: "/_admin/admin/organizations/";
      path: "/admin/organizations";
      fullPath: "/admin/organizations";
      preLoaderRoute: typeof AdminAdminOrganizationsIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/admin/provider-search/": {
      id: "/_admin/admin/provider-search/";
      path: "/admin/provider-search";
      fullPath: "/admin/provider-search";
      preLoaderRoute: typeof AdminAdminProviderSearchIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/admin/referrals/": {
      id: "/_admin/admin/referrals/";
      path: "/admin/referrals";
      fullPath: "/admin/referrals";
      preLoaderRoute: typeof AdminAdminReferralsIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/admin/users/": {
      id: "/_admin/admin/users/";
      path: "/admin/users";
      fullPath: "/admin/users";
      preLoaderRoute: typeof AdminAdminUsersIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_org/org/chat/": {
      id: "/_org/org/chat/";
      path: "/org/chat";
      fullPath: "/org/chat";
      preLoaderRoute: typeof OrgOrgChatIndexImport;
      parentRoute: typeof OrgImport;
    };
    "/_org/org/home/": {
      id: "/_org/org/home/";
      path: "/org/home";
      fullPath: "/org/home";
      preLoaderRoute: typeof OrgOrgHomeIndexImport;
      parentRoute: typeof OrgImport;
    };
    "/_org/org/jobs/": {
      id: "/_org/org/jobs/";
      path: "/org/jobs";
      fullPath: "/org/jobs";
      preLoaderRoute: typeof OrgOrgJobsIndexImport;
      parentRoute: typeof OrgImport;
    };
    "/_org/org/provider-profiles/": {
      id: "/_org/org/provider-profiles/";
      path: "/org/provider-profiles";
      fullPath: "/org/provider-profiles";
      preLoaderRoute: typeof OrgOrgProviderProfilesIndexImport;
      parentRoute: typeof OrgImport;
    };
    "/_org/org/provider-search/": {
      id: "/_org/org/provider-search/";
      path: "/org/provider-search";
      fullPath: "/org/provider-search";
      preLoaderRoute: typeof OrgOrgProviderSearchIndexImport;
      parentRoute: typeof OrgImport;
    };
    "/_org/org/settings/": {
      id: "/_org/org/settings/";
      path: "/org/settings";
      fullPath: "/org/settings";
      preLoaderRoute: typeof OrgOrgSettingsIndexImport;
      parentRoute: typeof OrgImport;
    };
    "/_provider/provider/billing/": {
      id: "/_provider/provider/billing/";
      path: "/provider/billing";
      fullPath: "/provider/billing";
      preLoaderRoute: typeof ProviderProviderBillingIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/chat/": {
      id: "/_provider/provider/chat/";
      path: "/provider/chat";
      fullPath: "/provider/chat";
      preLoaderRoute: typeof ProviderProviderChatIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/documents/": {
      id: "/_provider/provider/documents/";
      path: "/provider/documents";
      fullPath: "/provider/documents";
      preLoaderRoute: typeof ProviderProviderDocumentsIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/home/": {
      id: "/_provider/provider/home/";
      path: "/provider/home";
      fullPath: "/provider/home";
      preLoaderRoute: typeof ProviderProviderHomeIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/procedure-log/": {
      id: "/_provider/provider/procedure-log/";
      path: "/provider/procedure-log";
      fullPath: "/provider/procedure-log";
      preLoaderRoute: typeof ProviderProviderProcedureLogIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/profile/": {
      id: "/_provider/provider/profile/";
      path: "/provider/profile";
      fullPath: "/provider/profile";
      preLoaderRoute: typeof ProviderProviderProfileIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/referrals/": {
      id: "/_provider/provider/referrals/";
      path: "/provider/referrals";
      fullPath: "/provider/referrals";
      preLoaderRoute: typeof ProviderProviderReferralsIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/settings/": {
      id: "/_provider/provider/settings/";
      path: "/provider/settings";
      fullPath: "/provider/settings";
      preLoaderRoute: typeof ProviderProviderSettingsIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/shifts/": {
      id: "/_provider/provider/shifts/";
      path: "/provider/shifts";
      fullPath: "/provider/shifts";
      preLoaderRoute: typeof ProviderProviderShiftsIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/work-history/": {
      id: "/_provider/provider/work-history/";
      path: "/provider/work-history";
      fullPath: "/provider/work-history";
      preLoaderRoute: typeof ProviderProviderWorkHistoryIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/auth/signin/link/": {
      id: "/auth/signin/link/";
      path: "/auth/signin/link";
      fullPath: "/auth/signin/link";
      preLoaderRoute: typeof AuthSigninLinkIndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin/admin/provider-profile/$userId/": {
      id: "/_admin/admin/provider-profile/$userId/";
      path: "/admin/provider-profile/$userId";
      fullPath: "/admin/provider-profile/$userId";
      preLoaderRoute: typeof AdminAdminProviderProfileUserIdIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_org/org/provider-profile/$userId/": {
      id: "/_org/org/provider-profile/$userId/";
      path: "/org/provider-profile/$userId";
      fullPath: "/org/provider-profile/$userId";
      preLoaderRoute: typeof OrgOrgProviderProfileUserIdIndexImport;
      parentRoute: typeof OrgImport;
    };
    "/_provider/provider/credentials/personal-information/": {
      id: "/_provider/provider/credentials/personal-information/";
      path: "/provider/credentials/personal-information";
      fullPath: "/provider/credentials/personal-information";
      preLoaderRoute: typeof ProviderProviderCredentialsPersonalInformationIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/shifts/new/": {
      id: "/_provider/provider/shifts/new/";
      path: "/provider/shifts/new";
      fullPath: "/provider/shifts/new";
      preLoaderRoute: typeof ProviderProviderShiftsNewIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/shifts/potential/": {
      id: "/_provider/provider/shifts/potential/";
      path: "/provider/shifts/potential";
      fullPath: "/provider/shifts/potential";
      preLoaderRoute: typeof ProviderProviderShiftsPotentialIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/shifts/preferences/": {
      id: "/_provider/provider/shifts/preferences/";
      path: "/provider/shifts/preferences";
      fullPath: "/provider/shifts/preferences";
      preLoaderRoute: typeof ProviderProviderShiftsPreferencesIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_provider/provider/shifts/search/": {
      id: "/_provider/provider/shifts/search/";
      path: "/provider/shifts/search";
      fullPath: "/provider/shifts/search";
      preLoaderRoute: typeof ProviderProviderShiftsSearchIndexImport;
      parentRoute: typeof ProviderImport;
    };
    "/_admin/admin/organizations/$orgId/business-units/": {
      id: "/_admin/admin/organizations/$orgId/business-units/";
      path: "/admin/organizations/$orgId/business-units";
      fullPath: "/admin/organizations/$orgId/business-units";
      preLoaderRoute: typeof AdminAdminOrganizationsOrgIdBusinessUnitsIndexImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/": {
      id: "/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/";
      path: "/admin/organizations/$orgId/business-units/$businessUnitId/users";
      fullPath: "/admin/organizations/$orgId/business-units/$businessUnitId/users";
      preLoaderRoute: typeof AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexImport;
      parentRoute: typeof AdminImport;
    };
  }
}

// Create and export the route tree

interface AdminRouteChildren {
  AdminAdminIndexRoute: typeof AdminAdminIndexRoute;
  AdminAdminChatIndexRoute: typeof AdminAdminChatIndexRoute;
  AdminAdminDocumentsIndexRoute: typeof AdminAdminDocumentsIndexRoute;
  AdminAdminOrganizationsIndexRoute: typeof AdminAdminOrganizationsIndexRoute;
  AdminAdminProviderSearchIndexRoute: typeof AdminAdminProviderSearchIndexRoute;
  AdminAdminReferralsIndexRoute: typeof AdminAdminReferralsIndexRoute;
  AdminAdminUsersIndexRoute: typeof AdminAdminUsersIndexRoute;
  AdminAdminProviderProfileUserIdIndexRoute: typeof AdminAdminProviderProfileUserIdIndexRoute;
  AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute: typeof AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute;
  AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute: typeof AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute;
}

const AdminRouteChildren: AdminRouteChildren = {
  AdminAdminIndexRoute: AdminAdminIndexRoute,
  AdminAdminChatIndexRoute: AdminAdminChatIndexRoute,
  AdminAdminDocumentsIndexRoute: AdminAdminDocumentsIndexRoute,
  AdminAdminOrganizationsIndexRoute: AdminAdminOrganizationsIndexRoute,
  AdminAdminProviderSearchIndexRoute: AdminAdminProviderSearchIndexRoute,
  AdminAdminReferralsIndexRoute: AdminAdminReferralsIndexRoute,
  AdminAdminUsersIndexRoute: AdminAdminUsersIndexRoute,
  AdminAdminProviderProfileUserIdIndexRoute:
    AdminAdminProviderProfileUserIdIndexRoute,
  AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute:
    AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute,
  AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute:
    AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute,
};

const AdminRouteWithChildren = AdminRoute._addFileChildren(AdminRouteChildren);

interface OrgRouteChildren {
  OrgOrgChatIndexRoute: typeof OrgOrgChatIndexRoute;
  OrgOrgHomeIndexRoute: typeof OrgOrgHomeIndexRoute;
  OrgOrgJobsIndexRoute: typeof OrgOrgJobsIndexRoute;
  OrgOrgProviderProfilesIndexRoute: typeof OrgOrgProviderProfilesIndexRoute;
  OrgOrgProviderSearchIndexRoute: typeof OrgOrgProviderSearchIndexRoute;
  OrgOrgSettingsIndexRoute: typeof OrgOrgSettingsIndexRoute;
  OrgOrgProviderProfileUserIdIndexRoute: typeof OrgOrgProviderProfileUserIdIndexRoute;
}

const OrgRouteChildren: OrgRouteChildren = {
  OrgOrgChatIndexRoute: OrgOrgChatIndexRoute,
  OrgOrgHomeIndexRoute: OrgOrgHomeIndexRoute,
  OrgOrgJobsIndexRoute: OrgOrgJobsIndexRoute,
  OrgOrgProviderProfilesIndexRoute: OrgOrgProviderProfilesIndexRoute,
  OrgOrgProviderSearchIndexRoute: OrgOrgProviderSearchIndexRoute,
  OrgOrgSettingsIndexRoute: OrgOrgSettingsIndexRoute,
  OrgOrgProviderProfileUserIdIndexRoute: OrgOrgProviderProfileUserIdIndexRoute,
};

const OrgRouteWithChildren = OrgRoute._addFileChildren(OrgRouteChildren);

interface ProviderRouteChildren {
  ProviderProviderOnboardingIndexRoute: typeof ProviderProviderOnboardingIndexRoute;
  ProviderProviderBillingIndexRoute: typeof ProviderProviderBillingIndexRoute;
  ProviderProviderChatIndexRoute: typeof ProviderProviderChatIndexRoute;
  ProviderProviderDocumentsIndexRoute: typeof ProviderProviderDocumentsIndexRoute;
  ProviderProviderHomeIndexRoute: typeof ProviderProviderHomeIndexRoute;
  ProviderProviderProcedureLogIndexRoute: typeof ProviderProviderProcedureLogIndexRoute;
  ProviderProviderProfileIndexRoute: typeof ProviderProviderProfileIndexRoute;
  ProviderProviderReferralsIndexRoute: typeof ProviderProviderReferralsIndexRoute;
  ProviderProviderSettingsIndexRoute: typeof ProviderProviderSettingsIndexRoute;
  ProviderProviderShiftsIndexRoute: typeof ProviderProviderShiftsIndexRoute;
  ProviderProviderWorkHistoryIndexRoute: typeof ProviderProviderWorkHistoryIndexRoute;
  ProviderProviderCredentialsPersonalInformationIndexRoute: typeof ProviderProviderCredentialsPersonalInformationIndexRoute;
  ProviderProviderShiftsNewIndexRoute: typeof ProviderProviderShiftsNewIndexRoute;
  ProviderProviderShiftsPotentialIndexRoute: typeof ProviderProviderShiftsPotentialIndexRoute;
  ProviderProviderShiftsPreferencesIndexRoute: typeof ProviderProviderShiftsPreferencesIndexRoute;
  ProviderProviderShiftsSearchIndexRoute: typeof ProviderProviderShiftsSearchIndexRoute;
}

const ProviderRouteChildren: ProviderRouteChildren = {
  ProviderProviderOnboardingIndexRoute: ProviderProviderOnboardingIndexRoute,
  ProviderProviderBillingIndexRoute: ProviderProviderBillingIndexRoute,
  ProviderProviderChatIndexRoute: ProviderProviderChatIndexRoute,
  ProviderProviderDocumentsIndexRoute: ProviderProviderDocumentsIndexRoute,
  ProviderProviderHomeIndexRoute: ProviderProviderHomeIndexRoute,
  ProviderProviderProcedureLogIndexRoute:
    ProviderProviderProcedureLogIndexRoute,
  ProviderProviderProfileIndexRoute: ProviderProviderProfileIndexRoute,
  ProviderProviderReferralsIndexRoute: ProviderProviderReferralsIndexRoute,
  ProviderProviderSettingsIndexRoute: ProviderProviderSettingsIndexRoute,
  ProviderProviderShiftsIndexRoute: ProviderProviderShiftsIndexRoute,
  ProviderProviderWorkHistoryIndexRoute: ProviderProviderWorkHistoryIndexRoute,
  ProviderProviderCredentialsPersonalInformationIndexRoute:
    ProviderProviderCredentialsPersonalInformationIndexRoute,
  ProviderProviderShiftsNewIndexRoute: ProviderProviderShiftsNewIndexRoute,
  ProviderProviderShiftsPotentialIndexRoute:
    ProviderProviderShiftsPotentialIndexRoute,
  ProviderProviderShiftsPreferencesIndexRoute:
    ProviderProviderShiftsPreferencesIndexRoute,
  ProviderProviderShiftsSearchIndexRoute:
    ProviderProviderShiftsSearchIndexRoute,
};

const ProviderRouteWithChildren = ProviderRoute._addFileChildren(
  ProviderRouteChildren,
);

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute;
  "": typeof ProviderRouteWithChildren;
  "/admin": typeof AdminAdminIndexRoute;
  "/provider-onboarding": typeof ProviderProviderOnboardingIndexRoute;
  "/auth-org/signin": typeof AuthOrgSigninIndexRoute;
  "/auth-provider/signin": typeof AuthProviderSigninIndexRoute;
  "/auth/signin": typeof AuthSigninIndexRoute;
  "/admin/chat": typeof AdminAdminChatIndexRoute;
  "/admin/documents": typeof AdminAdminDocumentsIndexRoute;
  "/admin/organizations": typeof AdminAdminOrganizationsIndexRoute;
  "/admin/provider-search": typeof AdminAdminProviderSearchIndexRoute;
  "/admin/referrals": typeof AdminAdminReferralsIndexRoute;
  "/admin/users": typeof AdminAdminUsersIndexRoute;
  "/org/chat": typeof OrgOrgChatIndexRoute;
  "/org/home": typeof OrgOrgHomeIndexRoute;
  "/org/jobs": typeof OrgOrgJobsIndexRoute;
  "/org/provider-profiles": typeof OrgOrgProviderProfilesIndexRoute;
  "/org/provider-search": typeof OrgOrgProviderSearchIndexRoute;
  "/org/settings": typeof OrgOrgSettingsIndexRoute;
  "/provider/billing": typeof ProviderProviderBillingIndexRoute;
  "/provider/chat": typeof ProviderProviderChatIndexRoute;
  "/provider/documents": typeof ProviderProviderDocumentsIndexRoute;
  "/provider/home": typeof ProviderProviderHomeIndexRoute;
  "/provider/procedure-log": typeof ProviderProviderProcedureLogIndexRoute;
  "/provider/profile": typeof ProviderProviderProfileIndexRoute;
  "/provider/referrals": typeof ProviderProviderReferralsIndexRoute;
  "/provider/settings": typeof ProviderProviderSettingsIndexRoute;
  "/provider/shifts": typeof ProviderProviderShiftsIndexRoute;
  "/provider/work-history": typeof ProviderProviderWorkHistoryIndexRoute;
  "/auth/signin/link": typeof AuthSigninLinkIndexRoute;
  "/admin/provider-profile/$userId": typeof AdminAdminProviderProfileUserIdIndexRoute;
  "/org/provider-profile/$userId": typeof OrgOrgProviderProfileUserIdIndexRoute;
  "/provider/credentials/personal-information": typeof ProviderProviderCredentialsPersonalInformationIndexRoute;
  "/provider/shifts/new": typeof ProviderProviderShiftsNewIndexRoute;
  "/provider/shifts/potential": typeof ProviderProviderShiftsPotentialIndexRoute;
  "/provider/shifts/preferences": typeof ProviderProviderShiftsPreferencesIndexRoute;
  "/provider/shifts/search": typeof ProviderProviderShiftsSearchIndexRoute;
  "/admin/organizations/$orgId/business-units": typeof AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute;
  "/admin/organizations/$orgId/business-units/$businessUnitId/users": typeof AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute;
  "": typeof ProviderRouteWithChildren;
  "/admin": typeof AdminAdminIndexRoute;
  "/provider-onboarding": typeof ProviderProviderOnboardingIndexRoute;
  "/auth-org/signin": typeof AuthOrgSigninIndexRoute;
  "/auth-provider/signin": typeof AuthProviderSigninIndexRoute;
  "/auth/signin": typeof AuthSigninIndexRoute;
  "/admin/chat": typeof AdminAdminChatIndexRoute;
  "/admin/documents": typeof AdminAdminDocumentsIndexRoute;
  "/admin/organizations": typeof AdminAdminOrganizationsIndexRoute;
  "/admin/provider-search": typeof AdminAdminProviderSearchIndexRoute;
  "/admin/referrals": typeof AdminAdminReferralsIndexRoute;
  "/admin/users": typeof AdminAdminUsersIndexRoute;
  "/org/chat": typeof OrgOrgChatIndexRoute;
  "/org/home": typeof OrgOrgHomeIndexRoute;
  "/org/jobs": typeof OrgOrgJobsIndexRoute;
  "/org/provider-profiles": typeof OrgOrgProviderProfilesIndexRoute;
  "/org/provider-search": typeof OrgOrgProviderSearchIndexRoute;
  "/org/settings": typeof OrgOrgSettingsIndexRoute;
  "/provider/billing": typeof ProviderProviderBillingIndexRoute;
  "/provider/chat": typeof ProviderProviderChatIndexRoute;
  "/provider/documents": typeof ProviderProviderDocumentsIndexRoute;
  "/provider/home": typeof ProviderProviderHomeIndexRoute;
  "/provider/procedure-log": typeof ProviderProviderProcedureLogIndexRoute;
  "/provider/profile": typeof ProviderProviderProfileIndexRoute;
  "/provider/referrals": typeof ProviderProviderReferralsIndexRoute;
  "/provider/settings": typeof ProviderProviderSettingsIndexRoute;
  "/provider/shifts": typeof ProviderProviderShiftsIndexRoute;
  "/provider/work-history": typeof ProviderProviderWorkHistoryIndexRoute;
  "/auth/signin/link": typeof AuthSigninLinkIndexRoute;
  "/admin/provider-profile/$userId": typeof AdminAdminProviderProfileUserIdIndexRoute;
  "/org/provider-profile/$userId": typeof OrgOrgProviderProfileUserIdIndexRoute;
  "/provider/credentials/personal-information": typeof ProviderProviderCredentialsPersonalInformationIndexRoute;
  "/provider/shifts/new": typeof ProviderProviderShiftsNewIndexRoute;
  "/provider/shifts/potential": typeof ProviderProviderShiftsPotentialIndexRoute;
  "/provider/shifts/preferences": typeof ProviderProviderShiftsPreferencesIndexRoute;
  "/provider/shifts/search": typeof ProviderProviderShiftsSearchIndexRoute;
  "/admin/organizations/$orgId/business-units": typeof AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute;
  "/admin/organizations/$orgId/business-units/$businessUnitId/users": typeof AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexRoute;
  "/_admin": typeof AdminRouteWithChildren;
  "/_org": typeof OrgRouteWithChildren;
  "/_provider": typeof ProviderRouteWithChildren;
  "/_admin/admin/": typeof AdminAdminIndexRoute;
  "/_provider/provider-onboarding/": typeof ProviderProviderOnboardingIndexRoute;
  "/auth-org/signin/": typeof AuthOrgSigninIndexRoute;
  "/auth-provider/signin/": typeof AuthProviderSigninIndexRoute;
  "/auth/signin/": typeof AuthSigninIndexRoute;
  "/_admin/admin/chat/": typeof AdminAdminChatIndexRoute;
  "/_admin/admin/documents/": typeof AdminAdminDocumentsIndexRoute;
  "/_admin/admin/organizations/": typeof AdminAdminOrganizationsIndexRoute;
  "/_admin/admin/provider-search/": typeof AdminAdminProviderSearchIndexRoute;
  "/_admin/admin/referrals/": typeof AdminAdminReferralsIndexRoute;
  "/_admin/admin/users/": typeof AdminAdminUsersIndexRoute;
  "/_org/org/chat/": typeof OrgOrgChatIndexRoute;
  "/_org/org/home/": typeof OrgOrgHomeIndexRoute;
  "/_org/org/jobs/": typeof OrgOrgJobsIndexRoute;
  "/_org/org/provider-profiles/": typeof OrgOrgProviderProfilesIndexRoute;
  "/_org/org/provider-search/": typeof OrgOrgProviderSearchIndexRoute;
  "/_org/org/settings/": typeof OrgOrgSettingsIndexRoute;
  "/_provider/provider/billing/": typeof ProviderProviderBillingIndexRoute;
  "/_provider/provider/chat/": typeof ProviderProviderChatIndexRoute;
  "/_provider/provider/documents/": typeof ProviderProviderDocumentsIndexRoute;
  "/_provider/provider/home/": typeof ProviderProviderHomeIndexRoute;
  "/_provider/provider/procedure-log/": typeof ProviderProviderProcedureLogIndexRoute;
  "/_provider/provider/profile/": typeof ProviderProviderProfileIndexRoute;
  "/_provider/provider/referrals/": typeof ProviderProviderReferralsIndexRoute;
  "/_provider/provider/settings/": typeof ProviderProviderSettingsIndexRoute;
  "/_provider/provider/shifts/": typeof ProviderProviderShiftsIndexRoute;
  "/_provider/provider/work-history/": typeof ProviderProviderWorkHistoryIndexRoute;
  "/auth/signin/link/": typeof AuthSigninLinkIndexRoute;
  "/_admin/admin/provider-profile/$userId/": typeof AdminAdminProviderProfileUserIdIndexRoute;
  "/_org/org/provider-profile/$userId/": typeof OrgOrgProviderProfileUserIdIndexRoute;
  "/_provider/provider/credentials/personal-information/": typeof ProviderProviderCredentialsPersonalInformationIndexRoute;
  "/_provider/provider/shifts/new/": typeof ProviderProviderShiftsNewIndexRoute;
  "/_provider/provider/shifts/potential/": typeof ProviderProviderShiftsPotentialIndexRoute;
  "/_provider/provider/shifts/preferences/": typeof ProviderProviderShiftsPreferencesIndexRoute;
  "/_provider/provider/shifts/search/": typeof ProviderProviderShiftsSearchIndexRoute;
  "/_admin/admin/organizations/$orgId/business-units/": typeof AdminAdminOrganizationsOrgIdBusinessUnitsIndexRoute;
  "/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/": typeof AdminAdminOrganizationsOrgIdBusinessUnitsBusinessUnitIdUsersIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | "/"
    | ""
    | "/admin"
    | "/provider-onboarding"
    | "/auth-org/signin"
    | "/auth-provider/signin"
    | "/auth/signin"
    | "/admin/chat"
    | "/admin/documents"
    | "/admin/organizations"
    | "/admin/provider-search"
    | "/admin/referrals"
    | "/admin/users"
    | "/org/chat"
    | "/org/home"
    | "/org/jobs"
    | "/org/provider-profiles"
    | "/org/provider-search"
    | "/org/settings"
    | "/provider/billing"
    | "/provider/chat"
    | "/provider/documents"
    | "/provider/home"
    | "/provider/procedure-log"
    | "/provider/profile"
    | "/provider/referrals"
    | "/provider/settings"
    | "/provider/shifts"
    | "/provider/work-history"
    | "/auth/signin/link"
    | "/admin/provider-profile/$userId"
    | "/org/provider-profile/$userId"
    | "/provider/credentials/personal-information"
    | "/provider/shifts/new"
    | "/provider/shifts/potential"
    | "/provider/shifts/preferences"
    | "/provider/shifts/search"
    | "/admin/organizations/$orgId/business-units"
    | "/admin/organizations/$orgId/business-units/$businessUnitId/users";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/"
    | ""
    | "/admin"
    | "/provider-onboarding"
    | "/auth-org/signin"
    | "/auth-provider/signin"
    | "/auth/signin"
    | "/admin/chat"
    | "/admin/documents"
    | "/admin/organizations"
    | "/admin/provider-search"
    | "/admin/referrals"
    | "/admin/users"
    | "/org/chat"
    | "/org/home"
    | "/org/jobs"
    | "/org/provider-profiles"
    | "/org/provider-search"
    | "/org/settings"
    | "/provider/billing"
    | "/provider/chat"
    | "/provider/documents"
    | "/provider/home"
    | "/provider/procedure-log"
    | "/provider/profile"
    | "/provider/referrals"
    | "/provider/settings"
    | "/provider/shifts"
    | "/provider/work-history"
    | "/auth/signin/link"
    | "/admin/provider-profile/$userId"
    | "/org/provider-profile/$userId"
    | "/provider/credentials/personal-information"
    | "/provider/shifts/new"
    | "/provider/shifts/potential"
    | "/provider/shifts/preferences"
    | "/provider/shifts/search"
    | "/admin/organizations/$orgId/business-units"
    | "/admin/organizations/$orgId/business-units/$businessUnitId/users";
  id:
    | "__root__"
    | "/"
    | "/_admin"
    | "/_org"
    | "/_provider"
    | "/_admin/admin/"
    | "/_provider/provider-onboarding/"
    | "/auth-org/signin/"
    | "/auth-provider/signin/"
    | "/auth/signin/"
    | "/_admin/admin/chat/"
    | "/_admin/admin/documents/"
    | "/_admin/admin/organizations/"
    | "/_admin/admin/provider-search/"
    | "/_admin/admin/referrals/"
    | "/_admin/admin/users/"
    | "/_org/org/chat/"
    | "/_org/org/home/"
    | "/_org/org/jobs/"
    | "/_org/org/provider-profiles/"
    | "/_org/org/provider-search/"
    | "/_org/org/settings/"
    | "/_provider/provider/billing/"
    | "/_provider/provider/chat/"
    | "/_provider/provider/documents/"
    | "/_provider/provider/home/"
    | "/_provider/provider/procedure-log/"
    | "/_provider/provider/profile/"
    | "/_provider/provider/referrals/"
    | "/_provider/provider/settings/"
    | "/_provider/provider/shifts/"
    | "/_provider/provider/work-history/"
    | "/auth/signin/link/"
    | "/_admin/admin/provider-profile/$userId/"
    | "/_org/org/provider-profile/$userId/"
    | "/_provider/provider/credentials/personal-information/"
    | "/_provider/provider/shifts/new/"
    | "/_provider/provider/shifts/potential/"
    | "/_provider/provider/shifts/preferences/"
    | "/_provider/provider/shifts/search/"
    | "/_admin/admin/organizations/$orgId/business-units/"
    | "/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AdminRoute: typeof AdminRouteWithChildren;
  OrgRoute: typeof OrgRouteWithChildren;
  ProviderRoute: typeof ProviderRouteWithChildren;
  AuthOrgSigninIndexRoute: typeof AuthOrgSigninIndexRoute;
  AuthProviderSigninIndexRoute: typeof AuthProviderSigninIndexRoute;
  AuthSigninIndexRoute: typeof AuthSigninIndexRoute;
  AuthSigninLinkIndexRoute: typeof AuthSigninLinkIndexRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminRoute: AdminRouteWithChildren,
  OrgRoute: OrgRouteWithChildren,
  ProviderRoute: ProviderRouteWithChildren,
  AuthOrgSigninIndexRoute: AuthOrgSigninIndexRoute,
  AuthProviderSigninIndexRoute: AuthProviderSigninIndexRoute,
  AuthSigninIndexRoute: AuthSigninIndexRoute,
  AuthSigninLinkIndexRoute: AuthSigninLinkIndexRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_admin",
        "/_org",
        "/_provider",
        "/auth-org/signin/",
        "/auth-provider/signin/",
        "/auth/signin/",
        "/auth/signin/link/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_admin": {
      "filePath": "_admin.tsx",
      "children": [
        "/_admin/admin/",
        "/_admin/admin/chat/",
        "/_admin/admin/documents/",
        "/_admin/admin/organizations/",
        "/_admin/admin/provider-search/",
        "/_admin/admin/referrals/",
        "/_admin/admin/users/",
        "/_admin/admin/provider-profile/$userId/",
        "/_admin/admin/organizations/$orgId/business-units/",
        "/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/"
      ]
    },
    "/_org": {
      "filePath": "_org.tsx",
      "children": [
        "/_org/org/chat/",
        "/_org/org/home/",
        "/_org/org/jobs/",
        "/_org/org/provider-profiles/",
        "/_org/org/provider-search/",
        "/_org/org/settings/",
        "/_org/org/provider-profile/$userId/"
      ]
    },
    "/_provider": {
      "filePath": "_provider.tsx",
      "children": [
        "/_provider/provider-onboarding/",
        "/_provider/provider/billing/",
        "/_provider/provider/chat/",
        "/_provider/provider/documents/",
        "/_provider/provider/home/",
        "/_provider/provider/procedure-log/",
        "/_provider/provider/profile/",
        "/_provider/provider/referrals/",
        "/_provider/provider/settings/",
        "/_provider/provider/shifts/",
        "/_provider/provider/work-history/",
        "/_provider/provider/credentials/personal-information/",
        "/_provider/provider/shifts/new/",
        "/_provider/provider/shifts/potential/",
        "/_provider/provider/shifts/preferences/",
        "/_provider/provider/shifts/search/"
      ]
    },
    "/_admin/admin/": {
      "filePath": "_admin/admin/index.tsx",
      "parent": "/_admin"
    },
    "/_provider/provider-onboarding/": {
      "filePath": "_provider/provider-onboarding/index.tsx",
      "parent": "/_provider"
    },
    "/auth-org/signin/": {
      "filePath": "auth-org/signin/index.tsx"
    },
    "/auth-provider/signin/": {
      "filePath": "auth-provider/signin/index.tsx"
    },
    "/auth/signin/": {
      "filePath": "auth/signin/index.tsx"
    },
    "/_admin/admin/chat/": {
      "filePath": "_admin/admin/chat/index.tsx",
      "parent": "/_admin"
    },
    "/_admin/admin/documents/": {
      "filePath": "_admin/admin/documents/index.tsx",
      "parent": "/_admin"
    },
    "/_admin/admin/organizations/": {
      "filePath": "_admin/admin/organizations/index.tsx",
      "parent": "/_admin"
    },
    "/_admin/admin/provider-search/": {
      "filePath": "_admin/admin/provider-search/index.tsx",
      "parent": "/_admin"
    },
    "/_admin/admin/referrals/": {
      "filePath": "_admin/admin/referrals/index.tsx",
      "parent": "/_admin"
    },
    "/_admin/admin/users/": {
      "filePath": "_admin/admin/users/index.tsx",
      "parent": "/_admin"
    },
    "/_org/org/chat/": {
      "filePath": "_org/org/chat/index.tsx",
      "parent": "/_org"
    },
    "/_org/org/home/": {
      "filePath": "_org/org/home/index.tsx",
      "parent": "/_org"
    },
    "/_org/org/jobs/": {
      "filePath": "_org/org/jobs/index.tsx",
      "parent": "/_org"
    },
    "/_org/org/provider-profiles/": {
      "filePath": "_org/org/provider-profiles/index.tsx",
      "parent": "/_org"
    },
    "/_org/org/provider-search/": {
      "filePath": "_org/org/provider-search/index.tsx",
      "parent": "/_org"
    },
    "/_org/org/settings/": {
      "filePath": "_org/org/settings/index.tsx",
      "parent": "/_org"
    },
    "/_provider/provider/billing/": {
      "filePath": "_provider/provider/billing/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/chat/": {
      "filePath": "_provider/provider/chat/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/documents/": {
      "filePath": "_provider/provider/documents/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/home/": {
      "filePath": "_provider/provider/home/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/procedure-log/": {
      "filePath": "_provider/provider/procedure-log/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/profile/": {
      "filePath": "_provider/provider/profile/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/referrals/": {
      "filePath": "_provider/provider/referrals/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/settings/": {
      "filePath": "_provider/provider/settings/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/shifts/": {
      "filePath": "_provider/provider/shifts/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/work-history/": {
      "filePath": "_provider/provider/work-history/index.tsx",
      "parent": "/_provider"
    },
    "/auth/signin/link/": {
      "filePath": "auth/signin/link/index.tsx"
    },
    "/_admin/admin/provider-profile/$userId/": {
      "filePath": "_admin/admin/provider-profile/$userId/index.tsx",
      "parent": "/_admin"
    },
    "/_org/org/provider-profile/$userId/": {
      "filePath": "_org/org/provider-profile/$userId/index.tsx",
      "parent": "/_org"
    },
    "/_provider/provider/credentials/personal-information/": {
      "filePath": "_provider/provider/credentials/personal-information/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/shifts/new/": {
      "filePath": "_provider/provider/shifts/new/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/shifts/potential/": {
      "filePath": "_provider/provider/shifts/potential/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/shifts/preferences/": {
      "filePath": "_provider/provider/shifts/preferences/index.tsx",
      "parent": "/_provider"
    },
    "/_provider/provider/shifts/search/": {
      "filePath": "_provider/provider/shifts/search/index.tsx",
      "parent": "/_provider"
    },
    "/_admin/admin/organizations/$orgId/business-units/": {
      "filePath": "_admin/admin/organizations/$orgId/business-units/index.tsx",
      "parent": "/_admin"
    },
    "/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/": {
      "filePath": "_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/index.tsx",
      "parent": "/_admin"
    }
  }
}
ROUTE_MANIFEST_END */
