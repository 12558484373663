import { getChatThreadMessages } from "@/ajax/chat/getChatThreadMessages";
import { ChatThread } from "@/ajax/chat/getChatThreads";
import { Button } from "@/components/ui/button";
import { useQuery } from "@tanstack/react-query";
import { AlertCircle, Loader2, Send } from "lucide-react";
import { AdminChatThreadHeader } from "./AdminChatThreadHeader";
import { AdminChatMessageList } from "./AdminChatMessageList";
import { useEffect } from "react";
import { asyncInterval } from "@/common/utils/asyncInterval";
import { adminGetChatThreadMessages } from "@/ajax/chat/adminGetChatThreadMessages";

interface ThreadViewProps {
  thread: ChatThread;
  onBack: () => void;
  readOnly?: boolean;
  onUnarchive: () => void;
}

// Loading state component
const LoadingState = () => (
  <div className="flex flex-col h-full items-center justify-center">
    <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
    <p className="mt-2 text-gray-600">Loading messages...</p>
  </div>
);

// Error state component
const ErrorState = () => (
  <div className="flex flex-col h-full items-center justify-center text-red-600">
    <AlertCircle className="h-8 w-8" />
    <p className="mt-2">Failed to load messages</p>
    <Button
      variant="outline"
      className="mt-4"
      onClick={() => window.location.reload()}
    >
      Retry
    </Button>
  </div>
);

// Main thread view component
export const AdminChatThreadView: React.FC<ThreadViewProps> = ({
  thread,
  onBack,
}) => {
  const {
    data: messages,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["chatThreadMessages", thread.id],
    queryFn: () => adminGetChatThreadMessages({ threadId: thread.id }),
    staleTime: 0,
    refetchInterval: (query) => {
      return 5_000 + Math.random() * 5_000;
    },
  });

  useEffect(() => {
    let cleanup: (() => void) | undefined;

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        cleanup = asyncInterval({
          fn: async () => {
            // TODO: Implement heartbeat
          },
          interval: 8_000,
          jitter: 4_000,
        });
      } else {
        cleanup?.();
        cleanup = undefined;
      }
    };

    // Start interval if initially visible
    handleVisibilityChange();

    // Add visibility change listener
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      cleanup?.();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [thread.id]);

  if (isLoading) return <LoadingState />;
  if (error) return <ErrorState />;

  return (
    <div className="flex flex-col h-full">
      <AdminChatThreadHeader thread={thread} onBack={onBack} />
      <AdminChatMessageList messages={messages} />
    </div>
  );
};
