import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
export type UserProfileShareType = {
  id: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  shared_with_user_id: string;
  include_credential_files: boolean;
  include_contact_info: boolean;
  name_value: Record<string, any>;
  specialty_value: Record<string, any>;
  address_value: Record<string, any>;
};

export const getUserProfileShares = async (
  userId: string,
): Promise<UserProfileShareType[]> => {
  return await callSupabaseFnFromClient<UserProfileShareType[]>({
    fnName: "get_user_profile_shares",
    args: {
      user_id_param: userId,
    },
  });
};
