import { ChatThreadMessage } from "@/ajax/chat/getChatThreadMessages";
import { format, isToday, differenceInMinutes } from "date-fns";
import { useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { ChatMessage } from "../ChatMessage";

export const AdminChatMessageList = ({
  messages,
}: {
  messages: ChatThreadMessage[] | undefined;
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "instant" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const shouldGroupWithPreviousMessage = (
    message: ChatThreadMessage,
    prevMessage: ChatThreadMessage | undefined,
  ) => {
    if (!prevMessage) return false;
    return (
      message.sender.id === prevMessage.sender.id &&
      Math.abs(
        differenceInMinutes(message.created_at, prevMessage.created_at),
      ) < 5
    );
  };

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-100">
      <AnimatePresence initial={false}>
        {messages?.map((message, index) => {
          const prevMessage = messages[index - 1];
          const isGrouped = shouldGroupWithPreviousMessage(
            message,
            prevMessage,
          );
          const isOrganization = message.sender.organization_name !== null;
          const bgColor = isOrganization ? "bg-emerald-600" : "bg-blue-600";

          if (isGrouped) {
            return (
              <motion.div
                key={message.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="flex items-start space-x-2 mt-1"
              >
                <div className="w-8" />
                <div className="flex flex-col">
                  <div className={`rounded-xl px-4 py-2 text-white ${bgColor}`}>
                    {message.content.text}
                  </div>
                </div>
              </motion.div>
            );
          }

          return (
            <motion.div
              key={message.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              className="flex items-start space-x-2"
            >
              <div className="flex flex-col">
                <div className="flex items-center gap-2 mb-1 ml-1">
                  <span className="text-sm font-semibold text-gray-600">
                    {message.sender.organization_name ? (
                      <>
                        {message.sender.organization_name}
                        {message.sender.first_name &&
                          ` (${message.sender.first_name} ${message.sender.last_name})`}
                      </>
                    ) : (
                      `${message.sender.first_name} ${message.sender.last_name}`
                    )}
                  </span>
                  <span className="text-xs text-gray-500">
                    {isToday(message.created_at)
                      ? format(message.created_at, "h:mm a")
                      : format(message.created_at, "MMM d, h:mm a")}
                  </span>
                </div>
                <div className={`rounded-xl px-4 py-2 text-white ${bgColor}`}>
                  <ChatMessage message={message.content.text} />
                </div>
              </div>
            </motion.div>
          );
        })}
      </AnimatePresence>
      <div ref={messagesEndRef} />
    </div>
  );
};
