import { queryOptions } from "@tanstack/react-query";
import { getProviderUser } from "./auth/getProviderUser";
import { getFiles, getFilesCheckPermission } from "./documents/getFiles";
import { getProviderCommunicationPreferences } from "./settings/getProviderCommunicationPreferences";
import { getFileTypes } from "./documents/getFileTypes";
import { getReferrals } from "./referrals/getReferrals";
import { createReferralLink } from "./referrals/createReferralLink";
import { getHealthcareRoles } from "./shift_preferences/getHealthcareRoles";
import { getSpecialties } from "./shift_preferences/getSpecialties";
import { getCredentialsFields } from "./credentials/getCredentialsFields";
import { getSpecialtyCodes } from "./shift_preferences/getSpecialtyCodes";
import { getHealthcareRole } from "./shift_preferences/getHealthcareRole";
import { getSharedUser } from "./profile/getSharedUser";
import { getUserProfileShares } from "./profile/getUserProfileShares";
import { getProfileImage } from "./profile/getProfileImage";
import { getWorkShiftPreferences } from "./shift_preferences/getWorkShiftPreferences";
import { getChatUnreadMessagesCount } from "./chat/getUnreadMessagesCount";

export const FILES_QUERY_KEY = "files";
export const filesQueryOptions = ({
  switchUserId,
  id,
}: { switchUserId?: string; id?: string } = {}) =>
  queryOptions({
    queryKey: [FILES_QUERY_KEY, switchUserId, id],
    retry: 2,
    queryFn: () =>
      getFiles({
        id,
        switchUserId,
      }),
  });

export const FILES_CHECK_PERMISSION_QUERY_KEY = "filesWithPermission";
export const filesCheckPermissionQueryOptions = ({
  switchUserId,
  id,
}: { switchUserId?: string; id?: string } = {}) =>
  queryOptions({
    queryKey: [FILES_CHECK_PERMISSION_QUERY_KEY, switchUserId, id],
    retry: 2,
    queryFn: () =>
      getFilesCheckPermission({
        id,
        switchUserId,
      }),
  });

export const PROVIDER_USER_QUERY_KEY = "providerUser";
export const providerUserQueryOptions = ({
  staleTime = 1000 * 60, // 1 minute
}: { staleTime?: number } = {}) =>
  queryOptions({
    queryKey: [PROVIDER_USER_QUERY_KEY],
    queryFn: getProviderUser,
    staleTime,
  });

export const COMMUNICATION_PREFERENCES_QUERY_KEY = "communicationPreferences";
export const communicationPreferencesQueryOptions = () =>
  queryOptions({
    queryKey: [COMMUNICATION_PREFERENCES_QUERY_KEY],
    queryFn: () => getProviderCommunicationPreferences(),
  });

export const FILE_TYPES_QUERY_KEY = "userCredentialsFileTypes";
export const fileTypesQueryOptions = () =>
  queryOptions({
    queryKey: [FILE_TYPES_QUERY_KEY],
    queryFn: () => getFileTypes(),
  });

export const REFERRALS_QUERY_KEY = "referrals";
export const referralsQueryOptions = () =>
  queryOptions({
    queryKey: [REFERRALS_QUERY_KEY],
    queryFn: () => getReferrals(),
    staleTime: 0,
  });

export const CREATE_REFERRAL_LINK_QUERY_KEY = "createReferralLink";
export const createReferralLinkQueryOptions = () =>
  queryOptions({
    queryKey: [CREATE_REFERRAL_LINK_QUERY_KEY],
    queryFn: () => createReferralLink(),
  });

export const SPECIALTIES_QUERY_KEY = "specialties";
export const getSpecialtiesQueryOptions = () =>
  queryOptions({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: [SPECIALTIES_QUERY_KEY],
    queryFn: () => getSpecialties(),
    retry: 2,
  });

export const SPECIALTY_CODES_QUERY_KEY = "specialty";
export const getSpecialtyCodesQueryOptions = (codes: string[]) =>
  queryOptions({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: [SPECIALTY_CODES_QUERY_KEY, codes],
    queryFn: () => getSpecialtyCodes(codes),
    retry: 2,
  });

export const HEALTHCARE_ROLES_QUERY_KEY = "healthcareRoles";
export const getHealthcareRolesQueryOptions = () =>
  queryOptions({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: [HEALTHCARE_ROLES_QUERY_KEY],
    queryFn: getHealthcareRoles,
    retry: 2,
  });

export const HEALTHCARE_ROLE_KEY = "healthcareRole";
export const getHealthcareRoleQueryOptions = (id: string) =>
  queryOptions({
    staleTime: 1000 * 60 * 5, // 5 minutes
    queryKey: [HEALTHCARE_ROLE_KEY, id],
    queryFn: () => (id ? getHealthcareRole(id) : null),
    retry: 2,
  });

export const CREDENTIALS_FIELDS_QUERY_KEY = "credentialsFields";
export const getCredentialsFieldsQueryOptions = <T extends string>(
  fieldCodes: T[],
  switchUserId?: string,
) =>
  queryOptions({
    queryKey: [CREDENTIALS_FIELDS_QUERY_KEY, fieldCodes],
    queryFn: () => getCredentialsFields({ fieldCodes, switchUserId }),
    retry: 2,
    staleTime: 1000,
  });

export const SHARED_USER_QUERY_KEY = "sharedUser";
export const getSharedUserQueryOptions = (userId: string) =>
  queryOptions({
    queryKey: [SHARED_USER_QUERY_KEY, userId],
    retry: 2,
    queryFn: () => getSharedUser(userId),
  });

export const USER_PROFILE_SHARES_QUERY_KEY = "userProfileShares";
export const getUserProfileSharesQueryOptions = (userId: string) =>
  queryOptions({
    queryKey: [USER_PROFILE_SHARES_QUERY_KEY, userId],
    retry: 2,
    queryFn: () => getUserProfileShares(userId),
  });

export const PROFILE_IMAGE_QUERY_KEY = "profileImage";
export const getProfileImageQueryOptions = (
  profileImagePath: string | null | undefined,
) =>
  queryOptions({
    queryKey: [PROFILE_IMAGE_QUERY_KEY, profileImagePath],
    retry: 2,
    queryFn: () => getProfileImage(profileImagePath),
    staleTime: 1000 * 60 * 10, // 10 minutes
  });

export const WORK_SHIFT_PREFERENCES_QUERY_KEY = "workShiftPreferences";
export const getWorkShiftPreferencesQueryOptions = (userId?: string) =>
  queryOptions({
    queryKey: [WORK_SHIFT_PREFERENCES_QUERY_KEY, userId],
    queryFn: () => getWorkShiftPreferences({ userId }),
    retry: 2,
  });

export const CHAT_UNREAD_MESSAGES_COUNT_QUERY_KEY = "chatUnreadMessagesCount";
export const getChatUnreadMessagesCountQueryOptions = () =>
  queryOptions({
    queryKey: [CHAT_UNREAD_MESSAGES_COUNT_QUERY_KEY],
    queryFn: () => getChatUnreadMessagesCount(),
    retry: 2,
    staleTime: 1000 * 60 * 10,
    refetchInterval: 1000 * 60 * 10, // 10 minutes
  });
