import React, { useState } from "react";
import {
  Download,
  FileText,
  ChevronRight,
  Eye,
  ChevronDown,
  CircleX,
  InfoIcon,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  filesCheckPermissionQueryOptions,
  filesQueryOptions,
} from "@/ajax/queries";
import ErrorBoundary from "@/components/ErrorBoundary";
import FilePreviewModal from "@/components/FilePreview/FilePreviewModal";
import { generateDocumentName } from "@/features/documents/utils/generateDocumentName";
import { downloadFile } from "@/ajax/documents/downloadFile";
import { FileType } from "@/ajax/documents/getFiles";
import { downloadAllFilesZip } from "@/ajax/documents/downloadAllFilesZip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

const DocumentRow = ({
  fileName,
  documentType,
  onView,
  onDownload,
  isDownloading,
}: {
  fileName: string;
  documentType: string;
  onView: () => void;
  onDownload: () => void;
  isDownloading: boolean;
}) => (
  <div className="py-1">
    <div className="flex items-center justify-between">
      <button
        className="flex items-center gap-3 text-left"
        onClick={onView}
        aria-label={`View document: ${fileName}`}
      >
        <div className="text-blue-600">
          <FileText size={24} />
        </div>
        <div>
          <h3 className="text-gray-900 font-medium">{fileName}</h3>
          <p className="text-gray-500 text-sm">{documentType}</p>
        </div>
      </button>
      <div className="flex gap-2">
        <Button
          variant="outline"
          size="icon"
          onClick={onView}
          aria-label={`View document: ${fileName}`}
        >
          <Eye className="w-4 h-4" />
        </Button>
        <Button
          variant="outline"
          size="icon"
          onClick={onDownload}
          disabled={isDownloading}
          aria-label={`Download document: ${fileName}`}
        >
          {isDownloading ? (
            <div className="w-4 h-4 border-2 border-t-transparent border-blue-600 rounded-full animate-spin" />
          ) : (
            <Download className="w-4 h-4" />
          )}
        </Button>
      </div>
    </div>
  </div>
);

const DocumentsValid = ({
  userId,
  userName,
  editable,
}: {
  userId: string;
  userName: string;
  editable: boolean;
}) => {
  const [showAll, setShowAll] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<any>(null);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadingFileId, setDownloadingFileId] = useState<string | null>(
    null,
  );
  const {
    data: { data: documents, hasPermission },
  } = useSuspenseQuery(
    filesCheckPermissionQueryOptions({
      switchUserId: userId,
    }),
  );

  const handleDownload = async (file: FileType) => {
    setDownloadingFileId(file.id);
    try {
      const fileName = generateDocumentName(file);
      await downloadFile({
        userId,
        fileId: file.id,
        fileName,
      });
    } finally {
      setDownloadingFileId(null);
    }
  };

  const handleDownloadAll = async () => {
    setDownloadLoading(true);
    try {
      await downloadAllFilesZip({
        userId,
        userName,
        files: allDocuments,
      });
    } finally {
      setDownloadLoading(false);
    }
  };

  const allDocuments = documents || [];
  const displayedDocuments = showAll ? allDocuments : allDocuments.slice(0, 1);
  const remainingCount = allDocuments.length - 1;

  return (
    <div className="mt-6 bg-white">
      <div className="p-4">
        {/* Header Section */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-900">Documents</h2>
          {allDocuments.length > 0 && (
            <div className="flex items-center gap-2">
              {showAll && (
                <Button
                  variant="ghost"
                  className="text-gray-600 font-medium"
                  onClick={() => setShowAll(false)}
                >
                  <ChevronDown className="w-4 h-4 mr-1" />
                  Collapse
                </Button>
              )}
              <Button
                variant="ghost"
                className="text-blue-600 font-medium"
                onClick={handleDownloadAll}
                disabled={downloadLoading}
              >
                <Download className="w-4 h-4 mr-1" />
                {downloadLoading ? "Zipping files..." : "Download All"}
              </Button>
            </div>
          )}
        </div>
        {!hasPermission && (
          <div className="py-2 text-center">
            <div className="flex items-center justify-center gap-2 text-gray-700">
              <span>The provider did not share their documents</span>
              <Popover>
                <PopoverTrigger>
                  <InfoIcon className="h-4 w-4 text-gray-700 cursor-help" />
                </PopoverTrigger>
                <PopoverContent className="w-80 p-2">
                  <p className="text-sm text-gray-600">
                    Providers have the option to control who can view their
                    documents. If you believe this is a mistake, please reach
                    out to the provider to have them reshare access.
                  </p>
                </PopoverContent>
              </Popover>
            </div>
          </div>
        )}
        {hasPermission && allDocuments.length > 0 && (
          <div>
            {displayedDocuments.map((doc, index) => (
              <DocumentRow
                key={index}
                fileName={doc.generated_title || doc.file_name}
                documentType={doc.type_name}
                onView={() => setSelectedDocument(doc)}
                onDownload={() => handleDownload(doc)}
                isDownloading={downloadingFileId === doc.id}
              />
            ))}
            {!showAll && allDocuments.length > 1 && (
              <Button
                variant="outline"
                className="w-full text-gray-700"
                onClick={() => setShowAll(!showAll)}
              >
                <div className="flex items-center justify-center gap-2">
                  {remainingCount} more document
                  {remainingCount !== 1 ? "s" : ""} - View All
                  <ChevronRight className="w-4 h-4" />
                </div>
              </Button>
            )}
            {selectedDocument && (
              <FilePreviewModal
                userId={userId}
                file={selectedDocument}
                onClose={() => setSelectedDocument(null)}
                editable={editable}
              />
            )}
          </div>
        )}
        {hasPermission && allDocuments.length === 0 && (
          <div className="py-2 text-center">
            <div className="flex items-center justify-center gap-2 text-gray-700">
              <FileText className="h-5 w-5" />
              <span>No credentialling documents have been uploaded yet</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ErrorComponent = ({ error }: { error: Error & { code?: string } }) => {
  return (
    <div className="mt-6 bg-white">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-900">Documents</h2>
        </div>
        <div className="py-2 text-center">
          <div className="flex items-center justify-center gap-2 text-gray-700">
            <CircleX className="h-5 w-5" />
            <span>
              {error?.code === "A0403"
                ? "The provider did not share documents"
                : "Failed to load documents"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DocumentsCard = ({
  userId,
  userName,
  editable,
}: {
  userId: string;
  userName: string;
  editable: boolean;
}) => {
  return (
    <ErrorBoundary component={ErrorComponent}>
      <DocumentsValid userId={userId} userName={userName} editable={editable} />
    </ErrorBoundary>
  );
};

export default DocumentsCard;
