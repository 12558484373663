import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type Organization = {
  id: string;
  organization_id: string;
  name: string;
  organization_name: string;
  created_at: string;
};

export const getBusinessUnits = async ({ orgId }: { orgId?: string } = {}) => {
  return callSupabaseFnFromClient<Organization[]>({
    fnName: "admin_get_business_units",
    args: { org_id: orgId },
  });
};
