import { createFileRoute } from "@tanstack/react-router";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Link } from "@tanstack/react-router";
import { format } from "date-fns";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getUserProfileSharesQueryOptions } from "@/ajax/queries";
import { useState, useMemo } from "react";
import { SpecialtiesSelect } from "@/components/Selects/SpecialtiesSelect";
import { CitySelect } from "@/components/Selects/CitySelect";
import { Filter } from "lucide-react";

export const Route = createFileRoute("/_org/org/provider-profiles/")({
  component: () => {
    const { user } = Route.useRouteContext();
    const { data: providers } = useSuspenseQuery(
      getUserProfileSharesQueryOptions(user.id),
    );

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedSpecialty, setSelectedSpecialty] = useState("");
    const [selectedCity, setSelectedCity] = useState<{ name: string }[]>([]);
    const [showFilters, setShowFilters] = useState(false);

    const activeFilterCount =
      (selectedSpecialty ? 1 : 0) + (selectedCity.length > 0 ? 1 : 0);

    const filteredProviders = useMemo(() => {
      return providers.filter((provider) => {
        const matchesSearch =
          searchTerm === "" ||
          `${provider.name_value?.first_name} ${provider.name_value?.last_name}`
            .toLowerCase()
            .includes(searchTerm.toLowerCase());

        const matchesSpecialty =
          !selectedSpecialty ||
          provider.specialty_value?.specialty_code === selectedSpecialty;

        const matchesCity =
          selectedCity.length === 0 ||
          selectedCity.some(
            (city) =>
              city.name.toLowerCase() ===
              `${provider.address_value?.city}, ${provider.address_value?.state}`.toLowerCase(),
          );

        return matchesSearch && matchesSpecialty && matchesCity;
      });
    }, [providers, searchTerm, selectedSpecialty, selectedCity]);

    return (
      <div className="container mx-auto py-8 px-2">
        <div className="max-w-5xl mx-auto">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
            <h1 className="text-2xl font-semibold">Profiles Shared/Saved</h1>

            <div className="w-full sm:w-auto flex flex-col sm:flex-row gap-2">
              <div className="flex gap-2 w-full sm:w-auto">
                <Input
                  placeholder="Search by name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full sm:w-[200px]"
                />
                <Button
                  variant="outline"
                  onClick={() => setShowFilters(!showFilters)}
                  className="flex items-center gap-2 whitespace-nowrap py-7"
                >
                  <Filter size={16} />
                  Filters
                  {activeFilterCount > 0 && (
                    <span className="ml-1 bg-blue-100 text-blue-800 text-xs font-medium px-2 py-0.5 rounded-full">
                      {activeFilterCount}
                    </span>
                  )}
                </Button>
              </div>
            </div>
          </div>

          {showFilters && (
            <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
              <div>
                <label className="block text-sm font-medium mb-2">
                  Specialty
                </label>
                <SpecialtiesSelect
                  value={selectedSpecialty}
                  onChange={setSelectedSpecialty}
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-2">
                  Location
                </label>
                <CitySelect value={selectedCity} onChange={setSelectedCity} />
              </div>
            </div>
          )}

          <Card className="p-0">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Specialty</TableHead>
                  <TableHead>Location</TableHead>
                  <TableHead>Access Level</TableHead>
                  <TableHead>Shared Date</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredProviders.map((provider) => (
                  <TableRow key={provider.id}>
                    <TableCell>
                      <Link
                        to="/org/provider-profile/$userId"
                        params={{ userId: provider.user_id }}
                        className="text-blue-600 hover:underline"
                      >
                        {provider.name_value?.first_name}{" "}
                        {provider.name_value?.last_name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {provider.specialty_value?.specialty_name}
                    </TableCell>
                    <TableCell>
                      {provider.address_value?.city},{" "}
                      {provider.address_value?.state}
                    </TableCell>
                    <TableCell>
                      {provider.include_contact_info
                        ? provider.include_credential_files
                          ? "Full"
                          : "Full no files"
                        : "Basic"}
                    </TableCell>
                    <TableCell>
                      {format(new Date(provider.created_at), "MMM dd, yyyy")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
      </div>
    );
  },
});
