import { useNavigate } from "@tanstack/react-router";
import { SearchX } from "lucide-react";

export const NotFoundComponent = ({ homePath }: { homePath?: string }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-8 text-center">
      <div className="rounded-full bg-blue-100 p-4 mb-6">
        <SearchX className="w-12 h-12 text-blue-500" />
      </div>
      <h1 className="text-3xl font-bold mb-3">404 - Page Not Found</h1>
      <p className="text-gray-600 mb-6 max-w-md">
        The page you are looking for might have been removed, had its name
        changed, or is temporarily unavailable.
      </p>
      {homePath && (
        <button
          onClick={() => navigate({ to: homePath })}
          className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
        >
          Return to Home
        </button>
      )}
    </div>
  );
};
