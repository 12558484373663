import { createFileRoute, Link } from "@tanstack/react-router";
import { Button, buttonVariants } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import Select from "react-select";
import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getBusinessUnitUsers } from "@/ajax/organizations/getBusinessUnitUsers";
import { Card } from "@/components/ui/card";
import { getUsers } from "@/ajax/users/getUsers";
import { createBusinessUnitUser } from "@/ajax/organizations/createBusinessUnitUser";
import { deleteBusinessUnitUser } from "@/ajax/organizations/deleteBusinessUnitUser";
import { getBusinessUnit } from "@/ajax/organizations/getBusinessUnit";
const BusinessUnitUsersPage = () => {
  const { businessUnitId, orgId } = Route.useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [userToRemove, setUserToRemove] = useState<any>(null);
  const queryClient = useQueryClient();

  const { data: users = [] } = useQuery({
    queryKey: ["businessUnitUsers", businessUnitId],
    queryFn: () => getBusinessUnitUsers({ businessUnitId }),
  });

  const { data: businessUnit } = useQuery({
    queryKey: ["businessUnit", businessUnitId],
    queryFn: () => getBusinessUnit({ businessUnitId }),
  });

  const { data: availableUsers = [] } = useQuery({
    queryKey: ["availableUsers"],
    queryFn: () => getUsers({ search: "" }),
  });

  const addUserMutation = useMutation({
    mutationFn: () =>
      createBusinessUnitUser({ businessUnitId, userId: selectedUserId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["businessUnitUsers", businessUnitId],
      });
      setIsDialogOpen(false);
      setSelectedUserId("");
    },
  });

  const removeUserMutation = useMutation({
    mutationFn: () =>
      deleteBusinessUnitUser({ businessUnitUserId: userToRemove.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["businessUnitUsers", businessUnitId],
      });
      setIsRemoveDialogOpen(false);
      setUserToRemove(null);
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addUserMutation.mutate();
  };

  const handleRemoveClick = (user: any) => {
    setUserToRemove(user);
    setIsRemoveDialogOpen(true);
  };

  const handleConfirmRemove = () => {
    removeUserMutation.mutate();
  };

  return (
    <div className="container mx-auto py-8 px-2">
      <div className="max-w-5xl mx-auto">
        <div className="mb-6">
          <Link
            to="/admin/organizations/$orgId/business-units"
            params={{ orgId }}
            className={buttonVariants({ variant: "outline" }) + " mb-4"}
          >
            ← Back to Organization
          </Link>
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-semibold">{businessUnit?.name}</h1>
              <h2 className="text-lg text-gray-600 mt-1">
                Business Unit Users
              </h2>
            </div>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button>Add User</Button>
              </DialogTrigger>
              <DialogContent className="sm:min-w-[500px] p-4 sm:p-6">
                <DialogHeader>
                  <DialogTitle>Add User to Business Unit</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <Select
                      value={availableUsers.find(
                        (user) => user.id === selectedUserId,
                      )}
                      onChange={(option) => setSelectedUserId(option?.id || "")}
                      options={availableUsers}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name} (${option.email})`
                      }
                      getOptionValue={(option) => option.id}
                      placeholder="Select a user..."
                      isClearable
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row gap-2 sm:justify-end">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => setIsDialogOpen(false)}
                      className="w-full sm:w-auto"
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={addUserMutation.isPending || !selectedUserId}
                      className="w-full sm:w-auto"
                    >
                      {addUserMutation.isPending ? "Adding..." : "Add User"}
                    </Button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        {!users || users.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-12 px-4 text-gray-500 bg-gray-50 rounded-lg">
            <svg
              className="w-12 h-12 mb-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            <h3 className="text-lg font-semibold mb-2">No Users Yet</h3>
            <p className="text-sm text-center mb-4">
              Get started by adding users to this business unit
            </p>
            <Button
              onClick={() => setIsDialogOpen(true)}
              variant="outline"
              size="sm"
            >
              Add User
            </Button>
          </div>
        ) : (
          <Card className="p-0">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Created At</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>
                      {user.first_name} {user.last_name}
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      {new Date(user.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="destructive"
                        size="sm"
                        onClick={() => handleRemoveClick(user)}
                      >
                        Remove
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        )}

        <Dialog open={isRemoveDialogOpen} onOpenChange={setIsRemoveDialogOpen}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Remove User</DialogTitle>
            </DialogHeader>
            <div className="space-y-4">
              <p>
                Are you sure you want to remove {userToRemove?.first_name}{" "}
                {userToRemove?.last_name} from this business unit?
              </p>
              <div className="flex justify-end gap-4">
                <Button
                  variant="outline"
                  onClick={() => setIsRemoveDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={handleConfirmRemove}
                  disabled={removeUserMutation.isPending}
                >
                  {removeUserMutation.isPending ? "Removing..." : "Remove"}
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export const Route = createFileRoute(
  "/_admin/admin/organizations/$orgId/business-units/$businessUnitId/users/",
)({
  component: BusinessUnitUsersPage,
});
