import React, { useState } from "react";
import { X, Check } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";

interface RejectionReason {
  id: string;
  icon: string;
  label: string;
}

interface SubmitRejectionResponse {
  success: boolean;
  reasons: string[];
}

// Mock API function
const submitRejectionReasons = async (
  reasons: string[],
): Promise<SubmitRejectionResponse> => {
  // Simulate API call
  await new Promise((resolve) => setTimeout(resolve, 1000));
  if (Math.random() > 0.9) {
    throw new Error("Random error occurred");
  }
  return { success: true, reasons };
};

const RejectMatchDialog: React.FC = () => {
  const [open, setOpen] = useState<boolean>(true);
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const reasons: RejectionReason[] = [
    { id: "pay", icon: "$", label: "Pay too low" },
    { id: "distance", icon: "📍", label: "Too far" },
    { id: "schedule", icon: "🕒", label: "Doesn't match schedule" },
    { id: "facility", icon: "🏥", label: "Don't like facility" },
    {
      id: "responsibilities",
      icon: "📋",
      label: "Don't like responsibilities",
    },
    { id: "qualifications", icon: "🎓", label: "Don't qualify" },
  ];

  const mutation = useMutation<SubmitRejectionResponse, Error, string[]>({
    mutationFn: submitRejectionReasons,
    onSuccess: () => {
      console.log("Successfully submitted reasons:", selectedReasons);
      // Close dialog immediately
      setOpen(false);
      // Show toast instead of success alert in dialog
      toast("Feedback Submitted", {
        description: "Thank you for your feedback about this position.",
        duration: 3000,
        icon: <Check className="h-5 w-5 text-green-500" />,
      });
    },
    onError: (error: Error) => {
      console.error("Error submitting reasons:", error);
      toast("Error", {
        description:
          "There was a problem submitting your feedback. Please try again.",
        duration: 5000,
      });
    },
  });

  const handleToggleReason = (reasonId: string): void => {
    setSelectedReasons((prev) =>
      prev.includes(reasonId)
        ? prev.filter((id) => id !== reasonId)
        : [...prev, reasonId],
    );
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    mutation.mutate(selectedReasons);
  };

  const handleSkip = (): void => {
    console.log("Skipped providing reasons");
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:min-w-[500px] sm:max-w-full">
        <DialogHeader>
          <div className="mx-auto bg-red-100 p-4 rounded-full mb-4 w-16 h-16 flex items-center justify-center">
            <X className="h-6 w-6 text-red-500" />
          </div>
          <DialogTitle className="text-center text-2xl">
            Why isn't this a match?
          </DialogTitle>
          <DialogDescription className="text-center">
            Help us understand so we can find you a better match.
          </DialogDescription>
        </DialogHeader>

        <form onSubmit={handleSubmit}>
          <div className="space-y-3 my-6">
            {reasons.map((reason) => (
              <div
                key={reason.id}
                className="border rounded-lg p-3 flex items-center"
              >
                <Checkbox
                  id={reason.id}
                  checked={selectedReasons.includes(reason.id)}
                  onCheckedChange={() => handleToggleReason(reason.id)}
                  className="mr-3"
                />
                <span className="text-gray-400 mr-2">{reason.icon}</span>
                <label
                  htmlFor={reason.id}
                  className="text-gray-700 text-lg cursor-pointer flex-1"
                >
                  {reason.label}
                </label>
              </div>
            ))}
          </div>

          {mutation.isError && (
            <Alert variant="destructive" className="mb-4">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                There was a problem submitting your feedback. Please try again.
              </AlertDescription>
            </Alert>
          )}

          <DialogFooter className="flex lg:flex-col justify-between sm:justify-between gap-2">
            <Button
              type="submit"
              disabled={mutation.isPending || selectedReasons.length === 0}
              className={selectedReasons.length === 0 ? "opacity-70" : ""}
            >
              {mutation.isPending ? "Submitting..." : "Submit"}
            </Button>
            <Button type="button" variant="ghost" onClick={handleSkip}>
              Skip
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RejectMatchDialog;
