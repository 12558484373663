import { supabase } from "@/ajax/clients/supabase";
import { createReferral } from "@/ajax/referrals/createReferral";

const REFERRAL_STORAGE_KEY = "referral_data";
const REFERRAL_EXPIRY_DAYS = 14;

export const getReferralFromStorage = (): string | null => {
  const stored = localStorage.getItem(REFERRAL_STORAGE_KEY);
  if (!stored) return null;

  const { code, expiry } = JSON.parse(stored);
  if (new Date().getTime() > expiry) {
    localStorage.removeItem(REFERRAL_STORAGE_KEY);
    return null;
  }

  return code;
};

export const setReferralInStorage = (code: string) => {
  const expiry =
    new Date().getTime() + REFERRAL_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
  localStorage.setItem(
    REFERRAL_STORAGE_KEY,
    JSON.stringify({
      code,
      expiry,
    }),
  );
};

export const clearReferralFromStorage = () => {
  localStorage.removeItem(REFERRAL_STORAGE_KEY);
};

export const sendReferralComplete = async () => {
  const referralCode = getReferralFromStorage();
  if (!referralCode) return;
  await createReferral({
    referralCode,
  });
  clearReferralFromStorage();
};

supabase.auth.onAuthStateChange((event, session) => {
  if (event === "SIGNED_IN" && session) {
    sendReferralComplete();
  }
});
