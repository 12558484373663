import { getSharedUserQueryOptions } from "@/ajax/queries";
import Loading from "@/components/Loading";
import { useQuery } from "@tanstack/react-query";
import { ProviderProfilePage } from "./ProviderProfilePage";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";

export const ProfileModal = ({
    userId,
    onClose,
}: {
    userId: string;
    onClose: () => void;
}) => {
    const { data: providerUser, isLoading } = useQuery(
        getSharedUserQueryOptions(userId),
    );
    return (
        <Dialog open={true} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[90vw] max-h-[90vh] overflow-y-auto p-0 md:p-6">
                <DialogHeader className="p-4 md:p-0">
                    <DialogTitle>&nbsp;</DialogTitle>
                </DialogHeader>
                <div className="overflow-y-auto">
                    {isLoading ? (
                        <div className="flex items-center justify-center p-12">
                            <Loading className="h-20 w-20" />
                        </div>
                    ) : (
                        providerUser ? (
                            <ProviderProfilePage editable={false} user={providerUser} />
                        ) : (
                            <div className="flex items-center justify-center p-12">
                                <div className="text-center">
                                    <h3 className="text-lg font-medium text-gray-900">Error</h3>
                                    <p className="mt-2 text-sm text-gray-500">
                                        The provider profile couldn't be loaded.
                                    </p>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};
