import { Fragment } from "react";

export const ChatMessage = ({ message }: { message: string }) => {
  const lines = message.split("\n");
  return (
    <div>
      {lines.map((line: string, i: number) => (
        <Fragment key={i}>
          {line}
          {i < lines.length - 1 && <br />}
        </Fragment>
      ))}
    </div>
  );
};
