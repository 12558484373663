import { getAccessToken, supabase } from "@/ajax/clients/supabase";
import { sleep } from "@/common/utils/sleep";
import { API_HOST } from "@/consts/api_host";
import axios, { AxiosError, AxiosRequestConfig, Method } from "axios";

interface RetryOptions {
  maxRetries?: number;
  retryDelay?: number;
}

interface BaseApiRequestParams {
  path: string;
  headers?: Record<string, string>;
  retryOptions?: RetryOptions;
}

interface ApiRequestGetParams extends BaseApiRequestParams {
  params?: Record<string, any>;
}

interface ApiRequestPostParams extends BaseApiRequestParams {
  body: any;
}

interface ApiRequestDeleteParams extends BaseApiRequestParams {
  body: any;
}

const DEFAULT_RETRY_OPTIONS = {
  maxRetries: 3,
  retryDelay: 1000, // 1 second
};

const makeRequestWithRetry = async <T>(
  method: Method,
  {
    path,
    headers = {},
    retryOptions = {},
    ...rest
  }: ApiRequestGetParams | ApiRequestPostParams,
) => {
  const { maxRetries, retryDelay } = {
    ...DEFAULT_RETRY_OPTIONS,
    ...retryOptions,
  };
  let retries = 0;

  while (true) {
    try {
      const accessToken = await getAccessToken();
      const config: AxiosRequestConfig = {
        method,
        url: `${API_HOST}${path}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ...headers,
        },
      };

      if ("params" in rest) {
        config.params = rest.params;
      } else if ("body" in rest) {
        config.data = rest.body;
      }

      const response = await axios(config);
      return response.data;
    } catch (error) {
      if (retries >= maxRetries) {
        throw error;
      }

      if (
        error instanceof AxiosError &&
        (!error.response || error.response.status >= 500)
      ) {
        retries++;
        await sleep(retryDelay * retries); // Exponential backoff
        continue;
      }

      throw error;
    }
  }
};

export const apiRequestGetWithRetry = (params: ApiRequestGetParams) =>
  makeRequestWithRetry("get", params);

export const apiRequestPostWithRetry = (params: ApiRequestPostParams) =>
  makeRequestWithRetry("post", params);

export const apiRequestDeleteWithRetry = (params: ApiRequestDeleteParams) =>
  makeRequestWithRetry("delete", params);
