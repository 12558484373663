import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type BusinessUnit = {
  id: string;
  name: string;
  organization_id: string;
  created_at: string;
};

export const getBusinessUnit = async ({
  businessUnitId,
}: {
  businessUnitId: string;
}) => {
  return callSupabaseFnFromClient<BusinessUnit>({
    fnName: "admin_get_business_unit",
    args: { business_unit_id: businessUnitId },
  });
};
