import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { X, AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useMutation } from "@tanstack/react-query";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import { SaveButton } from "@/components/Button/SaveButton";
import { Textarea } from "@/components/ui/textarea";
import { PhoneInput } from "@/components/Inputs/PhoneInput";

const referenceSchema = z.object({
  first_name: z
    .string()
    .max(255, "First name must be less than 255 characters"),
  last_name: z.string().max(255, "Last name must be less than 255 characters"),
  relationship_description: z
    .string()
    .max(1000, "Description must be less than 1000 characters"),
  email: z
    .string()
    .email("Invalid email address")
    .max(255, "Email must be less than 255 characters"),
  phone_number: z
    .string({
      required_error: "Phone number is required",
    })
    .max(12, "Phone number must be less than 20 characters"),
});

const formSchema = z.object({
  references: z.array(referenceSchema),
});

type FormValues = z.infer<typeof formSchema>;

export function ReferencesForm({
  onSave,
  references,
}: {
  onSave: () => void;
  references: any[];
}) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      references:
        references
          .filter((val) => val.value)
          .map((val: { value: any }) => ({
            first_name: val.value?.first_name || "",
            last_name: val.value?.last_name || "",
            relationship_description: val.value?.relationship_description || "",
            email: val.value?.email || "",
            phone_number: val.value?.phone_number || "",
          })) || [],
    },
  });

  const {
    fields: referenceFields,
    append: appendReference,
    remove: removeReference,
  } = useFieldArray({
    control: form.control,
    name: "references",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const existingValues = references || [];
    const newIndexMax = data.references.length;

    const fieldValues = [
      ...data.references.map((reference, index) => ({
        field_code: "professional_references",
        value: {
          first_name: reference.first_name,
          last_name: reference.last_name,
          relationship_description: reference.relationship_description,
          email: reference.email,
          phone_number: reference.phone_number,
        },
        index: index + 1,
        delete: false,
      })),

      ...existingValues
        .filter((val: { index: number }) => val.index > newIndexMax)
        .map((val: { index: number }) => ({
          field_code: "professional_references",
          value: null,
          delete: true,
          index: val.index,
        })),
    ];

    mutation.mutate({
      fieldValues,
    });
  }

  const renderReferenceFields = (fields: any[], remove: any) => {
    return fields.map((field, index) => (
      <div key={field.id} className="space-y-4 p-4 border rounded-lg relative">
        <Button
          type="button"
          variant="outline"
          size="sm"
          className="absolute right-2 top-2"
          onClick={() => {
            remove(index as number);
          }}
        >
          Remove
        </Button>

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name={`references.${index}.first_name`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={`references.${index}.last_name`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name={`references.${index}.relationship_description`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Relationship Description</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`references.${index}.email`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`references.${index}.phone_number`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Phone Number</FormLabel>
              <FormControl>
                <PhoneInput {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    ));
  };

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          <div>
            <div className="space-y-4">
              {renderReferenceFields(referenceFields, removeReference)}
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() =>
                  appendReference({
                    first_name: "",
                    last_name: "",
                    relationship_description: "",
                    email: "",
                    phone_number: "",
                  })
                }
              >
                Add Reference
              </Button>
            </div>
          </div>
        </div>

        <SaveButton saveStatus={saveStatus} />
        <Button
          className="py-6 w-full"
          variant="outline"
          onClick={() => onSave()}
        >
          Cancel
        </Button>
      </form>
    </Form>
  );
}
