import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export const adminSearchProviders = async (params: {
  lat?: number;
  lng?: number;
  specialtyCode: string;
  distanceMiles?: number;
  contractDurationId?: number;
}) => {
  return callSupabaseFnFromClient<
    {
      user_id: string;
      contract_duration: string;
      specialty_name: string;
      specialty_code: string;
      first_name: string;
      last_name: string;
      locations: {
        name: string;
      }[];
      organizations: string[];
      facility_type_names: string[];
      availability_description: string;
      minimum_rate: number;
    }[]
  >({
    fnName: "admin_search_providers",
    args: {
      search_lat: params.lat,
      search_lng: params.lng,
      search_specialty_code: params.specialtyCode,
      search_distance_miles: params.distanceMiles,
      search_contract_duration_id: params.contractDurationId,
    },
  });
};
