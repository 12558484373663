import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type BusinessUnit = {
  id: string;
  organization_id: string;
  name: string;
  created_at: string;
};

export const updateBusinessUnit = async ({
  businessUnitId,
  name,
}: {
  businessUnitId: string;
  name: string;
}) => {
  return callSupabaseFnFromClient<BusinessUnit>({
    fnName: "admin_update_business_unit",
    args: {
      business_unit_id: businessUnitId,
      business_unit_name: name,
    },
  });
};
