import React from "react";
import {
  MapPin,
  Building2,
  Clock,
  DollarSign,
  Calendar,
  Info,
} from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ShiftContractDuration } from "@/common/enums/ShiftContractDuration";
import { shiftContractDurationLabels } from "@/common/enums/ShiftContractDuration";
interface JobPreferencesCardProps {
  preferredLocations?: string[];
  facilityTypes?: string[];
  contractType?: ShiftContractDuration;
  minimumRate?: number | null;
  availability?: string | null;
  noPreferences?: boolean;
}

export const JobPreferencesCard = ({
  preferredLocations = [],
  facilityTypes = [],
  contractType,
  minimumRate,
  availability,
  noPreferences = false,
}: JobPreferencesCardProps) => {
  if (noPreferences) {
    return (
      <Card className="w-full bg-white">
        <CardHeader className="pb-3">
          <CardTitle className="text-lg font-semibold text-gray-800">
            Job Preferences
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center py-6 text-center">
            <Info className="w-10 h-10 text-gray-400 mb-3" />
            <p className="text-gray-600 mb-1">
              The provider has not set preferences
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const hasAnyCity = preferredLocations.includes("ANY_CITY");
  const displayLocations = preferredLocations.filter(
    (loc) => loc !== "ANY_CITY",
  );
  const onlyAnyCity = hasAnyCity && displayLocations.length === 0;

  return (
    <Card className="w-full bg-white">
      <CardHeader className="pb-3">
        <CardTitle className="text-lg font-semibold text-gray-800">
          Job Preferences
        </CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-2 xl:grid-cols-1 gap-4">
        <div>
          <div className="flex items-center gap-2 mb-1">
            <MapPin className="w-4 h-4 text-gray-400" />
            <h3 className="text-gray-700 font-medium">Locations</h3>
          </div>
          <div className="pl-6 text-gray-600 flex flex-col gap-1">
            {onlyAnyCity || preferredLocations.length === 0 ? (
              <div>Any location</div>
            ) : (
              <>
                <div className="flex flex-wrap gap-1">
                  {displayLocations.map((location, index) => (
                    <React.Fragment key={location}>
                      <span>{location}</span>
                      {index < displayLocations.length - 1 && <span>•</span>}
                    </React.Fragment>
                  ))}
                </div>
                {hasAnyCity && (
                  <div className="text-gray-500 text-sm italic">
                    Also open to other locations
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-1">
            <Building2 className="w-4 h-4 text-gray-400" />
            <h3 className="text-gray-700 font-medium">Facility Types</h3>
          </div>
          <div className="pl-6 text-gray-600 flex flex-wrap gap-1">
            {facilityTypes.length === 0 ? (
              <span>Any facility type</span>
            ) : (
              facilityTypes.map((facility, index) => (
                <React.Fragment key={facility}>
                  <span>{facility}</span>
                  {index < facilityTypes.length - 1 && <span>•</span>}
                </React.Fragment>
              ))
            )}
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-1">
            <Clock className="w-4 h-4 text-gray-400" />
            <h3 className="text-gray-700 font-medium">Contract Type</h3>
          </div>
          <div className="pl-6 text-gray-600 flex flex-wrap gap-1">
            {contractType
              ? shiftContractDurationLabels[contractType]
              : "Any contract type"}
          </div>
        </div>

        <div>
          <div className="flex items-center gap-2 mb-1">
            <DollarSign className="w-4 h-4 text-gray-400" />
            <h3 className="text-gray-700 font-medium">Minimum Rate</h3>
          </div>
          <div className="pl-6 text-gray-600">
            {minimumRate ? `$${minimumRate} / hour` : "No minimum"}
          </div>
        </div>

        <div className="col-span-2 xl:col-span-1">
          <div className="flex items-center gap-2 mb-1">
            <Calendar className="w-4 h-4 text-gray-400" />
            <h3 className="text-gray-700 font-medium">Availability</h3>
          </div>
          <div className="pl-6 text-gray-600">{availability || "Flexible"}</div>
        </div>
      </CardContent>
    </Card>
  );
};
