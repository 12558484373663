import { ChatThread } from "@/ajax/chat/getChatThreads";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useRouter, useSearch } from "@tanstack/react-router";
import { format } from "date-fns";
import { useMemo, useState } from "react";

export const AdminChatThreadList: React.FC<{
  threads: ChatThread[];
  onSelectThread: (thread: ChatThread) => void;
  selectedThread: ChatThread | null;
}> = ({ threads, onSelectThread, selectedThread }) => {
  const search = useSearch({
    strict: false,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const router = useRouter();

  const handleTabChange = (value: string) => {
    router.navigate({
      // @ts-ignore
      search: (prev) => ({
        ...prev,
        archived: value === "archived",
      }),
    });
  };

  const filteredThreads = useMemo(() => {
    return threads.filter((thread) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        thread.user_participants.some(
          (p) =>
            `${p.first_name} ${p.last_name}`
              .toLowerCase()
              .includes(searchLower) ||
            (p.specialty_name || "").toLowerCase().includes(searchLower),
        ) ||
        thread.organization_participants.some((p) =>
          p.name.toLowerCase().includes(searchLower),
        )
      );
    });
  }, [threads, searchQuery]);

  return (
    <div className="flex flex-col h-full bg-gray-50">
      {/* Tabs */}
      <div className="p-4 bg-white border-b">
        <Input
          className="mb-4"
          placeholder="Search threads..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Tabs
          defaultValue={search.archived ? "archived" : "active"}
          onValueChange={handleTabChange}
        >
          <TabsList className="grid grid-cols-2 bg-gray-100 p-1 rounded-xl">
            <TabsTrigger
              value="active"
              className="rounded-lg data-[state=active]:bg-white data-[state=active]:shadow-sm"
            >
              Active
            </TabsTrigger>
            <TabsTrigger
              value="archived"
              className="rounded-lg data-[state=active]:bg-white data-[state=active]:shadow-sm"
            >
              Archived
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {/* Threads */}
      <div className="flex-1 overflow-y-auto px-2 py-2 sm:px-4">
        <Card className="border-0 shadow-none h-full">
          <CardContent className="p-0">
            {filteredThreads.map((thread) => {
              const isSelected = selectedThread?.id === thread.id;
              return (
                <button
                  key={thread.id}
                  className={`w-full text-left p-2 sm:p-4 flex items-center space-x-2 sm:space-x-4 rounded-md transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 hover:bg-gray-100 ${
                    isSelected
                      ? "bg-white border-2 border-blue-800 shadow-md"
                      : "border-2 border-transparent"
                  }`}
                  onClick={() => onSelectThread(thread)}
                >
                  <div className="flex-1 min-w-0">
                    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
                      <div className="max-w-full">
                        <h3
                          className={`font-semibold truncate ${isSelected ? "text-blue-800" : "text-gray-900"}`}
                        >
                          {[
                            ...thread.organization_participants.map(
                              (p) => p.name,
                            ),
                            ...thread.user_participants.map(
                              (p) => `${p.first_name} ${p.last_name}`,
                            ),
                          ].join(", ")}
                        </h3>
                        {thread.user_participants.length > 0 &&
                          thread.user_participants[0].specialty_name && (
                            <p
                              className={`text-sm ${isSelected ? "text-blue-600" : "text-gray-500"} mt-0.5`}
                            >
                              {thread.user_participants[0].specialty_name}
                            </p>
                          )}
                      </div>
                      <span
                        className={`text-xs font-medium mt-1 sm:mt-0 ${isSelected ? "text-blue-700" : "text-gray-500"}`}
                      >
                        {thread.last_message_time
                          ? format(
                              new Date(thread.last_message_time),
                              "MMM d, h:mm a",
                            )
                          : ""}
                      </span>
                    </div>
                    <p
                      className={`text-sm truncate italic mt-0.5 ${isSelected ? "text-blue-700" : "text-gray-600"}`}
                    >
                      {thread?.last_message?.text
                        ? `${thread.last_message.text.slice(0, 20)}...`
                        : ""}
                    </p>
                  </div>
                  {thread.unread_count > 0 && (
                    <Badge
                      variant="default"
                      className={`flex-shrink-0 ${isSelected ? "bg-blue-800" : "bg-blue-600"} hover:bg-blue-700 transition-colors`}
                    >
                      {thread.unread_count}
                    </Badge>
                  )}
                </button>
              );
            })}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
