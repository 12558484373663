import { apiRequestPostWithRetry } from "../utils/apiRequest";

export const sendCredentialsReminder = async ({
  remindAt,
}: {
  remindAt: string;
}): Promise<void> => {
  return await apiRequestPostWithRetry({
    path: `/user-credentials-file/reminder`,
    body: {
      remindAt,
    },
  });
};
