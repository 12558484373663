import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type Organization = {
  id: string;
  name: string;
  created_at: string;
};

export const getOrganization = async ({ orgId }: { orgId: string }) => {
  return callSupabaseFnFromClient<Organization>({
    fnName: "admin_get_organization",
    args: { org_id: orgId },
  });
};
