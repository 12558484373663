import { format, parse } from "date-fns";
import {
  ExpireState,
  getExpireState,
} from "@/features/documents/utils/documentExpiry";
import { cn } from "@/lib/utils";

export const getExpirationTextColor = (expiresAt: string) => {
  const expiresAtDate = parse(expiresAt, "yyyy-MM-dd", new Date());
  const expireState = getExpireState(expiresAtDate);

  let textColor: "text-red-700" | "text-yellow-800" | "" = "";
  let fontWeight: "font-bold" | "" = "";
  if (expireState === ExpireState.EXPIRING) {
    textColor = "text-red-700";
    fontWeight = "font-bold";
  } else if (expireState === ExpireState.WARNING) {
    textColor = "text-yellow-800";
    fontWeight = "font-bold";
  }
  return { textColor, fontWeight };
};

export const ExpirationText = ({ expiresAt }: { expiresAt: string }) => {
  const { textColor, fontWeight } = getExpirationTextColor(expiresAt);
  const expiresAtDate = parse(expiresAt, "yyyy-MM-dd", new Date());

  return (
    <span className={cn("whitespace-nowrap", textColor, fontWeight)}>
      {+expiresAtDate < +new Date() ? "Expired: " : "Expires: "}
      {format(expiresAtDate, "MMM d, yyyy")}
    </span>
  );
};
