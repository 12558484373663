import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { AlertCircle, Loader2, Send } from "lucide-react";
import { useRef, useState } from "react";

export const ChatMessageInput = ({
  onSend,
  readOnly = false,
}: {
  onSend: (message: string) => Promise<void>;
  readOnly: boolean;
}) => {
  const [message, setMessage] = useState("");
  const [sendError, setSendError] = useState<string | null>(null);
  const [isSending, setIsSending] = useState(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleSend = async (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current?.focus();
    if (!message.trim() || readOnly || isSending) return;

    const newMessage = message;
    setSendError(null);
    setIsSending(true);

    try {
      await onSend(newMessage.trim());
      setMessage("");

      // Focus the input after sending
    } catch (error) {
      console.error("Failed to send message:", error);
      setSendError("Failed to send message. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  if (readOnly) return null;

  return (
    <div className="p-4 border-t bg-white">
      {sendError && (
        <div className="mb-2 text-sm text-red-600 flex items-center">
          <AlertCircle className="h-4 w-4 mr-1" />
          {sendError}
        </div>
      )}
      <div className="flex space-x-2">
        <Textarea
          ref={inputRef}
          className="flex-1 bg-gray-50 focus:bg-white text-lg py-3 px-3 resize-none"
          placeholder="Type a message..."
          value={message}
          rows={1}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              handleSend(e as unknown as React.KeyboardEvent<HTMLInputElement>);
            }
          }}
        />
        <Button
          size="icon"
          className="h-14 w-14 rounded-lg"
          onClick={handleSend}
          disabled={isSending}
        >
          {isSending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            <Send className="h-6 w-6" />
          )}
        </Button>
      </div>
    </div>
  );
};
