import { router } from "@/router";

type PUSH_NOTIFICATION_ACTION_TYPE = {
  CREDENTIAL_EXPIRATION_REMINDER: {
    id: string;
  };
  CHAT_NOTIFICATION: {
    id: string;
  };
};

const routerHandler = {
  CREDENTIAL_EXPIRATION_REMINDER: (
    data: PUSH_NOTIFICATION_ACTION_TYPE["CREDENTIAL_EXPIRATION_REMINDER"],
  ) => {
    router.navigate({
      to: `/provider/documents`,
      search: {
        fileId: data.id,
      },
    });
  },
  CHAT_NOTIFICATION: (
    data: PUSH_NOTIFICATION_ACTION_TYPE["CHAT_NOTIFICATION"],
  ) => {
    router.navigate({
      to: `/provider/chat`,
      search: {
        thread: data.id,
      },
    });
  },
};

export const handlePushNotificationActions = ({
  action,
  data,
}: {
  action: keyof PUSH_NOTIFICATION_ACTION_TYPE;
  data: PUSH_NOTIFICATION_ACTION_TYPE[keyof PUSH_NOTIFICATION_ACTION_TYPE];
}) => {
  const handler = routerHandler[action];
  if (handler) {
    handler(data);
  } else {
    console.error(`No handler found for action: ${action}`);
  }
};
