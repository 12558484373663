import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { updateOrgUser } from "@/ajax/users/updateOrgUser";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
});

function Settings() {
  const { user } = Route.useRouteContext();
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: user?.first_name || "",
      lastName: user?.last_name || "",
    },
  });

  const [buttonState, setButtonState] = useState<"idle" | "saving" | "success">(
    "idle",
  );

  const updateUserMutation = useMutation({
    mutationFn: updateOrgUser,
    onSuccess: () => {
      setButtonState("success");
      setTimeout(() => setButtonState("idle"), 2000);
      // Invalidate relevant queries
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {
      setButtonState("idle");
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setButtonState("saving");
    updateUserMutation.mutate({
      firstName: values.firstName,
      lastName: values.lastName,
    });
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Settings</h1>

      <div className="max-w-md">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              disabled={buttonState !== "idle"}
              className={buttonState === "success" ? "bg-green-600" : ""}
            >
              {buttonState === "saving" && "Saving..."}
              {buttonState === "success" && "Saved!"}
              {buttonState === "idle" && "Save Changes"}
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_org/org/settings/")({
  component: Settings,
});
