import { supabase } from "../clients/supabase";

export const snoozeFileNotifications = async ({ id }: { id: string }) => {
  const { data, error } = await supabase.rpc(
    "snooze_user_credentials_file_notifications",
    {
      file_id: id,
    },
  );
  return { data, error };
};
