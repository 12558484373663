import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export type ChatThreadId = {
  id: string;
};

export const adminDeleteChatThread = async ({
  threadId,
}: {
  threadId: string;
}) => {
  return callSupabaseFnFromClient<ChatThreadId[]>({
    fnName: "admin_delete_chat_thread",
    args: {
      thread_id: threadId,
    },
  });
};
