import { createFileRoute, Link } from "@tanstack/react-router";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { getBusinessUnits } from "@/ajax/organizations/getBusinessUnits";
import { createBusinessUnit } from "@/ajax/organizations/createBusinessUnit";
import { updateBusinessUnit } from "@/ajax/organizations/updateBusinessUnit";
import { getOrganization } from "@/ajax/organizations/getOrganization";
import { Card } from "@/components/ui/card";

interface BusinessUnit {
  id: string;
  name: string;
  created_at: string;
}

const BusinessUnitsPage = () => {
  const { orgId } = Route.useParams();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [unitName, setUnitName] = useState("");
  const [editingUnit, setEditingUnit] = useState<BusinessUnit | null>(null);
  const queryClient = useQueryClient();

  const { data: organization } = useQuery({
    queryKey: ["organization", orgId],
    queryFn: () => getOrganization({ orgId }),
  });

  const { data: businessUnits } = useQuery({
    queryKey: ["businessUnits", orgId],
    queryFn: () => getBusinessUnits({ orgId }),
  });

  const createUnitMutation = useMutation({
    mutationFn: () => createBusinessUnit({ orgId, name: unitName }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["businessUnits", orgId] });
      setIsDialogOpen(false);
      setUnitName("");
      setEditingUnit(null);
    },
  });

  const updateUnitMutation = useMutation({
    mutationFn: () =>
      updateBusinessUnit({ businessUnitId: editingUnit!.id, name: unitName }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["businessUnits", orgId] });
      setIsDialogOpen(false);
      setUnitName("");
      setEditingUnit(null);
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (editingUnit) {
      updateUnitMutation.mutate();
    } else {
      createUnitMutation.mutate();
    }
  };

  const handleEdit = (unit: BusinessUnit) => {
    setEditingUnit(unit);
    setUnitName(unit.name);
    setIsDialogOpen(true);
  };

  const handleAddNew = () => {
    setEditingUnit(null);
    setUnitName("");
    setIsDialogOpen(true);
  };

  return (
    <div className="container mx-auto py-8 px-2">
      <div className="max-w-5xl mx-auto">
        <div className="mb-6">
          <Link to="/admin/organizations">
            <Button variant="outline" className="mb-4">
              ← Back to Organizations
            </Button>
          </Link>
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold">
              Business Units - {organization?.name}
            </h1>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button onClick={handleAddNew}>Add Business Unit</Button>
              </DialogTrigger>
              <DialogContent className="min-w-[500px] p-6">
                <DialogHeader>
                  <DialogTitle>
                    {editingUnit ? "Edit" : "Add New"} Business Unit
                  </DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                  <div>
                    <Label htmlFor="name">Business Unit Name</Label>
                    <Input
                      id="name"
                      value={unitName}
                      onChange={(e) => setUnitName(e.target.value)}
                      placeholder="Enter business unit name"
                    />
                  </div>
                  <div className="flex justify-end gap-2">
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => setIsDialogOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={
                        createUnitMutation.isPending ||
                        updateUnitMutation.isPending
                      }
                    >
                      {createUnitMutation.isPending ||
                      updateUnitMutation.isPending
                        ? "Saving..."
                        : editingUnit
                          ? "Save"
                          : "Create"}
                    </Button>
                  </div>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        {!businessUnits || businessUnits.length === 0 ? (
          <div className="flex flex-col items-center justify-center py-12 px-4 text-gray-500 bg-gray-50 rounded-lg">
            <svg
              className="w-12 h-12 mb-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              />
            </svg>
            <h3 className="text-lg font-semibold mb-2">
              No Business Units Yet
            </h3>
            <p className="text-sm text-center mb-4">
              Get started by creating your first business unit
            </p>
            <Button onClick={handleAddNew} variant="outline" size="sm">
              Add Business Unit
            </Button>
          </div>
        ) : (
          <Card className="p-0">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Name</TableHead>
                  <TableHead>Created At</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {businessUnits.map((unit) => (
                  <TableRow key={unit.id}>
                    <TableCell>{unit.name}</TableCell>
                    <TableCell>
                      {new Date(unit.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <div className="flex gap-2">
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => handleEdit(unit)}
                        >
                          Edit
                        </Button>
                        <Button variant="outline" size="sm" asChild>
                          <Link
                            to="/admin/organizations/$orgId/business-units/$businessUnitId/users"
                            params={{ orgId, businessUnitId: unit.id }}
                          >
                            View Users
                          </Link>
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        )}
      </div>
    </div>
  );
};

export const Route = createFileRoute(
  "/_admin/admin/organizations/$orgId/business-units/",
)({
  component: BusinessUnitsPage,
});
