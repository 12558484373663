import { supabase } from "../clients/supabase";

export const getSpecialties = async () => {
  const { error, data } = await supabase.rpc("get_medical_specialties");

  if (error) {
    console.error("Error:", error);
    throw error;
  }

  return data;
};
