import {
  PushNotifications,
  PermissionStatus,
} from "@capacitor/push-notifications";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { updateDeviceToken } from "@/ajax/notifications/updateDeviceToken";
import { handlePushNotificationActions } from "@/features/push_notifications/handlePushNotificationActions";
import posthog from "posthog-js";

export class PushNotificationService {
  // Check if we're running on a native platform that supports push
  private static isPushAvailable(): boolean {
    return (
      Capacitor.isNativePlatform() &&
      (Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android")
    );
  }

  // Request permission safely
  static async requestPermission(): Promise<boolean> {
    if (!this.isPushAvailable()) {
      console.error("Push notifications are not available on this platform");
      throw new Error("Push notifications are not available on this platform");
    }

    const currentPermissions = await PushNotifications.checkPermissions();

    if (currentPermissions.receive === "prompt") {
      posthog.capture("push_notification.request_permission");
      const permissionStatus = await PushNotifications.requestPermissions();
      if (permissionStatus.receive === "granted") {
        await PushNotifications.register();
        return true;
      }
    } else if (currentPermissions.receive === "granted") {
      posthog.capture("push_notification.granted");
      await PushNotifications.register();
      return true;
    }

    return false;
  }

  static setupPushListeners(): void {
    if (!this.isPushAvailable()) {
      return;
    }
    // Registration success
    PushNotifications.addListener("registration", async (token) => {
      const [deviceInfo, deviceId] = await Promise.all([
        Device.getInfo(),
        Device.getId(),
      ]);
      if (!["ios", "android"].includes(deviceInfo.platform)) {
        return;
      }
      const platformId = deviceInfo.platform === "ios" ? 1 : 2;

      updateDeviceToken({
        deviceToken: token.value,
        platformId,
        platformVersion: deviceInfo.osVersion,
        deviceId: deviceId.identifier,
      });
    });

    // Registration error
    PushNotifications.addListener("registrationError", (error) => {
      console.error("Push registration error:", error);
    });

    // Push received
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log("Push received:", notification);
      },
    );

    // Push action performed
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action) => {
        posthog.capture("push_notification.received", {
          action: action.notification.data?._action,
          title: action.notification.title,
          body: action.notification.body,
        });
        const notificationAction = action.notification.data?._action;
        console.log(
          "notificationAction",
          notificationAction,
          action,
          action.notification,
        );

        if (notificationAction) {
          handlePushNotificationActions({
            action: notificationAction,
            data: action.notification.data,
          });
        }
      },
    );
  }

  // Public method to check current permission status
  static async checkPermissionStatus(): Promise<PermissionStatus | null> {
    if (!this.isPushAvailable()) {
      return null;
    }
    return await PushNotifications.checkPermissions();
  }
}
