import { getSharedUserQueryOptions } from "@/ajax/queries";
import { ProviderProfilePage } from "@/features/profile/components/ProviderProfilePage";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { LockKeyhole } from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";

export const Route = createFileRoute("/_admin/admin/provider-profile/$userId/")(
  {
    errorComponent: ({ error }) => {
      console.error(error);
      return (
        <div className="flex flex-col items-center justify-center min-h-[50vh] p-8">
          <Card className="max-w-md">
            <CardHeader>
              <div className="text-center">
                <LockKeyhole className="w-12 h-12 mx-auto mb-4 text-muted-foreground" />
                <h2 className="text-2xl font-semibold">An Error Occurred</h2>
              </div>
            </CardHeader>
            <CardContent>
              <p className="text-muted-foreground text-center">
                We encountered an error while trying to load this profile. This
                may be because you don't have permission to view it. If you
                believe you should have access, please try again later.
              </p>
            </CardContent>
          </Card>
        </div>
      );
    },
    component: () => {
      const { userId } = Route.useParams();
      const { user } = Route.useRouteContext();

      const { data: providerUser } = useSuspenseQuery(
        getSharedUserQueryOptions(userId),
      );
      return (
        <ProviderProfilePage editable={user.is_admin} user={providerUser} />
      );
    },
  },
);
