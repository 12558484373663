import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type Organization = {
  id: string;
  name: string;
  created_at: string;
};

export const getOrganizations = async () => {
  return callSupabaseFnFromClient<Organization[]>({
    fnName: "admin_get_organizations",
  });
};
