import { apiRequestPostWithRetry } from "../utils/apiRequest";

export const createBillingWebSessionForNative = async (): Promise<{
  loginUrl: string;
}> => {
  return await apiRequestPostWithRetry({
    path: "/billing/app-session",
    retryOptions: {
      maxRetries: 1,
      retryDelay: 2000,
    },
    body: {},
  });
};
