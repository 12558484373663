import "@/common/utils/initSentry";
import ReactDOM from "react-dom/client";

// Import the generated route tree
import { initializeApp } from "./common/utils/liveReload";
import { Main } from "./main";

initializeApp();

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(<Main />);
}
