import { supabase } from "@/ajax/clients/supabase";
import { SocialLogin } from "@capgo/capacitor-social-login";
import { getEnvVar, isNative } from "@/common/utils/environment";
import posthog from "posthog-js";

let initialized = false;
export const initializeGoogleAuth = async () => {
  if (isNative() && !initialized) {
    await SocialLogin.initialize({
      google: {
        iOSClientId: getEnvVar("VITE_PUBLIC_GOOGLE_AUTH_IOS_CLIENT_ID"),
        mode: "online",
      },
    });
    initialized = true;
  }
};

const nativeSignInWithGoogle = async () => {
  try {
    await initializeGoogleAuth();
    const response = await SocialLogin.login({
      provider: "google",
      options: {
        scopes: ["email", "profile"],
        forceRefreshToken: true,
        forcePrompt: true, // if you need refresh token
      },
    });

    const idToken = (response.result as { idToken: string }).idToken;

    if (!idToken) {
      throw new Error("No authentication data present!");
    }

    const { data, error } = await supabase.auth.signInWithIdToken({
      provider: "google",
      token: idToken,
    });

    if (error) throw error;

    return { error, data };
  } catch (error) {
    console.error("Error signing in with Google:", error);
    throw error;
  }
};

/***
 * Sign in with Google email. This redirects the user to Google auth and they are redirected back when done.
 * @returns
 */
const webSignInWithGoogle = async ({ redirectTo }: { redirectTo?: string }) => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: "google",
    options: {
      redirectTo,
    },
  });

  return {
    data,
    error,
  };
};

export const signInWithGoogle = async ({
  redirectTo,
}: {
  redirectTo?: string;
}) => {
  posthog.capture("signin.google.attempt");
  if (isNative()) {
    return nativeSignInWithGoogle();
  }

  return webSignInWithGoogle({ redirectTo });
};
