import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type Organization = {
  id: string;
  name: string;
  created_at: string;
};

export const updateOrganization = async ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  return callSupabaseFnFromClient<Organization>({
    fnName: "admin_update_organization",
    args: {
      organization_id: id,
      organization_name: name,
    },
  });
};
