import {
  createFileRoute,
  Outlet,
  redirect,
  ScrollRestoration,
} from "@tanstack/react-router";
import { useEffect } from "react";
import { supabase } from "@/ajax/clients/supabase";
import OfflineHandler from "@/components/OfflineHandler";
import { OrgLayout } from "@/components/Layouts/OrgLayout";
import { saveLastEmail } from "@/common/utils/lastEmail";
import { z } from "zod";
import { NotFoundComponent } from "@/components/NotFoundComponent";

export const Route = createFileRoute("/_org")({
  validateSearch: z.object({
    email: z.string().optional(),
  }),
  beforeLoad: async ({ location, context, search }) => {
    if (search.email) {
      saveLastEmail(search.email);
    }

    if (!context.user) {
      throw redirect({
        to: "/auth-org/signin",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.pathname,
        },
      });
    }

    return {
      user: context.user,
    };
  },
  notFoundComponent: () => <NotFoundComponent homePath="/org/home" />,
  component: () => {
    const { user } = Route.useRouteContext();

    useEffect(() => {
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((event, session) => {
        if (event === "SIGNED_OUT") {
          document.location.reload();
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }, []);

    if (!user) {
      return <OfflineHandler />;
    }

    return (
      <OrgLayout user={user} showSidebar={true}>
        <Outlet />
        <ScrollRestoration />
      </OrgLayout>
    );
  },
});
