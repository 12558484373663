import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
export type ReferralIdType = {
  id: string;
};

// We need to create a referral with the status of "In Progress". It will be set to completed when the user completes the signup.
const IN_PROGRESS_STATUS_ID = 2;

export const createReferral = async ({
  referralCode,
}: {
  referralCode: string;
}): Promise<ReferralIdType[]> => {
  return await callSupabaseFnFromClient<ReferralIdType[]>({
    fnName: "create_user_referral",
    args: {
      code: referralCode,
      status_id: IN_PROGRESS_STATUS_ID,
    },
    retryCount: 2,
  });
};
