import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export type ChatThreadUnreadMessages = {
  thread_id: string;
  unread_count: number;
};

export const getChatUnreadMessagesCount = async () => {
  return callSupabaseFnFromClient<ChatThreadUnreadMessages[]>({
    fnName: "get_chat_unread_messages_count",
  });
};
