import * as React from "react";
import { User, RefreshCw } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Input } from "@/components/ui/input";
import { debounce } from "@/common/utils/debounce";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { adminGetReferrals } from "@/ajax/referrals/adminGetReferrals";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { adminPayReferrals } from "@/ajax/referrals/adminPayReferrals";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

function ReferralsPage() {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filterPaid, setFilterPaid] = React.useState<"all" | "paid" | "unpaid">(
    "all",
  );
  const [selectedReferrals, setSelectedReferrals] = React.useState<string[]>(
    [],
  );
  const [minCredentials, setMinCredentials] = React.useState("");
  const [showPaymentDialog, setShowPaymentDialog] = React.useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const queryClient = useQueryClient();

  const referralsQuery = useQuery({
    queryKey: ["referrals", searchQuery, filterPaid, minCredentials],
    queryFn: () =>
      adminGetReferrals({
        search: searchQuery,
        paid:
          filterPaid === "paid"
            ? true
            : filterPaid === "unpaid"
              ? false
              : undefined,
        minCredentialCount: minCredentials
          ? parseInt(minCredentials)
          : undefined,
      }),
    staleTime: 5000,
  });

  const payReferralMutation = useMutation({
    mutationFn: async (referralIds: string[]) => {
      await adminPayReferrals(referralIds);
    },
    onSuccess: () => {
      setShowSuccessMessage(true);
      queryClient.invalidateQueries({ queryKey: ["referrals"] });
      setSelectedReferrals([]);
    },
  });

  const filteredReferrals = React.useMemo(() => {
    return referralsQuery.data ?? [];
  }, [referralsQuery.data]);

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedReferrals(
        filteredReferrals.filter((r) => !r.referred_paid).map((r) => r.id),
      );
    } else {
      setSelectedReferrals([]);
    }
  };

  const handleSelectReferral = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedReferrals((prev) => [...prev, id]);
    } else {
      setSelectedReferrals((prev) => prev.filter((refId) => refId !== id));
    }
  };

  const handlePaySelected = async () => {
    await payReferralMutation.mutateAsync(selectedReferrals);
  };

  const handleCloseDialog = () => {
    setShowPaymentDialog(false);
    setShowSuccessMessage(false);
  };

  return (
    <div className="w-full p-2 pt-4 md:pt-8 md:p-8 min-h-screen bg-gray-100">
      <div>
        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center gap-4">
            <h2 className="text-title-md2 font-bold text-black dark:text-white">
              Referrals
            </h2>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => referralsQuery.refetch()}
              disabled={referralsQuery.isFetching}
              className="hover:bg-gray-100 flex items-center gap-2"
            >
              <RefreshCw
                className={`w-4 h-4 ${referralsQuery.isFetching ? "animate-spin" : ""}`}
              />
              <span className="sr-only md:not-sr-only">Refresh</span>
            </Button>
          </div>
          {selectedReferrals.length > 0 && (
            <Button onClick={() => setShowPaymentDialog(true)}>
              Pay Selected ({selectedReferrals.length})
            </Button>
          )}
        </div>

        <Dialog open={showPaymentDialog} onOpenChange={handleCloseDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                {showSuccessMessage ? "Payment Processing" : "Confirm Payment"}
              </DialogTitle>
              <DialogDescription>
                {showSuccessMessage ? (
                  <>
                    <p>The payment is being processed in the background.</p>
                    <p className="mt-2">
                      Please wait a 30 seconds and click the refresh button to
                      see the updated status.
                    </p>
                  </>
                ) : (
                  `This will send $${selectedReferrals.length * 20} total ($20 per referral) to the referring users. Are you sure you want to continue?`
                )}
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <Button variant="outline" onClick={handleCloseDialog}>
                {showSuccessMessage ? "Close" : "Cancel"}
              </Button>
              {!showSuccessMessage && (
                <Button
                  onClick={handlePaySelected}
                  disabled={payReferralMutation.isPending}
                >
                  {payReferralMutation.isPending
                    ? "Processing..."
                    : "Confirm Payment"}
                </Button>
              )}
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <div className="rounded-md border bg-white animate-fade-in-delay-2">
          <div className="p-4 flex gap-4">
            <Input
              placeholder="Search by email..."
              onChange={debounce((e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value);
              }, 500)}
              className="max-w-sm"
            />
            <Select
              value={filterPaid}
              onValueChange={(value: "all" | "paid" | "unpaid") =>
                setFilterPaid(value)
              }
            >
              <SelectTrigger className="w-[180px] py-7">
                <SelectValue placeholder="Filter by status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Referrals</SelectItem>
                <SelectItem value="paid">Paid</SelectItem>
                <SelectItem value="unpaid">Unpaid</SelectItem>
              </SelectContent>
            </Select>
            <Input
              type="number"
              placeholder="Min credentials..."
              value={minCredentials}
              onChange={(e) => setMinCredentials(e.target.value)}
              className="max-w-[150px]"
            />
          </div>

          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[5%]">
                  <Checkbox
                    checked={
                      filteredReferrals.length > 0 &&
                      selectedReferrals.length ===
                        filteredReferrals.filter((r) => !r.referred_paid).length
                    }
                    onCheckedChange={handleSelectAll}
                  />
                </TableHead>
                <TableHead className="w-[20%]">Referring User</TableHead>
                <TableHead className="w-[20%]">Referred User</TableHead>
                <TableHead className="w-[15%] text-center">
                  Credentials
                </TableHead>
                <TableHead className="w-[15%] text-center">
                  Onboarding Completed
                </TableHead>
                <TableHead className="w-[10%] text-center">Status</TableHead>
                <TableHead className="w-[15%]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredReferrals.length ? (
                filteredReferrals.map((referral) => (
                  <TableRow key={referral.id}>
                    <TableCell>
                      {!referral.referred_paid &&
                        !referral.referred_completed && (
                          <Checkbox
                            checked={selectedReferrals.includes(referral.id)}
                            onCheckedChange={(checked) =>
                              handleSelectReferral(
                                referral.id,
                                checked as boolean,
                              )
                            }
                          />
                        )}
                    </TableCell>
                    <TableCell className="w-[20%]">
                      <div className="flex items-center">
                        {referral.referrer_first_name}{" "}
                        {referral.referrer_last_name} ({referral.referrer_email}
                        )
                      </div>
                    </TableCell>
                    <TableCell className="w-[20%] font-medium">
                      {referral.referred_first_name || "[No first name]"}{" "}
                      {referral.referred_last_name || "[No last name]"} (
                      {referral.referred_email})
                    </TableCell>
                    <TableCell className="w-[15%] text-center">
                      {referral.referred_credentials_count}
                    </TableCell>
                    <TableCell className="w-[15%] text-center">
                      {referral.referred_max_onboarding_step >= 5
                        ? "Yes"
                        : "No"}
                    </TableCell>
                    <TableCell className="w-[10%] text-center">
                      <Popover>
                        <PopoverTrigger>
                          <span
                            className={`px-2 py-1 rounded-full text-xs font-medium cursor-help ${
                              referral.referred_paid
                                ? "bg-green-100 text-green-800"
                                : referral.referred_completed
                                  ? "bg-blue-100 text-blue-800"
                                  : "bg-yellow-100 text-yellow-800"
                            }`}
                          >
                            {referral.referred_paid
                              ? "Paid"
                              : referral.referred_completed
                                ? "Pending Account"
                                : "Unpaid"}
                          </span>
                        </PopoverTrigger>
                        {referral.referred_completed &&
                          !referral.referred_paid && (
                            <PopoverContent className="w-80">
                              <div className="text-sm">
                                <p>
                                  This referral is complete but payment is
                                  pending.
                                </p>
                                <p className="mt-2">
                                  The referred user needs to create a Stripe
                                  account to receive their referral payment.
                                </p>
                              </div>
                            </PopoverContent>
                          )}
                      </Popover>
                    </TableCell>
                    <TableCell className="w-[15%]">
                      <Button variant="outline" size="sm" asChild>
                        <Link
                          to="/org/provider-profile/$userId"
                          params={{ userId: referral.referred_user_id }}
                          target="_blank"
                        >
                          View Profile
                        </Link>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} className="h-24 text-center">
                    <div className="flex flex-col items-center justify-center h-32 sm:h-64 text-center">
                      <User className="w-12 h-12 sm:w-16 sm:h-16 mb-3 sm:mb-4" />
                      <p className="text-lg sm:text-xl font-medium text-gray-900 mb-1 sm:mb-2">
                        No referrals found
                      </p>
                      <p className="text-sm">
                        Try adjusting your search or filter
                      </p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_admin/admin/referrals/")({
  component: ReferralsPage,
});
