import React, { useState, useEffect } from "react";
import { ChatThread, getChatThreads } from "@/ajax/chat/getChatThreads";
import { useQuery } from "@tanstack/react-query";
import { ChatThreadView } from "./ChatThreadView";
import { useRouter, useSearch } from "@tanstack/react-router";
import { queryClient } from "@/ajax/queryClient";
import { ChatThreadList } from "./ChatThreadList";
import Loading from "@/components/Loading";
import { useUserBusinessUnits } from "@/ajax/organizations/hooks/useUserBusinessUnits";
import { listenForMessages } from "./real_time/real_time_chat";

const Chat = ({
  user,
}: {
  user: {
    id: string;
  } | null;
}) => {
  const router = useRouter();
  const search = useSearch({
    strict: false,
  });
  const [selectedThread, setSelectedThread] = useState<ChatThread | null>(null);

  const { data: chatThreads } = useQuery({
    queryKey: ["chatThreads", search.archived],
    queryFn: () => getChatThreads({ isArchived: search.archived === true }),
    refetchInterval: 30_000,
  });

  const { data: businessUnits } = useUserBusinessUnits();

  const handleUnarchive = () => {
    queryClient.invalidateQueries({ queryKey: ["chatThreads"] });
    router.navigate({
      // @ts-ignore
      search: (prev) => ({
        ...prev,
        archived: false,
      }),
    });
  };

  // Only run this effect when search.thread changes
  useEffect(() => {
    if (!chatThreads) return;
    const threadId = search.thread;
    if (threadId) {
      const thread = chatThreads.find((t) => t.id === threadId);
      if (thread) {
        setSelectedThread(thread);
      } else {
        // If thread not found in list, set to null
        setSelectedThread(null);
        // Also clear the thread from URL
        router.navigate({
          // @ts-ignore
          search: (prev) => {
            const { thread, ...rest } = prev;
            return rest;
          },
        });
      }
    } else {
      setSelectedThread(null);
    }
  }, [search.thread, chatThreads, router]);

  useEffect(() => {
    if (!user || !businessUnits) return;

    const businessUnitIds = businessUnits.map((bu) => bu.id);

    const { unsubscribe } = listenForMessages({
      userId: user.id,
      orgBusinessUnitIds: businessUnitIds,
      onMessage: (message) => {
        queryClient.invalidateQueries({ queryKey: ["chatThreads"] });
        queryClient.invalidateQueries({
          queryKey: ["chatThreadMessages", message.threadId],
        });
      },
    });

    return () => {
      unsubscribe();
    };
  }, [businessUnits, user]);

  const handleSelectThread = (thread: ChatThread) => {
    router.navigate({
      // @ts-ignore
      search: (prev) => ({
        ...prev,
        thread: thread.id,
      }),
    });
  };

  const handleBack = () => {
    router.navigate({
      // @ts-ignore
      search: (prev) => {
        const { thread, ...rest } = prev;
        return rest;
      },
    });
  };

  if (!chatThreads) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <Loading className="w-10 h-10" />
      </div>
    );
  }

  if (chatThreads.length === 0 && !search.archived) {
    return (
      <div className="absolute inset-0 flex items-center justify-center bg-white">
        <div className="text-center">
          <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <svg
              className="w-8 h-8 text-blue-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
              />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900">
            No conversations yet
          </h3>
          <p className="mt-2 text-gray-600">
            You don't have any chat threads at the moment
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 flex flex-col lg:flex-row absolute inset-0 bg-white h-full">
      <div
        className={`lg:max-w-sm lg:flex-1 border-r ${selectedThread ? "hidden lg:block" : ""}`}
      >
        <ChatThreadList
          threads={chatThreads}
          onSelectThread={handleSelectThread}
          selectedThread={selectedThread}
        />
      </div>
      {selectedThread ? (
        <ChatThreadView
          thread={selectedThread}
          onBack={handleBack}
          onUnarchive={handleUnarchive}
        />
      ) : (
        <div className="hidden lg:flex flex-1 items-center justify-center">
          <div className="text-center">
            <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
              <svg
                className="w-8 h-8 text-blue-600"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
            </div>
            <h3 className="text-xl font-semibold text-gray-900">
              No conversation selected
            </h3>
            <p className="mt-2 text-gray-600">
              Choose a conversation from the list to start chatting
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
