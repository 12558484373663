import { createBillingSession } from "@/ajax/billing/createBillingSession";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectAccountManagement,
  ConnectComponentsProvider,
  ConnectPayouts,
} from "@stripe/react-connect-js";
import { Settings } from "lucide-react";
import { useState } from "react";

export default function StripeBillingDashboard() {
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const { clientSecret } = await createBillingSession();
      return clientSecret;
    };
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: import.meta.env.VITE_PUBLIC_STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#625afa",
        },
      },
    });
  });
  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <div className="container max-w-4xl mx-auto py-8">
        <Card className="shadow-sm">
          <CardHeader>
            <div className="flex items-center justify-between">
              <div>
                <CardTitle className="text-2xl font-semibold">
                  Payment Dashboard
                </CardTitle>
                <CardDescription>
                  Manage your payouts and account information
                </CardDescription>
              </div>
              <Dialog>
                <DialogTrigger asChild>
                  <Button variant="outline" className="flex items-center gap-2">
                    <Settings className="w-4 h-4" />
                    Account Settings
                  </Button>
                </DialogTrigger>
                <DialogContent className="max-w-5xl w-full">
                  <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">
                      Account Management
                    </DialogTitle>
                  </DialogHeader>
                  <ConnectAccountManagement />
                </DialogContent>
              </Dialog>
            </div>
          </CardHeader>
          <CardContent>
            <ConnectPayouts />
          </CardContent>
        </Card>
      </div>
    </ConnectComponentsProvider>
  );
}
