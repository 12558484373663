import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/auth/signin/")({
  // Handle old route for backwards compatibility
  beforeLoad: () => {
    throw redirect({
      to: "/auth-provider/signin",
    });
  },
});
