import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";

export const acceptContract = async ({
  contractName,
}: {
  contractName: string;
}): Promise<void> => {
  const response = await callSupabaseFnFromClient<void>({
    fnName: "accept_contract",
    args: {
      contract_name: contractName,
    },
  });

  return response;
};
