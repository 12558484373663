import { Link } from "@tanstack/react-router";
import {
  CalendarRangeIcon,
  DollarSign,
  FolderIcon,
  HouseIcon,
  Menu,
  MessageSquare,
  Settings,
  SquareUserRound,
  Wallet,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import SidebarItem from "@/components/Sidebar/SidebarItem";
import { UserAvatar } from "@/components/UserAvatar/UserAvatar";
import { useRouterState } from "@tanstack/react-router";
import { DownloadApp } from "../DownloadApp";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { getChatUnreadMessagesCountQueryOptions } from "@/ajax/queries";
import BottomBarItem from "../BottomBar/BottomBarItem";

const sideItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/provider/home",
  },
  {
    icon: <FolderIcon />,
    label: "Documents",
    route: "/provider/documents",
  },
  {
    icon: <SquareUserRound />,
    label: "Profile",
    route: "/provider/profile",
  },
  {
    icon: <CalendarRangeIcon />,
    label: "Shifts",
    route: "/provider/shifts",
  },
  {
    icon: <MessageSquare />,
    label: "Chat",
    route: "/provider/chat",
    sidebarItem: ({
      item,
      onClick,
    }: {
      item: {
        icon: React.ReactNode;
        label: string;
        route?: string;
      };
      onClick: () => void;
    }) => {
      const { data: unreadMessagesCount } = useQuery(
        getChatUnreadMessagesCountQueryOptions(),
      );

      const notificationCount =
        unreadMessagesCount?.reduce(
          (acc, message) => acc + message.unread_count,
          0,
        ) ?? 0;

      return (
        <SidebarItem
          notificationCount={notificationCount}
          item={item}
          onClick={onClick}
        />
      );
    },
  },
  {
    divider: true,
  },
  {
    icon: <DollarSign />,
    label: "Refer Friends",
    route: "/provider/referrals",
  },
  {
    icon: <Settings />,
    label: "More Options",
    subItems: [
      {
        icon: <Wallet />,
        label: "Billing",
        route: "/provider/billing",
      },
      {
        icon: <Settings />,
        label: "Settings",
        route: "/provider/settings",
      },
    ],
  },
];

const mobileNavItems = [
  {
    icon: <HouseIcon />,
    label: "Home",
    route: "/provider/home",
  },
  {
    icon: <FolderIcon />,
    label: "Documents",
    route: "/provider/documents",
  },
  {
    icon: <SquareUserRound />,
    label: "Profile",
    route: "/provider/profile",
  },
  {
    icon: <CalendarRangeIcon />,
    label: "Shifts",
    route: "/provider/shifts",
  },
  {
    route: "/provider/chat",
    label: "Chat",
    icon: <MessageSquare />,
    bottomMenutItem: ({
      item,
    }: {
      item: {
        icon: React.ReactNode;
        label: string;
        route: string;
      };
    }) => {
      const { data: unreadMessagesCount } = useQuery(
        getChatUnreadMessagesCountQueryOptions(),
      );

      const notificationCount =
        unreadMessagesCount?.reduce(
          (acc, message) => acc + message.unread_count,
          0,
        ) ?? 0;

      return (
        <BottomBarItem notificationCount={notificationCount} item={item} />
      );
    },
  },
];

export function ProviderLayout({
  children,
  showSidebar = true,
}: {
  children: React.ReactNode;
  showSidebar?: boolean;
}) {
  const [openSheet, setOpenSheet] = useState(false);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const router = useRouterState();
  const chatEnabled = useFeatureFlagEnabled("chat");
  const hideNav = router.location.search.hideNav;

  const filteredMobileNavItems = chatEnabled
    ? mobileNavItems
    : mobileNavItems.filter((item) => item.label !== "Chat");
  const filteredSideItems = chatEnabled
    ? sideItems
    : sideItems.filter((item) => item.label !== "Chat");

  // If hideNav is true, don't show any navigation
  if (hideNav) {
    return (
      <div className="min-h-screen w-full">
        <div className="flex flex-col h-screen">
          <main className="flex-1 overflow-y-auto bg-gray-100">{children}</main>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={`min-h-screen max-h-screen w-full ${showSidebar ? "grid lg:grid-cols-[280px_1fr]" : ""}`}
      >
        {showSidebar && (
          <div
            id="static-sidebar"
            className="hidden border-r bg-muted/40 lg:block"
          >
            <div className="flex h-full max-h-screen flex-col gap-2">
              <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
                <Link
                  to="/provider/home"
                  className="flex items-center gap-2 font-semibold"
                >
                  <img
                    alt="Saile Logo"
                    src="/static/images/logo.png"
                    className="w-[114px]"
                  />
                </Link>
              </div>
              <div className="flex-1">
                <nav className="grid items-start px-2 mt-6 text-sm font-medium lg:px-4">
                  {filteredSideItems.map((item) => {
                    if (item.divider) {
                      return (
                        <hr
                          key="divider"
                          className="my-2 border-t border-gray-200"
                        />
                      );
                    }
                    const isExpanded = expandedItems.includes(
                      item?.label || "",
                    );
                    const onClick = () => {
                      if (item.subItems) {
                        setExpandedItems((prev) =>
                          prev.includes(item.label)
                            ? prev.filter((i) => i !== item.label)
                            : [...prev, item.label],
                        );
                      }
                    };

                    const MenuItem = item.sidebarItem;
                    return (
                      <React.Fragment key={item.label}>
                        {MenuItem ? (
                          <MenuItem
                            item={
                              item as {
                                icon: React.ReactNode;
                                label: string;
                                route?: string;
                              }
                            }
                            onClick={onClick}
                          />
                        ) : (
                          <SidebarItem
                            item={
                              item as {
                                icon: React.ReactNode;
                                label: string;
                                route?: string;
                              }
                            }
                            onClick={onClick}
                          />
                        )}
                        {item.subItems && isExpanded && (
                          <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                            {item.subItems.map((subItem) => (
                              <SidebarItem
                                key={subItem.label}
                                item={subItem}
                                // className="text-xs text-muted-foreground hover:text-primary py-1"
                              />
                            ))}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col h-screen">
          <header className="flex items-center gap-4 border-b bg-muted px-4">
            {showSidebar && (
              <Sheet open={openSheet} onOpenChange={setOpenSheet}>
                <SheetTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="shrink-0 lg:hidden"
                  >
                    <Menu className="h-5 w-5" />
                    <span className="sr-only">Toggle navigation menu</span>
                  </Button>
                </SheetTrigger>
                <SheetContent
                  id="sidebar"
                  side="left"
                  className="flex flex-col"
                >
                  <img
                    alt="Saile Logo"
                    src="/static/images/logo.png"
                    className="ml-2 mb-4 w-[114px]"
                  />
                  <nav className="text-lg font-medium">
                    {filteredSideItems.map((item) => {
                      if (item.divider) {
                        return (
                          <hr
                            key="divider"
                            className="my-2 border-t border-gray-200"
                          />
                        );
                      }
                      const isExpanded = expandedItems.includes(
                        item?.label || "",
                      );
                      const onClick = () => {
                        if (item.subItems) {
                          setExpandedItems((prev) =>
                            prev.includes(item.label)
                              ? prev.filter((i) => i !== item.label)
                              : [...prev, item.label],
                          );
                        } else {
                          setOpenSheet(false);
                        }
                      };
                      const MenuItem = item.sidebarItem;
                      return (
                        <React.Fragment key={item.label}>
                          {MenuItem ? (
                            <MenuItem
                              item={
                                item as {
                                  icon: React.ReactNode;
                                  label: string;
                                  route?: string;
                                }
                              }
                              onClick={onClick}
                            />
                          ) : (
                            <SidebarItem
                              item={
                                item as {
                                  icon: React.ReactNode;
                                  label: string;
                                  route?: string;
                                }
                              }
                              onClick={onClick}
                            />
                          )}
                          {item.subItems && isExpanded && (
                            <div className="ml-6 border-l border-muted-foreground/20 pl-2">
                              {item.subItems.map((subItem) => (
                                <SidebarItem
                                  key={subItem.label}
                                  item={subItem}
                                  onClick={() => setOpenSheet(false)}
                                  // className="text-sm text-muted-foreground hover:text-primary py-1"
                                />
                              ))}
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </nav>
                </SheetContent>
              </Sheet>
            )}
            <div className="w-full flex-1 flex justify-center items-center">
              <img
                alt="Saile Logo"
                src="/static/images/logo.png"
                className="w-[114px] md:hidden"
              />
            </div>
            <UserAvatar minimal={!showSidebar} />
          </header>

          <main className="flex-1 flex flex-col relative overflow-y-auto bg-gray-100">
            {children}
          </main>

          {/* Mobile Bottom Navigation */}
          {showSidebar && (
            <div
              id="mobile-bottom-nav"
              className="border-t bg-white shadow-[0_-4px_10px_rgba(0,0,0,0.1)] lg:hidden h-[70px]"
            >
              <nav className="flex justify-around items-center h-full">
                {filteredMobileNavItems.map((item) => {
                  const MenuItem = item.bottomMenutItem;
                  return MenuItem ? (
                    <MenuItem key={item.label} item={item} />
                  ) : (
                    <BottomBarItem key={item.label} item={item} />
                  );
                })}
              </nav>
            </div>
          )}
        </div>
      </div>
      <DownloadApp />
    </>
  );
}
