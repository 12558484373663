import { Link } from "@tanstack/react-router";
import {
  Bell,
  Building2,
  Menu,
  MessageCircle,
  Rss,
  Search,
  User,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import React, { useState } from "react";
import SidebarItem from "@/components/Sidebar/SidebarItem";
import { UserAvatar } from "@/components/UserAvatar/UserAvatar";

export const description =
  "A products dashboard with a sidebar navigation and a main content area. The dashboard has a header with a search input and a user menu. The sidebar has a logo, navigation links, and a card with a call to action. The main content area shows an empty state with a call to action.";

const sideItems = [
  {
    icon: <User />,
    label: "Users",
    route: "/admin/users",
  },
  {
    icon: <Search />,
    label: "Provider Search",
    route: "/admin/provider-search",
  },
  {
    icon: <MessageCircle />,
    label: "All Chats",
    route: "/admin/chat",
  },
  {
    icon: <Building2 />,
    label: "Organizations",
    route: "/admin/organizations",
  },
  {
    icon: <Rss />,
    label: "Referrals",
    route: "/admin/referrals",
  },
];

export function AdminLayout({
  children,
  showSidebar = true,
}: {
  children: React.ReactNode;
  showSidebar?: boolean;
}) {
  const [openSheet, setOpenSheet] = useState(false);

  return (
    <div
      className={`min-h-screen w-full grid md:grid-cols-[220px_1fr] lg:grid-cols-[280px_1fr]`}
    >
      <div className="hidden border-r bg-muted/40 md:block">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
            <Link
              to="/admin/documents"
              className="flex items-center gap-2 font-semibold"
            >
              <img
                alt="Saile Logo"
                src="/static/images/logo.png"
                className="w-[114px]"
              />
            </Link>
            <Button variant="outline" size="icon" className="ml-auto h-8 w-8">
              <Bell className="h-4 w-4" />
              <span className="sr-only">Toggle notifications</span>
            </Button>
          </div>
          <div className="flex-1">
            <nav className="grid items-start px-2 mt-6 text-sm font-medium lg:px-4">
              {sideItems.map((item) => (
                <SidebarItem key={item.label} item={item} />
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <header className="flex h-14 items-center gap-4 border-b bg-muted/40 px-4 lg:h-[60px] lg:px-6">
          {showSidebar && (
            <Sheet open={openSheet} onOpenChange={setOpenSheet}>
              <SheetTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="shrink-0 md:hidden"
                >
                  <Menu className="h-5 w-5" />
                  <span className="sr-only">Toggle navigation menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="flex flex-col">
                <img
                  alt="Saile Logo"
                  src="/static/images/logo.png"
                  className="ml-2 mb-4 w-[114px]"
                />
                <nav className="text-lg font-medium">
                  {sideItems.map((item) => (
                    <SidebarItem
                      onClick={() => setOpenSheet(false)}
                      key={item.label}
                      item={item}
                    />
                  ))}
                </nav>
              </SheetContent>
            </Sheet>
          )}
          <div className="w-full flex-1"></div>
          <UserAvatar />
        </header>
        <main className="flex-1 overflow-y-auto bg-gray-100">{children}</main>
      </div>
    </div>
  );
}
