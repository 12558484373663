import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
type BusinessUnit = {
  id: string;
  organization_id: string;
  name: string;
  created_at: string;
};

export const createBusinessUnit = async ({
  orgId,
  name,
}: {
  orgId: string;
  name: string;
}) => {
  return callSupabaseFnFromClient<BusinessUnit>({
    fnName: "admin_create_business_unit",
    args: {
      org_id: orgId,
      business_unit_name: name,
    },
  });
};
