import { createRouter } from "@tanstack/react-router";
import "./index.css";
import { Error } from "./components/Error";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";
import { LoadingScreen } from "./components/LoadingScreen/LoadingScreen";

// Create a new router instance
export const router = createRouter({
  // defaultComponent: OfflineHandler,
  routeTree,
  defaultPendingComponent: (props) => {
    return (
      <div className="min-h-screen flex w-full items-center justify-center">
        <LoadingScreen />
      </div>
    );
  },
  defaultErrorComponent: ({ error }) => <Error error={error} />,
  defaultPreload: "intent",
});

router.subscribe("onResolved", (event) => {
  console.log("location", event.toLocation);
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
