import { ProfileImage } from "./ProfileImage";
import { Button } from "@/components/ui/button";
import {
  Check,
  Copy,
  Mail,
  MapPin,
  Phone,
  Pencil,
  MessageSquareText,
  Linkedin,
  Info,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";
import { PrimaryDetailsForm } from "./PrimaryDetailsForm";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  getHealthcareRoleQueryOptions,
  getSpecialtyCodesQueryOptions,
} from "@/ajax/queries";
import { SharingProfileButton } from "./SharingProfileButton";
import { ExternalLink } from "@/components/Button/ExternalLink";

export const PrimaryDetailsCard = ({
  userId,
  profileImagePath,
  linkedinUrl,
  editable,
  phoneNumber,
  email,
  address1,
  address2,
  city,
  state,
  zipCode,
  firstName,
  lastName,
  middleName,
  suffixName,
  specialtyCodes,
  npiNumber,
  healthcareProfessionalType,
  onEditChange,
  isEditing,
}: {
  userId: string;
  profileImagePath: string | undefined | null;
  linkedinUrl: string | null | undefined;
  phoneNumber: string;
  email: string;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  middleName: string | undefined;
  suffixName: string | undefined;
  specialtyCodes: string[];
  npiNumber: string | undefined | null;
  healthcareProfessionalType: string | undefined;
  onEditChange: (editing: boolean) => void;
  isEditing: boolean;
  editable: boolean;
}) => {
  const [copiedField, setCopiedField] = useState<string | null>(null);

  const { data: healthcareRoleData } = useSuspenseQuery(
    getHealthcareRoleQueryOptions(healthcareProfessionalType || ""),
  );

  const handleCopy = async (text: string, field: string) => {
    await navigator.clipboard.writeText(text);
    setCopiedField(field);
    setTimeout(() => setCopiedField(null), 2000);
  };

  if (isEditing) {
    return (
      <PrimaryDetailsForm
        userId={userId}
        defaultValues={{
          linkedinUrl: linkedinUrl || "",
          firstName: firstName || "",
          middleName: middleName || "",
          lastName: lastName || "",
          suffixName: suffixName || "",
          specialtyCodes: specialtyCodes || [],
          npiNumber: npiNumber || "",
          address: {
            address1: address1 || "",
            address2: address2 || "",
            city: city || "",
            state: state || "",
            zipCode: zipCode || "",
          },
          healthcareProfessionalType: healthcareProfessionalType || "",
        }}
        onSubmit={() => onEditChange(false)}
        onCancel={() => onEditChange(false)}
      />
    );
  }

  return (
    <div className="flex items-start gap-4">
      <ProfileImage
        editable={editable}
        userId={userId}
        profileImagePath={profileImagePath}
        firstName={firstName}
        lastName={lastName}
      />
      <div className="flex-1">
        <div className="flex justify-between flex-col sm:flex-row">
          <div className="pr-2">
            <div className="flex items-center gap-2 mb-2">
              <h2 className="text-2xl font-semibold">
                {firstName ? (
                  <>
                    {String(firstName || "")} {String(middleName || "")}{" "}
                    {String(lastName || "")} {String(suffixName || "")}
                  </>
                ) : (
                  "Not provided"
                )}
              </h2>
              {editable && (
                <Button
                  variant="ghost"
                  size="sm"
                  tabIndex={0}
                  className="hover:text-gray-700"
                  onClick={() => onEditChange(true)}
                >
                  <Pencil className="h-4 w-4" />
                </Button>
              )}
            </div>
            {healthcareRoleData && (
              <p className="font-semibold">{healthcareRoleData.name}</p>
            )}
            {city && state && (
              <p className="text-sm font-semibold">
                {city}, {state}
              </p>
            )}
            <div className="flex gap-2">
              <p className="text-sm">
                NPI: {String(npiNumber) || "Not provided"}
              </p>
            </div>
          </div>
          <div className="flex flex-row gap-2 mt-4 sm:mt-0 flex-wrap items-start sm:gap-y-0">
            <Dialog>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  size="sm"
                  className="hover:text-gray-700"
                >
                  <MessageSquareText className="h-4 w-4 mr-2" />
                  Contact
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-md">
                <DialogHeader>
                  <DialogTitle>Contact Information</DialogTitle>
                </DialogHeader>
                <div className="space-y-6 py-4">
                  <div className="flex items-center justify-between p-2 rounded-lg bg-gray-50">
                    <div className="flex items-center">
                      <Mail className="h-5 w-5 text-gray-500 mr-3" />
                      <div>
                        <div className="text-sm text-gray-500">Email</div>
                        <div>{email || "No email set"}</div>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      {email && (
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleCopy(email, "email")}
                          className="visible"
                        >
                          {copiedField === "email" ? (
                            <Check className="h-4 w-4" />
                          ) : (
                            <Copy className="h-4 w-4" />
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between p-2 rounded-lg bg-gray-50">
                    <div className="flex items-center">
                      <Phone className="h-5 w-5 text-gray-500 mr-3" />
                      <div>
                        <div className="text-sm text-gray-500">Phone</div>
                        <div>{phoneNumber || "No phone number set"}</div>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      {phoneNumber && (
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleCopy(phoneNumber, "phone")}
                          className="visible"
                        >
                          {copiedField === "phone" ? (
                            <Check className="h-4 w-4" />
                          ) : (
                            <Copy className="h-4 w-4" />
                          )}
                        </Button>
                      )}
                      {/* <Button
                        variant="ghost"
                        size="icon"
                        className="visible"
                      >
                        <Pencil className="h-4 w-4" />
                      </Button> */}
                    </div>
                  </div>
                  <div className="flex items-start p-2 rounded-lg bg-gray-50">
                    <MapPin className="h-5 w-5 text-gray-500 mr-3 mt-1" />
                    <div>
                      <div className="text-sm text-gray-500">Address</div>
                      <div>
                        {address1 ? (
                          <>
                            {address1}
                            {address2 && (
                              <>
                                <br />
                                {address2}
                              </>
                            )}
                            <br />
                            {city}, {state} {zipCode}
                          </>
                        ) : (
                          "No address provided"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            {linkedinUrl && (
              <ExternalLink
                href={linkedinUrl || ""}
                className="border border-gray-200 rounded-md px-2 py-1 h-9 inline-flex items-center hover:bg-gray-100"
              >
                <Linkedin className="h-4 w-4 mr-2" />
                LinkedIn
              </ExternalLink>
            )}
            {editable && <SharingProfileButton />}
          </div>
        </div>
      </div>
    </div>
  );
};
