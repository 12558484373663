import Select from "react-select";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getSpecialtiesQueryOptions } from "@/ajax/queries";
import { useMemo } from "react";
import AsyncSelect from "react-select/async";

interface SpecialtiesSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  useParent?: boolean;
}

export const SpecialtiesSelect = ({
  value,
  onChange,
  placeholder = "Select specialty",
  disabled = false,
  useParent = false,
}: SpecialtiesSelectProps) => {
  const { data: specialties } = useSuspenseQuery(getSpecialtiesQueryOptions());

  const specialtyOptions = useMemo(() => {
    return specialties
      .filter((specialty) => (useParent ? specialty.is_parent : true))
      .map((specialty) => ({
        label: specialty.name,
        value: specialty.code,
      }));
  }, [specialties]);

  const selectedOption = useMemo(() => {
    return specialtyOptions.find((option) => option.value === value);
  }, [value, specialtyOptions]);

  const loadSpecialties = async (inputValue: string) => {
    const lowerInput = inputValue.toLowerCase();
    return specialtyOptions
      .map((option) => ({
        ...option,
        score: option.label.toLowerCase().indexOf(lowerInput),
      }))
      .filter((option) => option.score >= 0)
      .sort((a, b) => {
        // Exact matches first
        if (a.score === 0 && b.score !== 0) return -1;
        if (b.score === 0 && a.score !== 0) return 1;
        // Then by position of match
        return a.score - b.score;
      })
      .map(({ label, value }) => ({ label, value }));
  };

  return (
    <AsyncSelect
      value={selectedOption}
      loadOptions={loadSpecialties}
      onChange={(option) => onChange?.(option?.value || "")}
      options={specialtyOptions}
      isDisabled={disabled}
      placeholder={placeholder}
      isClearable
      classNames={{
        control: (state) => "py-3.5",
      }}
    />
  );
};
