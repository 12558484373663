import { RouterProvider } from "@tanstack/react-router";
import "./index.css";
import { router } from "./router";
import { PostHogProvider } from "posthog-js/react";
import { getEnvVar } from "@/common/utils/environment";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./ajax/queryClient";
import { useState } from "react";
import { createTRPCClient, httpBatchLink } from "@trpc/client";
import { TRPCProvider } from "./trpc";
import { supabase } from "./ajax/clients/supabase";
import { AppRouter, appRouter } from "../../api/src/trpcRouter";
const options = {
  api_host: getEnvVar("VITE_PUBLIC_POSTHOG_HOST"),
};

export const Main = () => {
  const [trpcClient] = useState(() =>
    createTRPCClient<typeof appRouter>({
      links: [
        httpBatchLink({
          url: `${getEnvVar("VITE_PUBLIC_API_HOST")}/trpc`,
          headers: async () => {
            const { data } = await supabase.auth.getSession();
            return {
              authorization: `Bearer ${data.session?.access_token}`,
            };
          },
        }),
      ],
    }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      <TRPCProvider trpcClient={trpcClient} queryClient={queryClient}>
        <PostHogProvider
          apiKey={getEnvVar("VITE_PUBLIC_POSTHOG_KEY")}
          options={options}
        >
          <RouterProvider router={router} />
        </PostHogProvider>
      </TRPCProvider>
    </QueryClientProvider>
  );
};
