import Chat from "@/features/chat/Chat";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
export const Route = createFileRoute("/_org/org/chat/")({
  component: () => {
    const { user } = Route.useRouteContext();
    return <Chat user={user} />;
  },
  validateSearch: z.object({
    thread: z.string().optional(),
    archived: z.boolean().optional(),
  }),
});
