import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";
export const updateOrgUser = async ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}): Promise<string> => {
  return callSupabaseFnFromClient<string>({
    fnName: "update_org_user",
    args: {
      first_name: firstName,
      last_name: lastName,
    },
  });
};
