import { ChatThread } from "@/ajax/chat/getChatThreads";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Ban } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { adminDeleteChatThread } from "@/ajax/chat/adminDeleteChatThread";
import { queryClient } from "@/ajax/queryClient";

export const AdminChatThreadHeader = ({
  thread,
  onBack,
}: {
  thread: ChatThread;
  onBack: () => void;
}) => {
  const [showStopDialog, setShowStopDialog] = useState(false);

  const deleteChatMutation = useMutation({
    mutationFn: () => adminDeleteChatThread({ threadId: thread.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminChatThreads"] });
      setShowStopDialog(false);
    },
  });

  return (
    <div className="p-4 border-b flex items-center space-x-4 bg-white">
      <Button
        variant="ghost"
        size="icon"
        onClick={onBack}
        className="lg:hidden"
      >
        <ArrowLeft className="h-5 w-5" />
      </Button>
      <div className="flex-1">
        <h2 className="font-semibold">
          {thread.organization_participants.map((p) => p.name).join(", ")}{" "}
          {thread.user_participants
            .map((p) => `${p.first_name} ${p.last_name}`)
            .join(", ")}
        </h2>
      </div>

      <Button
        variant="destructive"
        size="sm"
        onClick={() => setShowStopDialog(true)}
        className="flex items-center gap-2"
      >
        <Ban className="h-4 w-4" />
        Stop Chat
      </Button>

      <AlertDialog open={showStopDialog} onOpenChange={setShowStopDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Stop this chat?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently stop the conversation. This action cannot be
              undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={() => deleteChatMutation.mutate()}>
              Stop Chat
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
