import posthog from "posthog-js";
import { apiRequestPostWithRetry } from "../utils/apiRequest";

type ShareProfileResponse = {
  link: string;
};
export const shareProfile = async ({
  emails,
  includeFiles,
  shareWithOthers,
}: {
  emails: string[];
  includeFiles: boolean;
  shareWithOthers: boolean;
}): Promise<ShareProfileResponse> => {
  posthog.capture("share_profile.attempt");
  const response = await apiRequestPostWithRetry({
    path: "/profile/share",
    body: {
      emails,
      includeFiles: includeFiles,
      shareWithOthers: shareWithOthers,
    },
  });
  return response.data;
};
