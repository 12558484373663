import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Camera, Pencil } from "lucide-react";
import { useEffect, useState } from "react";
import { DashboardModal } from "@uppy/react";
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import ImageEditor from "@uppy/image-editor";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "@uppy/image-editor/dist/style.min.css";
import { queryClient } from "@/ajax/queryClient";
import {
  getProfileImageQueryOptions,
  PROVIDER_USER_QUERY_KEY,
} from "@/ajax/queries";
import { getEnvVar } from "@/common/utils/environment";
import { getAccessToken, supabase } from "@/ajax/clients/supabase";
import { uploadProfileImage } from "@/ajax/profile/uploadProfileImage";
import { useSuspenseQuery } from "@tanstack/react-query";

const UPLOAD_URL = `${getEnvVar("VITE_PUBLIC_SUPABASE_URL")}/storage/v1/upload/resumable`;
const UPLOAD_BUCKET_NAME = "profile_image";

export const ProfileImage = ({
  editable,
  userId,
  profileImagePath,
  firstName,
  lastName,
}: {
  editable: boolean;
  userId: string;
  profileImagePath: string | undefined | null;
  firstName: string | undefined;
  lastName: string | undefined;
}) => {
  const { data: profileImage } = useSuspenseQuery(
    getProfileImageQueryOptions(profileImagePath),
  );
  const [showUploader, setShowUploader] = useState(false);
  const [currentProfileImage, setCurrentProfileImage] = useState<string | null>(
    profileImage,
  );

  const [uppyInstance, _] = useState(() => {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/png", "image/jpeg", "image/jpg"],
      },
      autoProceed: false,
    })
      .use(ImageEditor, {
        quality: 0.8,
        cropperOptions: {
          aspectRatio: 1,
          viewMode: 1,
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: false,
          cropWidescreenVertical: false,
        },
      })
      .use(Tus, {
        endpoint: UPLOAD_URL,
        storeFingerprintForResuming: false,
        fingerprint: async (file, options) => {
          return Math.random().toString(36);
        },
        removeFingerprintOnSuccess: true,
        chunkSize: 1024 * 1024,
        allowedMetaFields: [
          "bucketName",
          "objectName",
          "contentType",
          "cacheControl",
        ],
      });
    uppy.addPreProcessor(async (file) => {
      // This is super hacky. Ideally Tus would have a headers function that could return a promise (it'd current sync). There is no API at this time to async update the headers.
      // This is done on every file, but probably only needs to be done once per batch. It works for now, but is a hack and should be fixed if Tus is updated.
      const accessToken = await getAccessToken();
      uppy.getPlugin("Tus")?.setOptions({
        headers: {
          authorization: `Bearer ${accessToken}`,
          "x-upsert": "true",
        },
      });
    });
    uppy.on("complete", async (result) => {
      if (result.successful && result.successful[0]) {
        await uploadProfileImage();
        setCurrentProfileImage(null);
        queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });
        setShowUploader(false);
        uppyInstance.clear();
      }
    });
    uppy.opts.onBeforeUpload = (files) => {
      return Object.fromEntries(
        Object.entries(files).map(
          ([fileId, file]: [fileId: string, file: any]) => {
            const path = String(userId);

            file.meta = {
              ...file.meta,
              bucketName: UPLOAD_BUCKET_NAME,
              objectName: path,
              contentType: file.type,
            };

            return [file.id, file];
          },
        ),
      );
    };
    return uppy;
  });

  const AvatarComponent = (
    <Avatar className={`h-16 w-16 ${editable ? "cursor-pointer" : ""}`}>
      {currentProfileImage ? (
        <AvatarImage
          src={currentProfileImage}
          alt={firstName ? `${firstName} ${lastName || ""}` : "Profile"}
        />
      ) : (
        <AvatarFallback className="bg-gray-700">
          <Camera className="h-6 w-6 text-white" />
        </AvatarFallback>
      )}
    </Avatar>
  );

  if (!editable) {
    return <div className="relative">{AvatarComponent}</div>;
  }

  return (
    <div className="relative group">
      <button
        className="block w-full"
        onClick={() => setShowUploader(true)}
        aria-label="Edit profile picture"
      >
        {AvatarComponent}

        <div className="absolute inset-0 flex items-center justify-center bg-black/50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer">
          <Pencil className="h-6 w-6 text-white" aria-hidden="true" />
          <span className="sr-only">Edit profile picture</span>
        </div>
      </button>

      {showUploader && (
        <DashboardModal
          uppy={uppyInstance}
          open={showUploader}
          onRequestClose={() => setShowUploader(false)}
        />
      )}
    </div>
  );
};
