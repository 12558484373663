import { callSupabaseFnFromClient } from "../utils/callSupabaseFnFromClient";
export type ReferralType = {
  id: string;
  email: string;
  status_name: string;
  status_id: number;
  paid: boolean;
  referred_at: string;
};

export const getReferrals = async (): Promise<ReferralType[]> => {
  return await callSupabaseFnFromClient<ReferralType[]>({
    fnName: "get_user_referrals",
  });
};
