import React from "react";
import { Link, useRouterState } from "@tanstack/react-router";

type SidebarItemProps = {
  item: {
    icon: React.ReactNode;
    label: string;
    route?: string;
  };
  notificationCount?: number;
  onClick?: () => void;
};

const SidebarItem = ({
  item,
  notificationCount = 0,
  onClick,
}: SidebarItemProps) => {
  const router = useRouterState();

  const isActive = (item: SidebarItemProps["item"]) => {
    if (item.route && router.location.pathname.startsWith(item.route))
      return true;
    return false;
  };

  const isItemActive = isActive(item);

  return (
    <Link
      to={item.route}
      onClick={onClick}
      className={`flex items-center gap-3 rounded-lg px-3 py-2 text-lg transition-all mb-1 ${isItemActive ? "bg-primary text-white" : "hover:text-primary"}`}
    >
      <div className="relative">
        {item.icon}
        {notificationCount > 0 && (
          <div className="absolute -top-1 -right-1 min-w-4 h-4 bg-red-500 rounded-full flex items-center justify-center text-[10px] text-white px-[2px]">
            {notificationCount}
          </div>
        )}
      </div>
      {item.label}
    </Link>
  );
};

export default SidebarItem;
