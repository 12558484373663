import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getUsers } from "@/ajax/users/getUsers";
import { queryClient } from "@/ajax/queryClient";
import { getBusinessUnits } from "@/ajax/organizations/getBusinessUnits";
import ReactSelect from "react-select";
import AdminChat from "@/features/chat/admin/AdminChat";
import { useTRPC } from "@/trpc";

export const Route = createFileRoute("/_admin/admin/chat/")({
  component: () => {
    const trpc = useTRPC();
    const [open, setOpen] = useState(false);
    const [selectedParticipants, setSelectedParticipants] = useState<
      Array<{ id: string; type: "user" | "organization" }>
    >([]);
    const [participantTypes, setParticipantTypes] = useState<
      Array<"user" | "organization" | null>
    >([null, null]);

    const { data: users } = useQuery({
      queryKey: ["users", ""],
      queryFn: () => getUsers({ search: "" }),
    });
    

    const { data: businessUnits } = useQuery({
      queryKey: ["allBusinessUnits"],
      queryFn: () => getBusinessUnits(), // TODO: Replace with actual org fetch
    });

    const createChatMutation = useMutation(trpc.chat.createChatThread.mutationOptions(
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["adminChatThreads"] });
          setOpen(false);
          setSelectedParticipants([]);
          setParticipantTypes([null, null]);
        },
      }
    ));

    const userOptions =
      users?.map((user) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name} (${user.email})`,
      })) || [];

    const businessUnitOptions =
      businessUnits?.map((org) => ({
        value: org.id,
        label: `${org.name} (${org.organization_name})`,
      })) || [];

    const handleCreateChat = async () => {
      if (selectedParticipants.length !== 2) return;

      const userIds = selectedParticipants
          .filter((p) => p.type === "user")
          .map((p) => p.id);
        const organizationBusinessUnitIds = selectedParticipants
        .filter((p) => p.type === "organization")
        .map((p) => p.id);
      createChatMutation.mutate({
        userIds,
        organizationBusinessUnitIds,
      });
    };

    const handleCancel = () => {
      setOpen(false);
      setSelectedParticipants([]);
      setParticipantTypes([null, null]);
    };

    return (
      <div className="h-full flex flex-col">
        <div className="p-4 border-b">
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
              <Button>Create New Chat</Button>
            </DialogTrigger>
            <DialogContent className="min-w-[28rem] sm:min-w-[32rem]">
              <DialogHeader>
                <DialogTitle>Create New Chat</DialogTitle>
              </DialogHeader>
              <div className="space-y-6">
                <div className="border rounded-lg p-4">
                  <h3 className="font-medium mb-3">First Participant</h3>
                  <div className="space-y-3">
                    <div>
                      <label className="text-sm font-medium">Type</label>
                      <Select
                        value={participantTypes[0] || undefined}
                        onValueChange={(value: "user" | "organization") => {
                          setParticipantTypes([value, participantTypes[1]]);
                          setSelectedParticipants([]);
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select type..." />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="user">User</SelectItem>
                          <SelectItem value="organization">
                            Organization
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    {participantTypes[0] && (
                      <div>
                        <label className="text-sm font-medium">
                          Select Participant
                        </label>
                        <ReactSelect
                          value={
                            selectedParticipants[0]?.id
                              ? {
                                  value: selectedParticipants[0].id,
                                  label:
                                    participantTypes[0] === "user"
                                      ? userOptions.find(
                                          (u) =>
                                            u.value ===
                                            selectedParticipants[0].id,
                                        )?.label
                                      : businessUnitOptions.find(
                                          (o) =>
                                            o.value ===
                                            selectedParticipants[0].id,
                                        )?.label,
                                }
                              : null
                          }
                          classNames={{
                            control: (state) => "py-2",
                          }}
                          onChange={(option) => {
                            if (!option) return;
                            setSelectedParticipants(
                              [
                                {
                                  id: option.value,
                                  type: participantTypes[0]!,
                                },
                                selectedParticipants[1],
                              ].filter(Boolean),
                            );
                          }}
                          options={
                            participantTypes[0] === "user"
                              ? userOptions
                              : businessUnitOptions
                          }
                          className="mt-1"
                          placeholder="Select participant..."
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="border rounded-lg p-4">
                  <h3 className="font-medium mb-3">Second Participant</h3>
                  <div className="space-y-3">
                    <div>
                      <label className="text-sm font-medium">Type</label>
                      <Select
                        value={participantTypes[1] || undefined}
                        onValueChange={(value: "user" | "organization") => {
                          setParticipantTypes([participantTypes[0], value]);
                          setSelectedParticipants([selectedParticipants[0]]);
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select type..." />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="user">User</SelectItem>
                          <SelectItem value="organization">
                            Organization
                          </SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    {participantTypes[1] && (
                      <div>
                        <label className="text-sm font-medium">
                          Select Participant
                        </label>
                        <ReactSelect
                          value={
                            selectedParticipants[1]?.id
                              ? {
                                  value: selectedParticipants[1].id,
                                  label:
                                    participantTypes[1] === "user"
                                      ? userOptions.find(
                                          (u) =>
                                            u.value ===
                                            selectedParticipants[1].id,
                                        )?.label
                                      : businessUnitOptions.find(
                                          (o) =>
                                            o.value ===
                                            selectedParticipants[1].id,
                                        )?.label,
                                }
                              : null
                          }
                          classNames={{
                            control: (state) => "py-2",
                          }}
                          onChange={(option) => {
                            if (!option) return;
                            setSelectedParticipants(
                              [
                                selectedParticipants[0],
                                {
                                  id: option.value,
                                  type: participantTypes[1]!,
                                },
                              ].filter(Boolean),
                            );
                          }}
                          options={
                            participantTypes[1] === "user"
                              ? userOptions
                              : businessUnitOptions
                          }
                          className="mt-1"
                          placeholder="Select participant..."
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex gap-4">
                  <Button
                    variant="outline"
                    className="flex-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="flex-1"
                    disabled={
                      selectedParticipants.length !== 2 ||
                      createChatMutation.isPending
                    }
                    onClick={handleCreateChat}
                  >
                    {createChatMutation.isPending
                      ? "Creating..."
                      : "Create Chat"}
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div className="flex-1 relative">
          <AdminChat />
        </div>
      </div>
    );
  },
});
