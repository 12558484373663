import { createFileRoute } from "@tanstack/react-router";
import React from "react";
import {
  Search,
  Edit2,
  Trash2,
  Plus,
  Clock,
  DollarSign,
  Users,
  FileText,
} from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";

// Mock data
const mockJobs = [
  {
    id: 1,
    title: "Urgent Care Physician",
    location: "CityHealth Urgent Care - Oakland",
    status: "Published",
    postedDate: "Mar 15, 2025",
    hourlyRate: 225,
    interestedProviders: 12,
    specialty: "Urgent Care",
  },
  {
    id: 2,
    title: "Emergency Medicine Physician",
    location: "CityHealth Urgent Care - San Francisco",
    status: "Contracting",
    postedDate: "Mar 10, 2025",
    hourlyRate: 250,
    contractStatus: "Contract in Review",
    specialty: "Emergency Medicine",
  },
  {
    id: 3,
    title: "Primary Care Physician",
    location: "CityHealth Urgent Care - Berkeley",
    status: "Draft",
    lastEdited: "Mar 5, 2025",
    hourlyRate: 200,
    specialty: "Primary Care",
  },
];

const JobsPage = () => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [statusFilter, setStatusFilter] = React.useState("All Status");
  const [specialtyFilter, setSpecialtyFilter] =
    React.useState("All Specialties");

  const getStatusBadgeColor = (status: string) => {
    switch (status) {
      case "Published":
        return "bg-emerald-100 text-emerald-800 border-emerald-200";
      case "Contracting":
        return "bg-blue-100 text-blue-800 border-blue-200";
      case "Draft":
        return "bg-gray-100 text-gray-800 border-gray-200";
      default:
        return "bg-gray-100 text-gray-800 border-gray-200";
    }
  };

  return (
    <div className="p-8 max-w-7xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold tracking-tight">Job Postings</h1>
        <Button className="bg-blue-600 hover:bg-blue-700 shadow-sm">
          <Plus className="mr-2 h-4 w-4" />
          Create New Posting
        </Button>
      </div>

      <div className="flex gap-4 mb-8">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
          <Input
            placeholder="Search postings..."
            className="pl-10 border-gray-200"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <Select value={specialtyFilter} onValueChange={setSpecialtyFilter}>
          <SelectTrigger className="w-48 border-gray-200">
            <SelectValue placeholder="All Specialties" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="All Specialties">All Specialties</SelectItem>
            <SelectItem value="Primary Care">Primary Care</SelectItem>
            <SelectItem value="Emergency Medicine">
              Emergency Medicine
            </SelectItem>
            <SelectItem value="Urgent Care">Urgent Care</SelectItem>
          </SelectContent>
        </Select>
        <Select value={statusFilter} onValueChange={setStatusFilter}>
          <SelectTrigger className="w-48 border-gray-200">
            <SelectValue placeholder="All Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="All Status">All Status</SelectItem>
            <SelectItem value="Published">Published</SelectItem>
            <SelectItem value="Contracting">Contracting</SelectItem>
            <SelectItem value="Draft">Draft</SelectItem>
          </SelectContent>
        </Select>
      </div>

      <div className="space-y-4">
        {mockJobs.map((job) => (
          <Card
            key={job.id}
            className="relative border-gray-200 shadow-sm hover:shadow-md transition-shadow"
          >
            <CardContent className="p-6">
              <div className="flex justify-between items-start">
                <div className="space-y-4">
                  <div>
                    <div className="flex items-center gap-3 mb-2">
                      <h2 className="text-xl font-semibold">{job.title}</h2>
                      <Badge
                        className={`${getStatusBadgeColor(job.status)} border`}
                      >
                        {job.status}
                      </Badge>
                    </div>
                    <p className="text-gray-600">{job.location}</p>
                  </div>

                  <div className="flex gap-6 text-sm text-gray-600">
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4 text-gray-400" />
                      {job.postedDate ? (
                        <span>Posted {job.postedDate}</span>
                      ) : (
                        <span>Edited {job.lastEdited}</span>
                      )}
                    </div>
                    <div className="flex items-center">
                      <DollarSign className="h-4 w-4 text-gray-400" />
                      <span>{job.hourlyRate}/hour</span>
                    </div>
                    {job.interestedProviders && (
                      <div className="flex items-center gap-2">
                        <Users className="h-4 w-4 text-gray-400" />
                        <span>{job.interestedProviders} interested</span>
                      </div>
                    )}
                    {job.contractStatus && (
                      <div className="flex items-center gap-2">
                        <FileText className="h-4 w-4 text-gray-400" />
                        <span>{job.contractStatus}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex gap-2">
                  <Button
                    variant="ghost"
                    size="icon"
                    className="hover:bg-gray-100"
                  >
                    <Edit2 className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="hover:bg-red-50 hover:text-red-600"
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </div>
              </div>

              {job.status === "Published" && (
                <div className="mt-4 pt-4 border-t border-gray-100">
                  <Button
                    variant="link"
                    className="px-0 text-blue-600 hover:text-blue-800 font-medium"
                  >
                    View Interested Providers →
                  </Button>
                </div>
              )}
              {job.status === "Contracting" && (
                <div className="mt-4 pt-4 border-t border-gray-100">
                  <Button
                    variant="link"
                    className="px-0 text-blue-600 hover:text-blue-800 font-medium"
                  >
                    View Contract Status →
                  </Button>
                </div>
              )}
              {job.status === "Draft" && (
                <div className="mt-4 pt-4 border-t border-gray-100">
                  <Button
                    variant="link"
                    className="px-0 text-blue-600 hover:text-blue-800 font-medium"
                  >
                    Complete & Publish →
                  </Button>
                </div>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export const Route = createFileRoute("/_org/org/jobs/")({
  component: JobsPage,
});
