import React from "react";
import { Link, useRouterState } from "@tanstack/react-router";

type BottomBarItemProps = {
  item: {
    icon: React.ReactNode;
    label: string;
    route: string;
  };
  notificationCount?: number;
  onClick?: () => void;
  isSelected?: boolean;
};

const BottomBarItem = ({
  item,
  notificationCount = 0,
  onClick,
  isSelected,
}: BottomBarItemProps) => {
  const router = useRouterState();
  const isActive = router.location.pathname.startsWith(item.route);
  return (
    <Link
      to={item.route}
      onClick={onClick}
      className={`flex flex-col items-center justify-center w-full h-full transition-colors ${
        isActive
          ? "text-primary border-t-[3px] border-primary bg-primary/5"
          : "text-gray-600 hover:text-primary/80 hover:bg-primary/5 active:bg-primary/10"
      }`}
    >
      <div className="relative">
        {React.isValidElement(item.icon)
          ? React.cloneElement(item.icon as React.ReactElement, {
              className: `h-6 w-6 ${isSelected ? "stroke-[2.5px]" : ""}`,
            })
          : item.icon}
        {notificationCount > 0 && (
          <div className="absolute -top-1.5 -right-1.5 min-w-4 h-4 px-1 bg-red-500 rounded-full shadow-sm shadow-red-300 flex items-center justify-center text-[10px] font-bold text-white">
            {notificationCount}
          </div>
        )}
      </div>
      <span className={`text-xs mt-1.5 ${isSelected ? "font-semibold" : ""}`}>
        {item.label}
      </span>
    </Link>
  );
};

export default BottomBarItem;
