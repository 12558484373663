export enum ShiftContractDuration {
  OCCASIONAL_SHIFTS = 1,
  CONTRACT_6_TO_24_MONTHS = 2,
  PERMANENT = 3,
}
export const shiftContractDurationOptions = [
  { value: null, label: "Any duration" },
  {
    value: ShiftContractDuration.OCCASIONAL_SHIFTS,
    label: "Occasional Shifts",
  },
  {
    value: ShiftContractDuration.CONTRACT_6_TO_24_MONTHS,
    label: "Contract (6-24 months)",
  },
  { value: ShiftContractDuration.PERMANENT, label: "Permanent" },
];

export const shiftContractDurationLabels = {
  [ShiftContractDuration.OCCASIONAL_SHIFTS]: "Occasional Shifts",
  [ShiftContractDuration.CONTRACT_6_TO_24_MONTHS]: "Contract (6-24 months)",
  [ShiftContractDuration.PERMANENT]: "Permanent",
};
