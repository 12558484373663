import { createFileRoute, Link } from "@tanstack/react-router";
import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CitySelect } from "@/components/Selects/CitySelect";
import { SpecialtiesSelect } from "@/components/Selects/SpecialtiesSelect";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@radix-ui/react-popover";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery } from "@tanstack/react-query";
import { shiftContractDurationOptions } from "@/common/enums/ShiftContractDuration";
import { adminSearchProviders } from "@/ajax/provider_search/adminSearchProviders";

const searchFormSchema = z.object({
  specialty: z.string(),
  city: z.array(
    z.object({
      name: z.string(),
      lat: z.number().optional(),
      lng: z.number().optional(),
    }),
  ),
  distance: z.string().min(1, "Please select a distance"),
  shiftDuration: z.string(),
});

type SearchFormData = z.infer<typeof searchFormSchema>;

const shiftDurationOptions = shiftContractDurationOptions.map((option) => ({
  value: option.value ? String(option.value) : "0",
  label: option.label,
}));

function ProviderSearch() {
  const navigate = Route.useNavigate();
  const search = Route.useSearch();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<SearchFormData>({
    resolver: zodResolver(searchFormSchema),
    defaultValues: {
      specialty: search.specialty || "",
      city: search.cityName
        ? [
            {
              name: search.cityName || "",
              lat: Number(search.lat) || undefined,
              lng: Number(search.lng) || undefined,
            },
          ]
        : [],
      distance: search.distance || "100",
      shiftDuration: search.shiftDuration || "0",
    },
  });

  const [searchParams, setSearchParams] = useState<{
    lat?: number;
    lng?: number;
    specialtyCode: string;
    distanceMiles?: number;
    shiftDuration?: string;
  } | null>(() => {
    if (search.lat && search.lng && search.specialty && search.distance) {
      return {
        lat: Number(search.lat),
        lng: Number(search.lng),
        specialtyCode: search.specialty,
        distanceMiles: Number(search.distance),
        shiftDuration: search.shiftDuration,
      };
    }
    return null;
  });

  const { data: providers = [], isLoading } = useQuery({
    queryKey: ["providers", searchParams],
    queryFn: () => {
      if (!searchParams) return Promise.resolve([]);
      return adminSearchProviders({
        ...searchParams,
        contractDurationId:
          searchParams.shiftDuration && searchParams.shiftDuration !== "0"
            ? parseInt(searchParams.shiftDuration)
            : undefined,
      });
    },
    enabled: !!searchParams,
  });

  const distanceOptions = [
    { value: "50", label: "50 miles" },
    { value: "100", label: "100 miles" },
    { value: "300", label: "300 miles" },
  ];

  const onSubmit = (data: SearchFormData) => {
    const city = data.city[0];
    const lat = city?.lat;
    const lng = city?.lng;

    const params = {
      lat,
      lng,
      specialtyCode: data.specialty,
      distanceMiles: parseInt(data.distance),
      shiftDuration: data.shiftDuration,
    };

    setSearchParams(params);

    const searchParams: {
      lat?: string;
      lng?: string;
      cityName?: string;
      specialty: string;
      distance: string;
      shiftDuration: string;
    } = {
      specialty: data.specialty,
      distance: data.distance,
      shiftDuration: data.shiftDuration,
    };

    if (lat && lng && city) {
      searchParams.lat = String(lat);
      searchParams.lng = String(lng);
      searchParams.cityName = city.name;
    }

    navigate({
      // @ts-ignore
      search: searchParams,
    });
  };

  return (
    <div className="container mx-auto py-8 px-4">
      <Card className="p-6">
        <h1 className="text-2xl font-bold mb-6">Find Healthcare Providers</h1>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
            <div className="md:col-span-4">
              <label className="block text-sm font-medium mb-2">
                Specialties
              </label>
              <SpecialtiesSelect
                value={watch("specialty")}
                onChange={(value) =>
                  setValue("specialty", value, { shouldValidate: true })
                }
              />
              {errors.specialty && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.specialty.message}
                </p>
              )}
            </div>

            <div className="md:col-span-4">
              <label className="block text-sm font-medium mb-2">City</label>
              <CitySelect
                value={watch("city")}
                onChange={(value) =>
                  setValue("city", value, { shouldValidate: true })
                }
              />
              {errors.city && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.city.message}
                </p>
              )}
            </div>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium mb-2">
                Shift Duration
              </label>
              <Select
                value={watch("shiftDuration")}
                onValueChange={(value) => setValue("shiftDuration", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Any duration" />
                </SelectTrigger>
                <SelectContent>
                  {shiftDurationOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="md:col-span-2">
              <label className="block text-sm font-medium mb-2">Distance</label>
              <Select
                value={watch("distance")}
                onValueChange={(value) =>
                  setValue("distance", value, { shouldValidate: true })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select distance" />
                </SelectTrigger>
                <SelectContent>
                  {distanceOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              {errors.distance && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.distance.message}
                </p>
              )}
            </div>
          </div>

          <Button type="submit" className="w-full py-8">
            Search Providers
          </Button>
        </form>

        <div className="mt-8">
          {isLoading ? (
            <div className="text-center py-8">Loading...</div>
          ) : !searchParams ? (
            <div className="text-center py-8">
              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                Enter Search Parameters
              </h3>
              <p className="text-gray-500">
                Select a specialty, city, and distance above to find providers
              </p>
            </div>
          ) : providers.length > 0 ? (
            <div className="space-y-4">
              {providers.map((provider) => (
                <Card key={provider.user_id} className="p-4">
                  <div className="flex flex-col md:flex-row justify-between items-start gap-4">
                    <div>
                      <h3 className="text-lg font-semibold">
                        {provider.first_name} {provider.last_name}
                      </h3>
                      <p>{provider.specialty_name}</p>
                      {provider.organizations &&
                        provider.organizations.length > 0 && (
                          <p className="text-sm">
                            <span className="text-gray-500">Orgs:</span>{" "}
                            {provider.organizations.map((org, i, arr) => (
                              <span key={i}>
                                {org}
                                {i < arr.length - 1 && " • "}
                              </span>
                            ))}
                          </p>
                        )}
                      <p className="text-sm">
                        <span className="text-gray-500">
                          Preferred locations:
                        </span>{" "}
                        {provider.locations
                          .filter((l) => l.name !== "ANY_CITY")
                          .map((l, i, arr) => (
                            <span key={l.name}>
                              {l.name}
                              {i < arr.length - 1 && " • "}
                            </span>
                          ))}
                        {provider.locations.some(
                          (l) => l.name === "ANY_CITY",
                        ) && (
                          <span className="text-gray-500">
                            {" "}
                            (open to other cities)
                          </span>
                        )}
                      </p>

                      <div className="flex flex-col gap-1">
                        <p className="text-sm">
                          <span className="text-gray-500">Looking for:</span>{" "}
                          <Popover>
                            <PopoverTrigger>
                              <span className="font-bold underline decoration-dotted cursor-help">
                                {provider.contract_duration || "Any Duration"}
                              </span>
                            </PopoverTrigger>
                            <PopoverContent className="w-64 rounded-md bg-white p-4 shadow-md border border-gray-200">
                              <div className="flex gap-2 items-center">
                                <p className="text-sm text-gray-600">
                                  This provider's availability preference may
                                  change. Please confirm during outreach.
                                </p>
                              </div>
                            </PopoverContent>
                          </Popover>
                        </p>
                        <p className="text-sm">
                          <span className="text-gray-500">Facility types:</span>{" "}
                          {!provider.facility_type_names ||
                          provider.facility_type_names.length === 0
                            ? "Any Facility"
                            : provider.facility_type_names.map(
                                (type, i, arr) => (
                                  <span key={i}>
                                    {type}
                                    {i < arr.length - 1 && " • "}
                                  </span>
                                ),
                              )}
                        </p>
                        {provider.availability_description && (
                          <p className="text-sm">
                            <span className="text-gray-500">Availability:</span>{" "}
                            {provider.availability_description}
                          </p>
                        )}
                        <p className="text-sm">
                          <span className="text-gray-500">Min Wage:</span>{" "}
                          {!provider.minimum_rate || provider.minimum_rate === 0
                            ? "No minimum"
                            : `$${provider.minimum_rate}/hr`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col md:flex-row gap-2 w-full md:w-auto">
                      <Link
                        to="/admin/provider-profile/$userId"
                        params={{ userId: provider.user_id }}
                        className="w-full md:w-auto"
                      >
                        <Button className="w-full">
                          View Provider Profile
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          ) : (
            <div className="text-center py-8">
              <h3 className="text-lg font-semibold text-gray-700 mb-2">
                No providers found
              </h3>
              <p className="text-gray-500 mb-4">
                Try adjusting your search criteria:
              </p>
              <ul className="text-gray-600 space-y-2">
                <li>• Expand your search radius</li>
                <li>• Try different specialties</li>
                <li>• Search in nearby cities</li>
              </ul>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export const Route = createFileRoute("/_admin/admin/provider-search/")({
  component: ProviderSearch,
  validateSearch: (search: Record<string, string>) => ({
    lat: search.lat,
    lng: search.lng,
    cityName: search.cityName,
    specialty: search.specialty,
    distance: search.distance,
    shiftDuration: search.shiftDuration,
  }),
});
