import { LiveUpdate } from "@capawesome/capacitor-live-update";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { router } from "@/router";
import { getEnvVar } from "./environment";

const LIVE_UPDATE_CHANNEL = getEnvVar("VITE_PUBLIC_LIVE_UPDATE_CHANNEL");

export async function initializeApp() {
  // Check if it's not web
  if (Capacitor.getPlatform() === "web") {
    return;
  }

  if (LIVE_UPDATE_CHANNEL !== "production ") {
    console.log("Live update channel", LIVE_UPDATE_CHANNEL);
    await LiveUpdate.reset();
  }

  // Register event to fire each time user resumes the app
  App.addListener("resume", async () => {
    try {
      if (localStorage.shouldReloadApp === "true") {
        console.log("Reloading app on resume");
        localStorage.shouldReloadApp = false;
        await LiveUpdate.reload();
      } else {
        const result = await LiveUpdate.sync({
          channel: LIVE_UPDATE_CHANNEL,
        });
        console.log("Syncing app", result.nextBundleId);
        localStorage.shouldReloadApp = result.nextBundleId != null;
      }
    } catch (error) {
      // If offline or error occurs, continue without sync
      console.log("Failed to sync updates on resume:", error);
    }
  });

  App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
    const path = event.url.split(".com").pop();
    console.log("Universal link", path);
    router.navigate({
      to: path,
    });
  });

  const result = await LiveUpdate.sync({
    channel: LIVE_UPDATE_CHANNEL,
  });
  if (result.nextBundleId) {
    console.log("Reloading app", result.nextBundleId);
    await LiveUpdate.reload();
  }

  await LiveUpdate.ready();
}
