import { useQuery } from "@tanstack/react-query";
import { useTRPC } from "@/trpc";

/**
 * Hook to fetch business units that a user belongs to
 */
export const useUserBusinessUnits = () => {
  const trpc = useTRPC();
  return useQuery(trpc.organization.getUserBusinessUnits.queryOptions());
};
