import { getEnvVar } from "@/common/utils/environment";
import { createClient } from "@supabase/supabase-js";
import { Capacitor } from "@capacitor/core";
import { debounce } from "@/common/utils/debounce";
import { Storage } from "@/common/utils/storage";
import { Database } from "@/common/types/database.types";
export const STORAGE_KEY = "sb.auth.token";

const REMEMBER_ME_TIME = 7 * 24 * 60 * 60 * 1000;
const createSupabaseClient = () => {
  const expiryMinutes = parseInt(
    getEnvVar("VITE_PUBLIC_TOKEN_EXPIRY_MINUTES") || "240",
  );

  const isNative = Capacitor.isNativePlatform();

  const client = createClient<Database>(
    getEnvVar("VITE_PUBLIC_SUPABASE_URL"),
    getEnvVar("VITE_PUBLIC_SUPABASE_ANON_KEY"),
    {
      auth: {
        storageKey: STORAGE_KEY,
        storage: {
          getItem: (key) => {
            const item = Storage.getItem(key);
            if (!item) return null;
            const rememberMe =
              Storage.getItem("remember_me_enabled") === "true";

            const { value, timestamp } = JSON.parse(item);

            const expiryTime = rememberMe
              ? REMEMBER_ME_TIME
              : expiryMinutes * 60 * 1000;

            if (
              !isNative &&
              expiryMinutes > 0 &&
              Date.now() - timestamp > expiryTime
            ) {
              supabase.auth.signOut();
            }

            return value;
          },
          setItem: (key, value) => {
            const item = JSON.stringify({
              value,
              timestamp: Date.now(),
            });
            return Storage.setItem(key, item);
          },
          removeItem: (key) => {
            return Storage.removeItem(key);
          },
        },
      },
    },
  );
  return client;
};

export const supabase = createSupabaseClient();

export const getAccessToken = async (): Promise<string | null> => {
  const token = await supabase.auth.getSession();

  return token.data.session?.access_token ?? null;
};
// Debounced function to update token expiry timestamp on user interaction
const updateTokenExpiry = debounce(() => {
  const token = Storage.getItem(STORAGE_KEY);
  if (token) {
    const parsed = JSON.parse(token);
    parsed.timestamp = Date.now();
    Storage.setItem(STORAGE_KEY, JSON.stringify(parsed));
  }
}, 1000);

// Add event listeners for user interaction
["click", "keydown", "mousemove", "touchstart"].forEach((eventType) => {
  document.addEventListener(eventType, updateTokenExpiry, { passive: true });
});
