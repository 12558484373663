import { Users, Search, Building2 } from "lucide-react";

export const OrgPitchContent = () => (
  <>
    <h1 className="text-5xl font-bold mb-8 animate-fade-in text-center">
      Streamline Staffing with <span className="text-primary-light">Saile</span>
    </h1>
    <div className="space-y-8">
      <div className="flex items-start space-x-4 p-4 rounded-lg bg-primary-dark/30 hover:bg-primary-dark/40 transition-all duration-300 animate-fade-in-delay-1">
        <div className="flex-shrink-0 mt-1 bg-primary-light/20 p-3 rounded-full">
          <Search className="w-7 h-7" />
        </div>
        <div>
          <h3 className="font-semibold text-2xl mb-2 text-primary-light">
            Access Provider Profiles
          </h3>
          <p className="text-gray-100 leading-relaxed">
            Get instant access to comprehensive provider profiles with verified
            credentials, up-to-date licenses, and real-time availability status.
            Make informed decisions quickly.
          </p>
        </div>
      </div>
      <div className="flex items-start space-x-4 p-4 rounded-lg bg-primary-dark/30 hover:bg-primary-dark/40 transition-all duration-300 animate-fade-in-delay-2">
        <div className="flex-shrink-0 mt-1 bg-primary-light/20 p-3 rounded-full">
          <Building2 className="w-7 h-7" />
        </div>
        <div>
          <h3 className="font-semibold text-2xl mb-2 text-primary-light">
            Fill Open Shifts Instantly
          </h3>
          <p className="text-gray-100 leading-relaxed">
            Say goodbye to staffing headaches. Connect with qualified healthcare
            providers in your area and fill shifts with just a few clicks.
            Streamline your scheduling process.
          </p>
        </div>
      </div>
      <div className="flex items-start space-x-4 p-4 rounded-lg bg-primary-dark/30 hover:bg-primary-dark/40 transition-all duration-300 animate-fade-in-delay-3">
        <div className="flex-shrink-0 mt-1 bg-primary-light/20 p-3 rounded-full">
          <Users className="w-7 h-7" />
        </div>
        <div>
          <h3 className="font-semibold text-2xl mb-2 text-primary-light">
            Powerful Team Management
          </h3>
          <p className="text-gray-100 leading-relaxed">
            Empower your team with role-based access, streamlined collaboration
            tools, and efficient provider management. Keep everyone aligned and
            productive.
          </p>
        </div>
      </div>
    </div>
  </>
);
