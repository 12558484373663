import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export const archiveChatThread = async ({
  threadId,
  switchUserId,
}: {
  threadId: string;
  switchUserId?: string;
}) => {
  return callSupabaseFnFromClient<void>({
    fnName: "archive_chat_thread",
    args: { thread_id: threadId, switch_user_id: switchUserId },
  });
};
