import { fileTypesQueryOptions } from "@/ajax/queries";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Input } from "../ui/input";
import Select from "react-select";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Button } from "../ui/button";
import { DialogFooter } from "../ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { updateFile } from "@/ajax/documents/updateFile";
import { useState } from "react";
import { queryClient } from "@/ajax/queryClient";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "lucide-react";
import { format, parse } from "date-fns";
import { Switch } from "../ui/switch";

type File = {
  generated_title: string | null;
  file_name: string;
  id: string;
  type_id: number;
  type_name: string;
  expires_at: string | null;
  expiry_notifications_enabled: boolean;
};

export function FilePreviewEdit({
  file,
  onClose,
  userId,
}: {
  file: File;
  onClose: () => void;
  userId: string;
}) {
  const [error, setError] = useState<string | null>(null);
  const fileTypesQueryState = useSuspenseQuery(fileTypesQueryOptions());
  const fileTypes = fileTypesQueryState.data.map((type) => ({
    value: String(type.id),
    label: type.name,
  }));

  const form = useForm({
    defaultValues: {
      title: file.generated_title || file.file_name,
      type: {
        value: String(file.type_id),
        label: file.type_name,
      },
      expires_at: file.expires_at ? new Date(file.expires_at) : null,
      notify_expiry: file.expiry_notifications_enabled,
    },
  });

  const updateFileMutation = useMutation({
    mutationFn: updateFile,
    onSuccess: () => {
      setError(null);
      queryClient.invalidateQueries({
        queryKey: ["files"],
      });
      onClose();
    },
    onError: (error) => {
      setError("An error occurred while updating the file. Please try again.");
    },
  });

  const onSubmit = (values: any) => {
    updateFileMutation.mutate({
      id: file.id,
      switchUserId: userId,
      title: values.title,
      typeId: values.type.value,
      // @ts-ignore
      expiresAt: values.expires_at
        ? format(values.expires_at, "yyyy-MM-dd")
        : null,
      expiryNotificationsEnabled: values.notify_expiry,
    });
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onClose();
    }
  };

  return (
    <Dialog open={true} onOpenChange={handleOpenChange}>
      <DialogContent className="min-w-[400px]">
        <DialogHeader>
          <DialogTitle>Edit Document</DialogTitle>
          <DialogDescription>
            Edit the title and type of your document.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <Select
                      options={fileTypes}
                      {...field}
                      classNames={{
                        control: (state) => "py-2",
                      }}
                      isClearable
                      placeholder="Select a category"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="expires_at"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel>Expiration Date</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <DatePicker
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        customInput={
                          <Input
                            value={
                              field.value
                                ? format(field.value, "MM-dd-yyyy")
                                : ""
                            }
                            onChange={(e) => {
                              const date = parse(
                                e.target.value,
                                "MM-dd-yyyy",
                                new Date(),
                              );
                              if (
                                date instanceof Date &&
                                !isNaN(date.getTime())
                              ) {
                                field.onChange(date);
                              }
                            }}
                          />
                        }
                        dateFormat="MM-dd-yyyy"
                        wrapperClassName="w-full"
                      />
                      <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {form.watch("expires_at") && (
              <FormField
                control={form.control}
                name="notify_expiry"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center rounded-lg border p-4">
                    <FormControl className="mr-4">
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-0.5">
                      <FormLabel className="text-base">
                        Expiry Notifications
                      </FormLabel>
                      <FormDescription>
                        Get notified when this document is about to expire
                      </FormDescription>
                    </div>
                  </FormItem>
                )}
              />
            )}
            {error && <div className="text-red-500">{error}</div>}
            <DialogFooter className="flex sm:flex-row gap-2 sm:gap-0">
              <Button type="submit" disabled={updateFileMutation.isPending}>
                {updateFileMutation.isPending ? "Saving..." : "Save changes"}
              </Button>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
