import { createFileRoute, Link } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useState } from "react";
import { useProviderUser } from "@/hooks/useProviderUser";
import {
  Share2,
  UserPlus,
  DollarSign,
  InfoIcon,
  RefreshCw,
} from "lucide-react";
import { format } from "date-fns";
import { useSuspenseQuery, useMutation } from "@tanstack/react-query";
import {
  createReferralLinkQueryOptions,
  referralsQueryOptions,
} from "@/ajax/queries";
import { queryClient } from "@/ajax/queryClient";
import { ReferralType } from "@/ajax/referrals/getReferrals";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ExternalLink } from "@/components/Button/ExternalLink";
import { getEnvVar } from "@/common/utils/environment";
import { Contract } from "@/features/onboarding/enums/Contract";
import { acceptContract } from "@/ajax/contracts/acceptContract";
import { PROVIDER_USER_QUERY_KEY } from "@/ajax/queries";
const MARKETING_URL = getEnvVar("MARKETING_URL") || "https://www.saileapp.com";

function ReferralsPage() {
  const [copied, setCopied] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const user = useProviderUser();

  const { data: referrals, refetch: refetchReferrals } = useSuspenseQuery(
    referralsQueryOptions(),
  );
  const { data: referralLink } = useSuspenseQuery(
    createReferralLinkQueryOptions(),
  );

  const acceptContractMutation = useMutation({
    mutationFn: acceptContract,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });
    },
  });

  const referralUrl = `${MARKETING_URL}?l=${referralLink}`;

  const copyToClipboard = () => {
    if (referralUrl) {
      navigator.clipboard.writeText(referralUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const hasSuccessfulReferral = referrals.some(
    (ref: ReferralType) => ref.status_name === "Complete",
  );

  const setupReferralAccount = async () => {
    if (!termsAccepted) {
      return;
    }

    await acceptContractMutation.mutateAsync({
      contractName: "referral_program",
    });
  };

  if (
    !user.accepted_contracts.find(
      (contract) => contract.name === Contract.REFERRAL_PROGRAM,
    )
  ) {
    return (
      <div className="pt-6">
        <div className="bg-white p-8 rounded-lg max-w-2xl mx-auto">
          <div className="text-center mb-8">
            <h1 className="text-2xl font-bold mb-4">
              Invite Friends & Earn Rewards
            </h1>
            <p className="text-gray-600">
              Know a US-licensed provider who could benefit from Saile? Invite
              them to Saile and earn $20 when they join! It's that simple.
            </p>
          </div>

          <div className="space-y-6 mb-8">
            <div className="flex items-center gap-6 p-4 rounded-lg border border-gray-100">
              <div className="bg-blue-50 p-3 rounded-full">
                <Share2 className="w-6 h-6 text-blue-500" />
              </div>
              <div>
                <h3 className="font-semibold mb-1">
                  Step 1: Agree to the Terms
                </h3>
                <p className="text-gray-600 text-sm">
                  Agree to the terms and get your personal invite link
                </p>
              </div>
            </div>

            <div className="flex items-center gap-6 p-4 rounded-lg border border-gray-100">
              <div className="bg-green-50 p-3 rounded-full">
                <UserPlus className="w-6 h-6 text-green-500" />
              </div>
              <div>
                <h3 className="font-semibold mb-1">Step 2: Invite Friends</h3>
                <p className="text-gray-600 text-sm">
                  Share your link with your US-licensed provider friends
                </p>
              </div>
            </div>

            <div className="flex items-center gap-6 p-4 rounded-lg border border-gray-100">
              <div className="bg-yellow-50 p-3 rounded-full">
                <DollarSign className="w-6 h-6 text-yellow-500" />
              </div>
              <div>
                <h3 className="font-semibold mb-1">Step 3: Get Rewarded</h3>
                <p className="text-gray-600 text-sm">
                  Earn $20 when your friends join and complete their profile
                </p>
              </div>
            </div>
          </div>

          <div className="text-center">
            <div className="mb-4 flex items-center justify-center gap-2">
              <input
                type="checkbox"
                id="terms"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300"
              />
              <label htmlFor="terms" className="text-sm text-gray-600">
                I agree to the{" "}
                <ExternalLink
                  href="https://www.saileapp.com/referral-terms.html"
                  popover={true}
                  className="text-gray-600 underline hover:underline"
                >
                  Referral Terms
                </ExternalLink>
              </label>
            </div>
            <Button
              size="lg"
              className="px-8"
              onClick={setupReferralAccount}
              disabled={acceptContractMutation.isPending || !termsAccepted}
            >
              {acceptContractMutation.isPending
                ? "Setting up..."
                : "Start Inviting Friends"}
            </Button>
            <p className="text-sm text-gray-500 mt-4">
              Quick setup - start inviting friends in less than 2 minutes
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-6 pb-12">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold mb-3">Invite Friends to Saile</h1>
        <p className="text-xl text-gray-600">
          Get <span className="font-semibold text-green-600">$20</span> for each
          US-licensed provider who joins!
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg mb-8 max-w-2xl mx-auto">
        <h2 className="text-xl font-semibold mb-6">How It Works</h2>
        <div className="space-y-6">
          <div className="flex items-center gap-6 p-4 rounded-lg border border-gray-200">
            <div className="bg-blue-50 p-4 rounded-full">
              <Share2 className="w-6 h-6 text-blue-500" />
            </div>
            <div>
              <h3 className="font-semibold mb-1">1. Share With Friends</h3>
              <p className="text-gray-600 text-sm">
                Send your personal invite link to your US-licensed provider
                friends
              </p>
            </div>
          </div>

          <div className="flex items-center gap-6 p-4 rounded-lg border border-gray-200">
            <div className="bg-green-50 p-4 rounded-full">
              <UserPlus className="w-6 h-6 text-green-500" />
            </div>
            <div>
              <h3 className="font-semibold mb-1">2. Friends Join Saile</h3>
              <p className="text-gray-600 text-sm">
                Your friends create their account and fill out their profile
                using your link
              </p>
            </div>
          </div>

          <div className="flex items-center gap-6 p-4 rounded-lg border border-gray-200">
            <div className="bg-yellow-50 p-4 rounded-full">
              <DollarSign className="w-6 h-6 text-yellow-500" />
            </div>
            <div>
              <h3 className="font-semibold mb-1">3. You Get $20</h3>
              <p className="text-gray-600 text-sm">
                Earn your reward when they complete their profile
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg mb-8 max-w-2xl mx-auto">
        <h2 className="text-lg font-semibold mb-4">Your Invite Link</h2>
        <div className="flex gap-4 items-center">
          <input
            readOnly
            value={referralUrl}
            className="flex-1 p-2 border rounded bg-gray-50"
            onClick={(e) => e.currentTarget.select()}
          />
          <Button onClick={copyToClipboard} className="min-w-[120px]">
            {copied ? "Copied!" : "Copy Link"}
          </Button>
        </div>
      </div>

      <div className="bg-white p-8 rounded-lg mb-8 max-w-2xl mx-auto border border-gray-100">
        <div className="flex items-center gap-3 mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Program Terms</h2>
        </div>
        <div className="text-gray-800 text-sm">
          <p className="mb-4 font-medium">
            By participating in our referral program, you agree to:
          </p>
          <ul className="space-y-3">
            <li className="flex items-start gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-gray-400 mt-2"></div>
              <span>
                Only refer US-licensed providers (MDs, CRNAs, NPs, PAs). RNs are
                not eligible.
              </span>
            </li>
            <li className="flex items-start gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-gray-400 mt-2"></div>
              <span>
                Receive $20 for each successful referral who completes their
                profile
              </span>
            </li>
            <li className="flex items-start gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-gray-400 mt-2"></div>
              <span>
                Maintain professional conduct in your referral communications
              </span>
            </li>
            <li className="flex items-start gap-3">
              <div className="w-1.5 h-1.5 rounded-full bg-gray-400 mt-2"></div>
              <span>
                Allow Saile to verify referral eligibility before payment
              </span>
            </li>
          </ul>
          <div className="mt-6 pt-4 border-t border-gray-100">
            <a
              href="https://www.saileapp.com/referral-terms.html"
              target="_blank"
              className="text-gray-700 hover:text-gray-900 font-medium inline-flex items-center gap-2 transition-colors"
            >
              View complete terms
              <span className="text-gray-400">→</span>
            </a>
          </div>
        </div>
      </div>

      {!user.billing.details_complete && (
        <div className="mt-4 p-4 bg-yellow-50 rounded-lg max-w-2xl mx-auto mb-8">
          <p className="text-sm text-yellow-800 mb-2">
            To receive referral payments, please complete your billing account
            setup.
          </p>
          <Link
            to="/provider/billing"
            className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-yellow-600 text-white hover:bg-yellow-700 h-9 px-4"
          >
            Setup Billing Account
          </Link>
        </div>
      )}

      <div className="bg-white rounded-lg max-w-2xl mx-auto">
        <div className="p-6 flex justify-between items-center">
          <div className="flex items-center gap-4">
            <h2 className="text-lg font-semibold">Friends You've Invited</h2>
            <Button
              variant="ghost"
              size="sm"
              onClick={() => refetchReferrals()}
              className="hover:bg-gray-100 flex items-center gap-2"
            >
              <RefreshCw className="w-4 h-4" />
              <span className="sr-only md:not-sr-only">Refresh</span>
            </Button>
          </div>
          {hasSuccessfulReferral && (
            <Button variant="outline" className="flex items-center gap-2">
              <DollarSign className="w-4 h-4" />
              Claim Your Rewards
            </Button>
          )}
        </div>
        {referrals.length === 0 ? (
          <div className="p-8 text-center">
            <p className="text-gray-500 mb-4">
              You haven't invited any friends yet.
            </p>
            <p className="text-gray-500">
              Share your invite link above to start earning rewards!
            </p>
          </div>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Friend's ID</TableHead>
                <TableHead>Date Invited</TableHead>
                <TableHead>Status</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {referrals.map((referral: ReferralType) => (
                <TableRow key={referral.id}>
                  <TableCell>{referral.email}</TableCell>
                  <TableCell>
                    {format(new Date(referral.referred_at), "MMMM d, yyyy")}
                  </TableCell>
                  <TableCell className="flex items-center gap-2">
                    {referral.status_name === "Completed" && referral.paid
                      ? "Paid"
                      : referral.status_name}
                    {referral.status_name === "In Progress" && (
                      <Popover>
                        <PopoverTrigger>
                          <InfoIcon className="h-4 w-4 text-gray-500" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <p className="text-sm">
                            Your friend has started signing up but hasn't
                            completed their profile yet.
                          </p>
                        </PopoverContent>
                      </Popover>
                    )}
                    {referral.status_name === "Completed" && !referral.paid && (
                      <Popover>
                        <PopoverTrigger>
                          <InfoIcon className="h-4 w-4 text-yellow-500" />
                        </PopoverTrigger>
                        <PopoverContent>
                          <div className="flex items-center gap-2 text-yellow-600">
                            <p className="text-sm font-medium">
                              Warning: You need to create a billing account to
                              receive your referral payment.
                            </p>
                          </div>
                        </PopoverContent>
                      </Popover>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_provider/provider/referrals/")({
  component: ReferralsPage,
  loader: () =>
    Promise.all([
      queryClient.ensureQueryData(referralsQueryOptions()),
      queryClient.ensureQueryData(createReferralLinkQueryOptions()),
    ]),
});
