import ShiftPreferencesForm from "@/components/Forms/ShiftPreferencesForm";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_provider/provider/shifts/preferences/")(
  {
    component: ShiftPage,
  },
);

function ShiftPage() {
  return (
    <div className="p-2 pt-4 md:pt-8 md:p-8">
      <div className="animate-fade-in-delay-2 w-full p-8 rounded-sm  bg-white border dark:border-strokedark dark:bg-boxdark">
        <h2 className="text-lg font-bold text-gray-900 mb-1 animate-fade-in">
          Shift Preferences
        </h2>
        <p className="text-gray-600 mb-6 animate-fade-in-delay-2">
          Fill in your shift preferences and we will let you know when shifts
          are available
        </p>
        <ShiftPreferencesForm />
      </div>
    </div>
  );
}
