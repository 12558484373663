import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { FileBadge, Pencil, Stethoscope } from "lucide-react";
import { formatDate } from "date-fns";
import { useSuspenseQuery } from "@tanstack/react-query";
import { getSpecialtyCodesQueryOptions } from "@/ajax/queries";
import { SpecialtiesForm } from "./SpecialtiesForm";

export function SpecialtiesCard({
  editable,
  isEditing,
  onEditChange,
  specialties,
  userId,
}: {
  editable: boolean;
  isEditing: boolean;
  onEditChange: (editing: boolean) => void;
  specialties: Array<any>;
  userId: string;
}) {
  const specialtyCodes = specialties
    .filter((specialty: any) => specialty.value.specialty_code)
    .map((specialty: any) => specialty.value.specialty_code);
  const { data: specialtyData } = useSuspenseQuery(
    getSpecialtyCodesQueryOptions(specialtyCodes),
  );

  const getSpecialtyName = (code: string) => {
    return specialtyData?.find((s) => s.code === code)?.name || code;
  };

  return (
    <Card
      className={cn("mt-4", {
        "shadow-none border-none": isEditing,
      })}
    >
      <CardHeader className="pb-1">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">
            {specialties.length > 1 ? "Specialties" : "Specialty"}
          </h3>
          {isEditing || !editable ? null : (
            <Button
              variant="ghost"
              size="icon"
              onClick={() => onEditChange(true)}
            >
              <Pencil className="h-4 w-4" />
            </Button>
          )}
        </div>
      </CardHeader>
      <CardContent className="space-y-2">
        {isEditing ? (
          <SpecialtiesForm
            specialties={specialties}
            userId={userId}
            onSave={() => onEditChange(false)}
          />
        ) : (
          <>
            {specialties.map((specialty: any, index: number) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Stethoscope className="h-5 w-5 text-gray-500" />
                  <div>
                    <div className="font-semibold">
                      {getSpecialtyName(specialty.value.specialty_code)}
                    </div>
                    {specialty.value.certifying_board ? (
                      <div className="text-sm text-gray-600">
                        {specialty.value.certifying_board}
                      </div>
                    ) : (
                      <div className="text-sm text-gray-600">
                        No board certification
                      </div>
                    )}
                    {specialty.value.certification_number && (
                      <div className="text-sm text-gray-600">
                        #{specialty.value.certification_number}
                      </div>
                    )}
                    {specialty.value.expiration_date && (
                      <div className="text-sm text-gray-500">
                        Exp.{" "}
                        {formatDate(
                          specialty.value.expiration_date,
                          "MMM yyyy",
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {specialties.length === 0 && (
              <div className="text-center text-gray-500">
                No specialties added yet.
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}
