export const VALID_TIMEZONES = [
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Phoenix",
] as const;

export type Timezone = (typeof VALID_TIMEZONES)[number];

/**
 * Type guard function that checks if a string is a valid Timezone
 * @param timezone - The timezone string to validate
 * @returns A type predicate indicating if the string is a valid Timezone
 */
export function isTimezone(timezone: string): timezone is Timezone {
  return VALID_TIMEZONES.includes(timezone as Timezone);
}
