import { ChatThread } from "@/ajax/chat/getChatThreads";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { ArrowLeft, MoreVertical, Archive, User, ExternalLink } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTRPC } from "@/trpc";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ProfileModal } from "../profile/components/ProfileModal";

export const ChatThreadHeader = ({
  thread,
  onBack,
  onArchive,
  onViewDetails,
}: {
  thread: ChatThread;
  onBack: () => void;
  onArchive: () => void;
  onViewDetails: () => void;
}) => {
  const hasUserParticipants = thread.user_participants.length > 0;
  const trpc = useTRPC();
  const { data: userDetails } = useQuery(
    trpc.chat.getUserDetails.queryOptions(
      {
        userId: thread.user_participants[0]?.id,
      },
      {
        enabled: hasUserParticipants,
      },
    ),
  );
  const [showDetails, setShowDetails] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  return (
    <div className="p-3 sm:p-4 border-b flex flex-col bg-white">
      <div className="flex items-center space-x-3 sm:space-x-4">
        <Button
          variant="ghost"
          size="icon"
          onClick={onBack}
          className="lg:hidden"
        >
          <ArrowLeft className="h-5 w-5" />
        </Button>
        <Avatar className="h-10 w-10 border-2 border-white shadow-sm">
          <AvatarFallback className="bg-gradient-to-br from-blue-800 to-blue-900 text-white font-medium">
            {thread.user_participants[0]?.first_name?.[0]
              ? thread.user_participants[0]?.first_name[0]
              : thread.organization_participants[0]?.name?.[0] || "?"}
          </AvatarFallback>
        </Avatar>
        <div className="flex-1">
          <h2 className="font-semibold text-sm sm:text-base">
            {thread.user_participants.length
              ? thread.user_participants
                  .map((p) => `${p.first_name} ${p.last_name}`)
                  .join(", ")
              : thread.organization_participants.map((p) => p.name).join(", ")}
          </h2>
        </div>
        {userDetails && (
          <Button
            variant="outline"
            size="sm"
            className="text-sm flex items-center gap-1"
            onClick={() => setShowDetails(!showDetails)}
          >
            <User className="h-5 w-5" />
            {showDetails ? "Hide Details" : "View Details"}
          </Button>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreVertical className="h-5 w-5" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={onArchive}>
              <Archive className="mr-2 h-4 w-4" />
              Archive Chat
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {userDetails && showDetails && (
        <div className="mt-2 pl-10 sm:pl-14 pr-2 text-xs text-gray-600">
          <div className="flex flex-col gap-y-1 text-xs sm:text-sm">
            {userDetails.specialty_name && (
              <div className="flex flex-wrap items-center">
                <span className="font-semibold mr-1">Specialty:</span>
                <span className="truncate">
                  {userDetails.specialty_name} ({userDetails.specialty_code})
                </span>
              </div>
            )}
            {userDetails.locations && userDetails.locations.length > 0 && (
              <div className="flex flex-wrap items-center">
                <span className="font-semibold mr-1">Locations:</span>
                <span className="truncate">
                  {userDetails.locations
                    .filter((loc) => loc.name !== "ANY_CITY")
                    .map((loc) => loc.name)
                    .join(" • ")}
                  {userDetails.locations.some(
                    (loc) => loc.name === "ANY_CITY",
                  ) &&
                    (userDetails.locations.filter(
                      (loc) => loc.name !== "ANY_CITY",
                    ).length > 0
                      ? " • Open to other locations"
                      : "Any location")}
                </span>
              </div>
            )}
            <div className="flex items-center">
              <span className="font-semibold mr-1">Duration:</span>
              <span>
                {userDetails.contract_duration ||
                  "Full-time, Contract, or Ocassional"}
              </span>
            </div>
            {userDetails.availability_description && (
              <div className="flex flex-wrap items-center">
                <span className="font-semibold mr-1">Availability:</span>
                <span className="truncate">
                  {userDetails.availability_description}
                </span>
              </div>
            )}
            <div className="flex items-center">
              <span className="font-semibold mr-1">Rate:</span>
              {userDetails.min_hourly_rate > 0 ? (
                <span>${userDetails.min_hourly_rate}/hr</span>
              ) : (
                <span>No minimum</span>
              )}
            </div>
            {userDetails.organizations &&
              userDetails.organizations.length > 0 && (
                <div className="flex flex-wrap items-center">
                  <span className="font-semibold mr-1">Organizations:</span>
                  <span className="wrap">
                    {userDetails.organizations.join(" • ")}
                  </span>
                </div>
              )}
            <div className="mt-2">
              <Button
                variant="outline"
                size="sm"
                className="inline-flex items-center justify-center gap-1 text-xs px-3 py-1"
                onClick={() => setShowProfileModal(true)}
              >
                <ExternalLink className="h-4 w-4" />
                View Full Profile
              </Button>
            </div>
          </div>
        </div>
      )}
      {showProfileModal && (
        <ProfileModal userId={thread.user_participants[0]?.id} onClose={() => setShowProfileModal(false)} />
      )}
    </div>
  );
};
