import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import "react-international-phone/style.css";
import Loading from "@/components/Loading";
import { getHealthcareRoles } from "@/ajax/shift_preferences/getHealthcareRoles";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  useSuspenseQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { updateOnboardingAccountDetails } from "@/ajax/onboarding/updateOnboardingAccountDetails";
import {
  CREDENTIALS_FIELDS_QUERY_KEY,
  getCredentialsFieldsQueryOptions,
  PROVIDER_USER_QUERY_KEY,
} from "@/ajax/queries";
import { getEnvVar } from "@/common/utils/environment";
import { SpecialtiesSelect } from "@/components/Selects/SpecialtiesSelect";
import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { getNPIDetails, NPIResult } from "@/ajax/onboarding/getNPIDetails";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useProviderUser } from "@/hooks/useProviderUser";
import { useTRPC } from "@/trpc";

type Inputs = {
  firstName: string;
  lastName: string;
  npiNumber: string;
  acceptTerms: boolean;
  specialtyCode: string;
  healthcareProfessionalType: string;
};

const PRIVACY_POLICY_URL =
  getEnvVar("PRIVACY_POLICY_URL") || "https://www.saileapp.com/privacy-policy";
const TERMS_OF_SERVICE_URL =
  getEnvVar("TERMS_OF_SERVICE_URL") ||
  "https://www.saileapp.com/terms-of-service";

const openUrl = async (url: string) => {
  if (Capacitor.isNativePlatform()) {
    await Browser.open({ url, presentationStyle: "popover" });
  } else {
    window.open(url, "_blank");
  }
};

export default function AccountDetailsOnboardingForm({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const trpc = useTRPC();
  const providerUser = useProviderUser();
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [npiResults, setNpiResults] = useState<any[]>([]);
  const [showNpiDialog, setShowNpiDialog] = useState(false);
  const [isSearchingNpi, setIsSearchingNpi] = useState(false);
  const queryClient = useQueryClient();

  const { data: healthcareRoles } = useSuspenseQuery({
    queryKey: ["healthcareRoles"],
    queryFn: getHealthcareRoles,
  });

  const { data: credentialsFields } = useSuspenseQuery(
    getCredentialsFieldsQueryOptions([
      "name",
      "npi_number",
      "healthcare_role",
      "specialty",
    ]),
  );

  const healthcareRolesData = healthcareRoles.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  const name =
    (credentialsFields.name.values?.[0]?.value as {
      first_name: string;
      last_name: string;
    }) ?? null;
  const npiNumber =
    (credentialsFields.npi_number.values?.[0]?.value as string) ?? null;
  const healthcareRole =
    (credentialsFields.healthcare_role.values?.[0]?.value as {
      healthcare_role_id: string;
    }) ?? null;
  const specialty =
    (credentialsFields.specialty.values?.[0]?.value as {
      specialty_code: string;
    }) ?? null;
  const form = useForm<Inputs>({
    defaultValues: {
      firstName: name?.first_name || providerUser?.first_name || "",
      lastName: name?.last_name || providerUser?.last_name || "",
      npiNumber: npiNumber || "",
      healthcareProfessionalType: healthcareRole?.healthcare_role_id || "",
      specialtyCode: specialty?.specialty_code || "",
      acceptTerms: false,
    },
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting, isSubmitted },
  } = form;

  const firstName = watch("firstName");
  const lastName = watch("lastName");

  const handleNpiSelect = (result: NPIResult) => {
    setValue("npiNumber", result.number);
    let specialtyCode = result.taxonomies.find((t) => t.primary)?.code;
    // Get the first if no primary specialty is found
    if (!specialtyCode) {
      specialtyCode = result.taxonomies[0]?.code;
    }
    if (specialtyCode) {
      setValue("specialtyCode", specialtyCode);
    }
    // Some values have a dot in them, so we remove it
    const healthcareProfessionalType = result.basic.credential.replaceAll(
      ".",
      "",
    );

    const roleId = healthcareRoles.find(
      (role) => role.code === healthcareProfessionalType,
    )?.id;
    if (roleId) {
      setValue("healthcareProfessionalType", roleId);
    }
    setShowNpiDialog(false);
  };

  const searchNpi = async () => {
    if (firstName && lastName) {
      setShowNpiDialog(true);
      setIsSearchingNpi(true);
      const results = await getNPIDetails(firstName, lastName);
      setNpiResults(results);
      setIsSearchingNpi(false);
    }
  };

  const onLastNameBlur = async () => {
    await searchNpi();
  };

  const onFirstNameBlur = async () => {
    await searchNpi();
  };

  const { mutateAsync: updateAccountDetails } = useMutation({
    ...trpc.providerOnboarding.updateDetails.mutationOptions(),
    onSuccess: () => {
      setSubmitError(null);
      queryClient.invalidateQueries({
        queryKey: [CREDENTIALS_FIELDS_QUERY_KEY],
      });
      queryClient.invalidateQueries({ queryKey: [PROVIDER_USER_QUERY_KEY] });
      onComplete();
    },
    onError: () => {
      setSubmitError("An error occurred. Please try again.");
    },
  });

  const onSubmit = async (data: Inputs) => {
    await updateAccountDetails({
      firstName: data.firstName,
      lastName: data.lastName,
      npiNumber: data.npiNumber,
      healthcareProfessionalTypeId: data.healthcareProfessionalType,
      specialtyCode: data.specialtyCode,
    });
  };

  useEffect(() => {
    searchNpi();
  }, []);

  return (
    <>
      <Form {...form}>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4.5">
            <FormField
              rules={{
                required: "First name is required",
              }}
              name="firstName"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    First name <span className="text-meta-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      id="first_name_input"
                      type="text"
                      placeholder="Enter your first name"
                      autoFocus={true}
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        onFirstNameBlur();
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mb-4.5">
            <FormField
              rules={{
                required: "Last name is required",
              }}
              name="lastName"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Last name <span className="text-meta-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      id="last_name_input"
                      type="text"
                      placeholder="Enter your last name"
                      {...field}
                      onBlur={(e) => {
                        field.onBlur();
                        onLastNameBlur();
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mb-4.5 space-y-1">
            <FormField
              name="healthcareProfessionalType"
              rules={{
                required: "Healthcare role is required",
              }}
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Healthcare Provider Type{" "}
                    <span className="text-meta-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <Select
                      placeholder="Select Healthcare Provider Type"
                      options={healthcareRolesData}
                      {...field}
                      classNames={{
                        control: (state) => "py-2",
                      }}
                      value={healthcareRolesData.find(
                        (option) => option.value === field.value,
                      )}
                      onChange={(
                        selectedOption: { value: string; label: string } | null,
                      ) => {
                        field.onChange(selectedOption?.value ?? "");
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mb-4.5 space-y-1">
            <FormField
              name="specialtyCode"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Specialty <span className="text-meta-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <SpecialtiesSelect
                      value={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mb-4.5">
            <FormField
              rules={{
                required: "NPI number is required",
                pattern: {
                  value: /^\d{10}$/,
                  message: "Invalid NPI number. Must be 10 digits",
                },
              }}
              name="npiNumber"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    NPI Number <span className="text-meta-1">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      maxLength={10}
                      id="npi_number_input"
                      type="text"
                      placeholder="Enter NPI Number"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="mb-5">
            <Controller
              name="acceptTerms"
              control={control}
              rules={{
                required:
                  "You must accept the terms of service and privacy policy",
              }}
              render={({ field }) => (
                <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                  <FormControl>
                    <Checkbox
                      {...field}
                      value="Accept"
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id="acceptTerms"
                    />
                  </FormControl>
                  <div className="space-y-1 leading-none">
                    <FormLabel>
                      Agree to our{" "}
                      <button
                        type="button"
                        onClick={() => openUrl(TERMS_OF_SERVICE_URL)}
                        className="text-blue-600 hover:underline"
                      >
                        Terms of Service
                      </button>{" "}
                      and{" "}
                      <button
                        type="button"
                        onClick={() => openUrl(PRIVACY_POLICY_URL)}
                        className="text-blue-600 hover:underline"
                      >
                        Privacy Policy
                      </button>
                      .
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />
            {errors.acceptTerms && (
              <p className="text-red-500 text-sm mt-1">
                {errors.acceptTerms.message}
              </p>
            )}
          </div>
          {submitError && <p className="text-red-500 my-2">{submitError}</p>}
          <div className="mb-5">
            <Button size="none" className="w-full py-4" type="submit">
              {isSubmitting ? <Loading className="w-4 h-4" /> : "Save"}
            </Button>
          </div>
        </form>
      </Form>

      <Dialog open={showNpiDialog} onOpenChange={setShowNpiDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Select your NPI record</DialogTitle>
          </DialogHeader>
          <div className="space-y-4 max-h-[60vh] overflow-y-auto">
            {isSearchingNpi ? (
              <div className="flex justify-center items-center py-8">
                <Loading className="w-8 h-8" />
              </div>
            ) : npiResults.length > 0 ? (
              npiResults.map((result: NPIResult) => {
                const specialties = result.taxonomies.sort((a, b) =>
                  a.primary ? -1 : 1,
                );
                return (
                  <div
                    key={result.number}
                    className="p-4 border rounded-lg cursor-pointer hover:bg-gray-50"
                    onClick={() => handleNpiSelect(result)}
                  >
                    <p>
                      {result.basic.first_name} {result.basic.last_name}
                    </p>
                    <p className="text-sm text-gray-500">
                      NPI: {result.number}
                    </p>
                    {specialties.length > 0 && (
                      <p className="text-sm text-gray-500">
                        Specialty: {specialties.map((s) => s.desc).join(", ")}
                      </p>
                    )}
                  </div>
                );
              })
            ) : (
              <p className="text-center text-gray-500 py-4">
                No NPI records found that match your name. Check your spelling
                or try a different name.
              </p>
            )}
          </div>
          <div className="flex justify-center mt-4">
            <Button
              variant="outline"
              onClick={() => setShowNpiDialog(false)}
              className="w-full"
              autoFocus={false}
            >
              {npiResults.length > 0 ? "None of these match" : "Close"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
