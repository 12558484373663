import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import { CalendarIcon, X, AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useMutation } from "@tanstack/react-query";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import DatePicker from "react-datepicker";
import { SaveButton } from "@/components/Button/SaveButton";
import { USStateSelect } from "@/components/Selects/USStateSelect";

const licenseSchema = z.object({
  state: z.string().length(2, "State code must be 2 characters"),
  license_number: z
    .string()
    .max(255, "License number must be less than 255 characters"),
  expiration_date: z.date(),
  deleted: z.boolean().optional(),
});

const formSchema = z.object({
  stateLicenses: z.array(licenseSchema),
});

type FormValues = z.infer<typeof formSchema>;

export function StateLicensesForm({
  onSave,
  stateLicenses,
  userId,
}: {
  onSave: () => void;
  stateLicenses: any;
  userId: string;
}) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      stateLicenses:
        stateLicenses
          .filter((val: { value: any }) => val.value)
          .map((val: { value: any }) => ({
            state: val.value?.state || "",
            license_number: val.value?.license_number || "",
            expiration_date: val.value?.expiration_date
              ? new Date(val.value.expiration_date)
              : new Date(),
          })) || [],
    },
  });

  const {
    fields: licenseFields,
    append: appendLicense,
    remove: removeLicense,
  } = useFieldArray({
    control: form.control,
    name: "stateLicenses",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const existingValues = stateLicenses || [];
    const newIndexMax = data.stateLicenses.length;

    const fieldValues = [
      ...data.stateLicenses.map((license, index) => ({
        field_code: "state_medical_licenses",
        value: {
          state: license.state,
          license_number: license.license_number,
          expiration_date: format(license.expiration_date, "yyyy-MM-dd"),
        },
        index: index + 1,
        delete: false,
      })),

      ...existingValues
        .filter((val: { index: number }) => val.index > newIndexMax)
        .map((val: { index: number }) => ({
          field_code: "state_medical_licenses",
          value: null,
          delete: true,
          index: val.index,
        })),
    ];

    mutation.mutate({
      fieldValues,
      switchUserId: userId,
    });
  }

  const renderLicenseFields = (fields: any[], remove: any) => {
    return fields.map((field, index) => (
      <div key={field.id} className="space-y-4 p-4 border rounded-lg relative">
        <Button
          type="button"
          variant="outline"
          size="sm"
          className="absolute right-2 top-2"
          onClick={() => {
            remove(index as number);
          }}
        >
          Remove
        </Button>

        <FormField
          control={form.control}
          name={`stateLicenses.${index}.state`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>State</FormLabel>
              <FormControl>
                <USStateSelect {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`stateLicenses.${index}.license_number`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>License Number</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={`stateLicenses.${index}.expiration_date`}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Expiration Date</FormLabel>
              <div className="relative">
                <DatePicker
                  selected={field.value}
                  onChange={(date) => field.onChange(date)}
                  customInput={
                    <Input
                      value={
                        field.value ? format(field.value, "MM-dd-yyyy") : ""
                      }
                    />
                  }
                  dateFormat="MM-dd-yyyy"
                  wrapperClassName="w-full"
                />
                <CalendarIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 opacity-50 pointer-events-none" />
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    ));
  };

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="space-y-6">
          <div>
            <div className="space-y-4">
              {renderLicenseFields(licenseFields, removeLicense)}
              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() =>
                  appendLicense({
                    state: "",
                    license_number: "",
                    expiration_date: new Date(),
                  })
                }
              >
                Add State License
              </Button>
            </div>
          </div>
        </div>

        <SaveButton saveStatus={saveStatus} />
        <Button onClick={onSave} className="w-full p-6" variant="outline">
          Cancel
        </Button>
      </form>
    </Form>
  );
}
