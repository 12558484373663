import { apiRequestPostWithRetry } from "../utils/apiRequest";

export const adminPayReferrals = async (referralIds: string[]) => {
  const response = await apiRequestPostWithRetry({
    path: "/admin/referrals/complete",
    body: {
      referralIds,
    },
  });

  return response.data;
};
