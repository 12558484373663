import { WorkShiftSearchStatus } from "@/common/enums/WorkShiftSearchStatus";
import { ShiftContractDuration } from "@/common/enums/ShiftContractDuration";
import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export const getWorkShiftPreferences = async ({
  userId,
}: {
  userId?: string;
} = {}) => {
  return callSupabaseFnFromClient<{
    cities: {
      name: string;
      lat: number;
      lng: number;
    }[];
    open_to_other_cities: boolean;
    healthcare_professional_type: {
      id: string;
      name: string;
    };
    facility_types: {
      id: string;
      name: string;
    }[];
    contract_duration_id: ShiftContractDuration;
    search_status_id: WorkShiftSearchStatus;
    min_hourly_rate: number | null;
    availability_description: string | null;
  }>({
    fnName: "get_work_shift_preferences",
    args: {
      user_id: userId,
    },
  });
};
