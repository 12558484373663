import {
  useState,
  KeyboardEvent,
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
} from "react";

interface TagsInputProps {
  initialTags?: string[];
  onTagsChange?: (tags: string[]) => void;
  validateValue?: (value: string) => boolean;
  placeholder?: string;
}

function TagsInput({
  placeholder = "Type something",
  initialTags = [],
  onTagsChange,
  validateValue,
}: TagsInputProps) {
  const [tags, setTags] = useState<string[]>(initialTags);

  function addTag(value: string) {
    if (!value.trim()) return;
    if (validateValue && !validateValue(value)) return;
    const newTags = [...tags, value];
    setTags(newTags);
    onTagsChange?.(newTags);
  }

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (!["Space", "Tab", "Enter"].includes(e.code)) {
      return;
    }
    if (e.code === "Enter") {
      e.stopPropagation();
      e.preventDefault();
    }
    const value = e.currentTarget.value;
    addTag(value);
    e.currentTarget.value = "";
  }

  function handlePaste(e: ClipboardEvent<HTMLInputElement>) {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const values = pastedText.split(/[,\s]+/).filter(Boolean);

    const newTags = [...tags];
    values.forEach((value) => {
      if (!value.trim()) return;
      if (validateValue && !validateValue(value)) return;
      newTags.push(value);
    });

    setTags(newTags);
    onTagsChange?.(newTags);
    e.currentTarget.value = "";
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    if (value) {
      addTag(value);
      e.currentTarget.value = "";
    }
  }

  function removeTag(index: number) {
    const newTags = tags.filter((_, i) => i !== index);
    setTags(newTags);
    onTagsChange?.(newTags);
  }

  return (
    <div
      style={{
        padding: ".5em",
        borderRadius: "3px",
        marginTop: "1em",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: ".5em",
      }}
      className="border border-gray-300"
    >
      {tags.map((tag, index) => (
        <div
          style={{
            backgroundColor: "rgb(218, 216, 216)",
            display: "inline-block",
            padding: ".5em .75em",
            borderRadius: "20px",
          }}
          key={index}
        >
          <span>{tag}</span>
          <span
            onClick={() => removeTag(index)}
            style={{
              height: "20px",
              width: "20px",
              backgroundColor: "rgb(48, 48, 48)",
              color: "#fff",
              borderRadius: "50%",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: ".5em",
              fontSize: "18px",
              cursor: "pointer",
            }}
          >
            &times;
          </span>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onBlur={handleBlur}
        type="text"
        style={{
          flexGrow: 1,
          padding: ".5em 0",
          border: "none",
          outline: "none",
        }}
        placeholder={placeholder}
      />
    </div>
  );
}

export default TagsInput;
