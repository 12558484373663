import { createFileRoute } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getOrganizations } from "@/ajax/organizations/getOrganizations";
import { createOrganization } from "@/ajax/organizations/createOrganization";
import { Link } from "@tanstack/react-router";
import { Card } from "@/components/ui/card";
import { updateOrganization } from "@/ajax/organizations/updateOrganization";

interface Organization {
  id: string;
  name: string;
  created_at: string;
  business_units?: Array<{
    id: string;
    name: string;
    created_at: string;
  }>;
}

const OrganizationsPage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [editingOrg, setEditingOrg] = useState<Organization | null>(null);
  const queryClient = useQueryClient();

  const { data: organizations = [] } = useQuery<Organization[]>({
    queryKey: ["organizations"],
    queryFn: getOrganizations,
  });

  const createOrgMutation = useMutation({
    mutationFn: createOrganization,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      handleCloseDialog();
    },
  });

  const updateOrgMutation = useMutation({
    mutationFn: updateOrganization,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["organizations"] });
      handleCloseDialog();
    },
  });

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setOrgName("");
    setEditingOrg(null);
  };

  const handleOpenDialog = (org?: Organization) => {
    if (org) {
      setEditingOrg(org);
      setOrgName(org.name);
    } else {
      setOrgName("");
      setEditingOrg(null);
    }
    setIsDialogOpen(true);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (editingOrg) {
      updateOrgMutation.mutate({ id: editingOrg.id, name: orgName });
    } else {
      createOrgMutation.mutate({ name: orgName });
    }
  };

  const isPending = createOrgMutation.isPending || updateOrgMutation.isPending;

  return (
    <div className="container mx-auto py-8 px-2">
      <div className="max-w-5xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-semibold">Organizations</h1>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button onClick={() => handleOpenDialog()}>
                Add Organization
              </Button>
            </DialogTrigger>
            <DialogContent className="min-w-[500px] p-6">
              <DialogHeader>
                <DialogTitle>
                  {editingOrg ? "Edit Organization" : "Add New Organization"}
                </DialogTitle>
              </DialogHeader>
              <form onSubmit={handleSubmit} className="space-y-4 mt-4">
                <div>
                  <Label htmlFor="name">Organization Name</Label>
                  <Input
                    id="name"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    placeholder="Enter organization name"
                  />
                </div>
                <DialogFooter className="flex flex-col-reverse gap-2 sm:flex-row sm:justify-end sm:gap-2">
                  <Button
                    type="button"
                    variant="outline"
                    onClick={handleCloseDialog}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={isPending}>
                    {isPending
                      ? editingOrg
                        ? "Saving..."
                        : "Creating..."
                      : editingOrg
                        ? "Save Changes"
                        : "Create"}
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>

        <Card className="p-0">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Created At</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {organizations.map((org) => (
                <TableRow key={org.id}>
                  <TableCell>{org.name}</TableCell>
                  <TableCell>
                    {new Date(org.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-2">
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => handleOpenDialog(org)}
                      >
                        Edit
                      </Button>
                      <Button variant="outline" size="sm" asChild>
                        <Link
                          to="/admin/organizations/$orgId/business-units"
                          params={{ orgId: org.id }}
                        >
                          View Business Units
                        </Link>
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </div>
    </div>
  );
};

export const Route = createFileRoute("/_admin/admin/organizations/")({
  component: OrganizationsPage,
});
