import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export type ChatThread = {
  id: string;
  last_message_time: string;
  unread_count: number;
  user_participants: {
    id: string;
    first_name: string;
    last_name: string;
    specialty_name: string;
    specialty_code: string;
  }[];
  organization_participants: {
    id: string;
    name: string;
  }[];
  last_message: {
    text: string;
  };
  last_read_at: string;
  archived_at: string;
};

export const adminGetChatThreads = async ({
  isArchived = false,
}: {
  isArchived?: boolean;
}) => {
  return callSupabaseFnFromClient<ChatThread[]>({
    fnName: "admin_get_chat_threads",
    args: { is_archived: isArchived },
  });
};
