import {
  createFileRoute,
  Outlet,
  redirect,
  ScrollRestoration,
} from "@tanstack/react-router";
import { ProviderLayout } from "@/components/Layouts/ProviderLayout";
import { useEffect } from "react";
import { supabase } from "@/ajax/clients/supabase";
import { queryClient } from "@/ajax/queryClient";
import { providerUserQueryOptions } from "../ajax/queries";
import { getOnboardingSteps } from "@/features/onboarding/utils/getOnboardingSteps";
import OfflineHandler from "@/components/OfflineHandler";
import { z } from "zod";
import { NotFoundComponent } from "@/components/NotFoundComponent";
import { Toaster } from "@/components/ui/sonner";
import { useTRPC } from "@/trpc";
import { useMutation } from "@tanstack/react-query";
import { isTimezone, Timezone, VALID_TIMEZONES } from "@/features/user/enums";

export const Route = createFileRoute("/_provider")({
  validateSearch: z.object({
    hideNav: z.boolean().optional(),
  }),
  beforeLoad: async ({ location, context }) => {
    let providerUser = null;
    try {
      providerUser = await queryClient.fetchQuery(providerUserQueryOptions());
    } catch (e) {
      console.log("error fetching provider user", e);

      if (e instanceof Error && e.message.includes("NetworkError")) {
        return {
          user: context.user,
          providerUser: null,
          isOnboarding: false,
        };
      }
    }

    if (!context.user || !providerUser) {
      throw redirect({
        to: "/auth-provider/signin",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.pathname,
        },
      });
    }

    const isOnboarding = getOnboardingSteps(providerUser).length > 0;

    // Redirect to the onboarding if it's not completed
    if (isOnboarding && location.pathname.startsWith("/provider/")) {
      throw redirect({
        to: "/provider-onboarding",
      });
    }

    return {
      user: context.user,
      providerUser,
      isOnboarding,
    };
  },
  notFoundComponent: () => <NotFoundComponent homePath="/provider/home" />,
  component: () => {
    const { user, isOnboarding } = Route.useRouteContext();
    const trpc = useTRPC();
    const { mutate: updateTimezone } = useMutation(
      trpc.user.updateTimezone.mutationOptions(),
    );
    useEffect(() => {
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((event, session) => {
        if (event === "SIGNED_OUT") {
          document.location.reload();
        }
      });
      return () => {
        subscription.unsubscribe();
      };
    }, []);

    useEffect(() => {
      if (user) {
        let timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (!isTimezone(timeZone)) {
          console.log(
            "Timezone is not a valid US timezone. Defaulting to New York.",
            timeZone,
          );
          timeZone = "America/New_York" as Timezone;
        }
        // Set the timezone to the user's timezone
        updateTimezone({
          timeZone: timeZone as Timezone,
        });
      }
    }, [user]);

    if (!user) {
      return <OfflineHandler />;
    }

    return (
      <ProviderLayout showSidebar={!isOnboarding}>
        <Outlet />
        <Toaster />
      </ProviderLayout>
    );
  },
});
