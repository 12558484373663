import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { AlertCircle, Plus, X } from "lucide-react";
import { SaveButton } from "@/components/Button/SaveButton";
import { SpecialtiesSelect } from "@/components/Selects/SpecialtiesSelect";
import { USStateSelect } from "@/components/Selects/USStateSelect";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import Select from "react-select";
import { getHealthcareRoles } from "@/ajax/shift_preferences/getHealthcareRoles";
import { AddressInput } from "@/components/Inputs/AddressInput";

const formSchema = z.object({
  firstName: z.string().min(1, "First name is required"),
  middleName: z.string().optional(),
  lastName: z.string().min(1, "Last name is required"),
  suffixName: z.string().optional(),
  specialtyCodes: z
    .array(z.string())
    .min(1, "At least one specialty is required"),
  npiNumber: z
    .string()
    .regex(/^\d{10}$/, "Invalid NPI number. Must be 10 digits"),
  address: z.object({
    address1: z.string().min(1, "Address line 1 is required"),
    address2: z.string().optional(),
    city: z.string().min(1, "City is required"),
    state: z
      .string()
      .regex(/^[A-Z]{2}$/, "Invalid state code. Must be 2 uppercase letters"),
    zipCode: z.string().regex(/^\d{5}$/, "Invalid ZIP code. Must be 5 digits"),
    lat: z.number().optional(),
    lng: z.number().optional(),
  }),
  healthcareProfessionalType: z.string().min(1, "Healthcare role is required"),
  linkedinUrl: z
    .string()
    .regex(
      /^https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
      "Invalid LinkedIn URL",
    )
    .optional()
    .or(z.literal("")),
});

type FormValues = z.infer<typeof formSchema>;

interface PrimaryDetailsFormProps {
  userId: string;
  defaultValues: FormValues;
  onSubmit: () => void;
  onCancel: () => void;
}

const HealthcareRoleSelect = ({
  value,
  onChange,
  placeholder = "Select Healthcare Provider Type",
}: {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
}) => {
  const { data: healthcareRoles } = useSuspenseQuery({
    queryKey: ["healthcareRoles"],
    queryFn: getHealthcareRoles,
  });

  const healthcareRolesData = healthcareRoles.map((role) => ({
    value: role.id,
    label: role.name,
  }));

  return (
    <Select
      placeholder={placeholder}
      options={healthcareRolesData}
      classNames={{
        control: (state) => "py-2",
      }}
      value={healthcareRolesData.find((option) => option.value === value)}
      onChange={(selectedOption: { value: string; label: string } | null) => {
        onChange(selectedOption?.value ?? "");
      }}
    />
  );
};

export const PrimaryDetailsForm = ({
  userId,
  defaultValues,
  onSubmit,
  onCancel,
}: PrimaryDetailsFormProps) => {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSubmit();
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const handleSubmit = (data: FormValues) => {
    setSaveStatus("saving");
    setErrorMessage(null);

    // Handle specialty codes deletion
    const existingSpecialtyCodes = defaultValues.specialtyCodes;
    const formSpecialtyCodes = data.specialtyCodes;
    const specialtyIndicesToDelete = existingSpecialtyCodes
      .map((_, i) => i + 1)
      .filter((i) => !formSpecialtyCodes.map((_, i) => i + 1).includes(i));

    const fieldValues = [
      {
        field_code: "name",
        value: {
          first_name: data.firstName,
          middle_name: data.middleName,
          last_name: data.lastName,
          suffix_name: data.suffixName,
        },
        index: 1,
        delete: false,
      },
      {
        field_code: "address",
        value: {
          address_1: data.address.address1,
          address_2: data.address.address2,
          city: data.address.city,
          state: data.address.state,
          zip_code: data.address.zipCode,
        },
        index: 1,
        delete: false,
      },
      ...data.specialtyCodes.map((code, index) => ({
        field_code: "specialty",
        value: {
          specialty_code: code,
        },
        index: index + 1,
        delete: false,
      })),
      ...specialtyIndicesToDelete.map((index) => ({
        field_code: "specialty",
        value: null,
        index,
        delete: true,
      })),
      {
        field_code: "npi_number",
        value: data.npiNumber,
        index: 1,
        delete: false,
      },
      {
        field_code: "healthcare_role",
        value: {
          healthcare_role_id: data.healthcareProfessionalType,
        },
        index: 1,
        delete: false,
      },
      ...(data.linkedinUrl
        ? [
            {
              field_code: "linkedin_url",
              value: data.linkedinUrl,
              index: 1,
              delete: false,
            },
          ]
        : defaultValues.linkedinUrl
          ? [
              {
                field_code: "linkedin_url",
                value: null,
                index: 1,
                delete: true,
              },
            ]
          : []),
    ];

    mutation.mutate({ fieldValues, switchUserId: userId });
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        {errorMessage && (
          <Alert variant="destructive" className="mb-6">
            <AlertCircle className="h-6 w-6" />
            <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
          </Alert>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name *</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="middleName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Middle Name</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name *</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="suffixName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Suffix</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="healthcareProfessionalType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Healthcare Provider Type *</FormLabel>
              <FormControl>
                <HealthcareRoleSelect
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="space-y-4">
          <FormLabel>Specialties *</FormLabel>
          {form.watch("specialtyCodes").map((code, index) => (
            <div key={index} className="flex gap-2 items-center">
              <div className="flex-1">
                <SpecialtiesSelect
                  value={code}
                  onChange={(value) => {
                    const newCodes = [...form.getValues("specialtyCodes")];
                    newCodes[index] = value;
                    form.setValue("specialtyCodes", newCodes);
                  }}
                />
              </div>
              {index > 0 && (
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    const newCodes = form
                      .getValues("specialtyCodes")
                      .filter((_, i) => i !== index);
                    form.setValue("specialtyCodes", newCodes);
                  }}
                >
                  <X className="h-4 w-4" />
                </Button>
              )}
            </div>
          ))}
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={() => {
              const currentCodes = form.getValues("specialtyCodes");
              form.setValue("specialtyCodes", [...currentCodes, ""]);
            }}
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Specialty
          </Button>
          {form.formState.errors.specialtyCodes && (
            <FormMessage>
              {form.formState.errors.specialtyCodes.message}
            </FormMessage>
          )}
        </div>

        <FormField
          control={form.control}
          name="npiNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>NPI Number</FormLabel>
              <FormControl>
                <Input
                  maxLength={10}
                  type="text"
                  placeholder="Enter NPI Number"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="linkedinUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>LinkedIn URL</FormLabel>
              <FormControl>
                <Input
                  type="url"
                  placeholder="https://linkedin.com/in/username"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="address"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <AddressInput value={field.value} onChange={field.onChange} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div>
          <SaveButton saveStatus={saveStatus} className="w-full py-6" />
        </div>
        <Button
          type="button"
          variant="outline"
          onClick={onCancel}
          className="w-full py-6"
        >
          Cancel
        </Button>
      </form>
    </Form>
  );
};
