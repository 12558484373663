import { getChatThreadMessages } from "@/ajax/chat/getChatThreadMessages";
import { ChatThread } from "@/ajax/chat/getChatThreads";
import { Button } from "@/components/ui/button";
import { useQuery, useMutation } from "@tanstack/react-query";
import { AlertCircle, Loader2, Send } from "lucide-react";
import { queryClient } from "@/ajax/queryClient";
import { ChatThreadHeader } from "./ChatThreadHeader";
import { ChatMessageList } from "./ChatMessageList";
import { ChatMessageInput } from "./ChatMessageInput";
import { archiveChatThread } from "@/ajax/chat/archiveChat";
import { useEffect } from "react";
import { updateChatLastReadAt } from "@/ajax/chat/updateChatLastReadAt";
import { getChatUnreadMessagesCountQueryOptions } from "@/ajax/queries";
import { useTRPC } from "@/trpc";
import posthog from "posthog-js";

interface ThreadViewProps {
  thread: ChatThread;
  onBack: () => void;
  readOnly?: boolean;
  onUnarchive: () => void;
}

// Loading state component
const LoadingState = () => (
  <div className="flex flex-col flex-1 h-full items-center justify-center">
    <Loader2 className="h-8 w-8 animate-spin text-blue-600" />
    <p className="mt-2 text-gray-600">Loading messages...</p>
  </div>
);

// Error state component
const ErrorState = () => (
  <div className="flex flex-col flex-1 h-full items-center justify-center text-red-600">
    <AlertCircle className="h-8 w-8" />
    <p className="mt-2">Failed to load messages</p>
    <Button
      variant="outline"
      className="mt-4"
      onClick={() => window.location.reload()}
    >
      Retry
    </Button>
  </div>
);

// Main thread view component
export const ChatThreadView: React.FC<ThreadViewProps> = ({
  thread,
  onBack,
  onUnarchive,
  readOnly = false,
}) => {
  const trpc = useTRPC();
  const {
    data: messages,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["chatThreadMessages", thread.id],
    queryFn: () => getChatThreadMessages({ threadId: thread.id }),
    staleTime: 0,
    refetchInterval: (query) => {
      return 5_000 + Math.random() * 5_000;
    },
  });

  const updateLastReadMutation = useMutation({
    mutationFn: updateChatLastReadAt,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chatThreads"] });
      queryClient.invalidateQueries(getChatUnreadMessagesCountQueryOptions());
    },
  });

  const sendMessageMutation = useMutation({
    ...trpc.chat.sendMessage.mutationOptions(),
    onSuccess: () => {
      posthog.capture("chat_message.send.success");
      queryClient.invalidateQueries({
        queryKey: ["chatThreadMessages", thread.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["chatThreads"],
      });
      queryClient.invalidateQueries(getChatUnreadMessagesCountQueryOptions());
    },
    onError: () => {
      posthog.capture("chat_message.send.error");
    },
  });

  useEffect(() => {
    if (
      messages &&
      messages.length > 0 &&
      new Date(messages[messages.length - 1].created_at) >
        new Date(thread.last_read_at)
    ) {
      updateLastReadMutation.mutate({
        chatThreadId: thread.id,
        lastReadAt: messages[messages.length - 1].created_at,
      });
    }
  }, [messages]);

  const handleSendMessage = async (message: string) => {
    await sendMessageMutation.mutateAsync({
      threadId: thread.id,
      message,
    });
    if (thread.archived_at) {
      onUnarchive();
    }
  };

  const archiveMutation = useMutation({
    mutationFn: () => archiveChatThread({ threadId: thread.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["chatThreads"] });
      onBack();
    },
  });

  if (isLoading || !messages) return <LoadingState />;
  if (error) return <ErrorState />;

  return (
    <div className="flex flex-col flex-1 max-h-full">
      <ChatThreadHeader
        thread={thread}
        onBack={onBack}
        onArchive={() => archiveMutation.mutate()}
        onViewDetails={() => {}}
      />
      <ChatMessageList messages={messages} />
      <ChatMessageInput onSend={handleSendMessage} readOnly={readOnly} />
    </div>
  );
};
