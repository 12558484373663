import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import * as z from "zod";
import { Form } from "@/components/ui/form";
import { AlertCircle, X, Plus } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useMutation } from "@tanstack/react-query";
import { saveCredentialsFields } from "@/ajax/credentials/saveCredentialsFields";
import { queryClient } from "@/ajax/queryClient";
import { useState } from "react";
import { SaveButton } from "@/components/Button/SaveButton";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  CERTIFICATION_TYPE_MAP,
  formSchema,
  CertificationType,
} from "./formConfigs";
type FormValues = z.infer<typeof formSchema>;

export function CertificationForm({
  onSave,
  certData,
  userId,
}: {
  onSave: () => void;
  certData: any;
  userId: string;
}) {
  const [saveStatus, setSaveStatus] = useState<
    "idle" | "saving" | "saved" | "error"
  >("idle");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showTypeSelect, setShowTypeSelect] = useState(false);

  const mutation = useMutation({
    mutationFn: saveCredentialsFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["credentialsFields"] });
      setSaveStatus("idle");
      setErrorMessage(null);
      onSave();
      if (isDeleting) {
        setIsDeleting(false);
      }
    },
    onError: (error) => {
      setSaveStatus("error");
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to save changes",
      );
      setTimeout(() => setSaveStatus("idle"), 4000);
    },
  });

  const certValues = Object.fromEntries(
    Object.keys(CERTIFICATION_TYPE_MAP).map((type) => [
      type,
      certData[type as CertificationType][0]?.value,
    ]),
  );

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      certifications: Object.entries(certValues)
        .filter(([_, value]) => value)
        .map(([type, value]) =>
          CERTIFICATION_TYPE_MAP[type as CertificationType].parseValue(value),
        ),
    },
  });

  const {
    fields: certificationFields,
    append: appendCertification,
    remove: removeCertification,
  } = useFieldArray({
    control: form.control,
    name: "certifications",
  });

  function onSubmit(data: FormValues) {
    setSaveStatus("saving");
    setErrorMessage(null);

    const existingTypes = new Set<CertificationType>(
      data.certifications.map((c) => c.type as CertificationType),
    );

    const fieldValues = [
      ...data.certifications.map((cert: { type: CertificationType }) => ({
        field_code: cert.type,
        index: 1,
        value: CERTIFICATION_TYPE_MAP[cert.type].formatValue(cert),
        delete: false,
      })),
      ...Object.entries(certValues)
        .filter(
          ([type, value]) =>
            value && !existingTypes.has(type as CertificationType),
        )
        .map(([type]) => ({
          field_code: type,
          index: 1,
          value: null,
          delete: true,
        })),
    ];

    mutation.mutate({ fieldValues, switchUserId: userId });
  }

  const handleTypeSelect = (type: CertificationType) => {
    appendCertification(CERTIFICATION_TYPE_MAP[type].createNew());
    setShowTypeSelect(false);
  };

  // Get current certification types
  const currentTypes = new Set(certificationFields.map((field) => field.type));

  let formFields = (
    <div className="space-y-6">
      {certificationFields.map((field, index) => {
        const CertificationFields =
          CERTIFICATION_TYPE_MAP[field.type as CertificationType]?.fields;
        return (
          <div key={field.id} className="border rounded-lg">
            <div className="p-4">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold capitalize">
                  {
                    CERTIFICATION_TYPE_MAP[field.type as CertificationType]
                      ?.label
                  }{" "}
                  Certification
                </h3>
                <Button
                  type="button"
                  variant="outline"
                  size="sm"
                  onClick={() => removeCertification(index)}
                >
                  Remove
                </Button>
              </div>
              <CertificationFields form={form} index={index} />
            </div>
          </div>
        );
      })}
      {showTypeSelect && (
        <div className="space-y-4">
          <Select onValueChange={handleTypeSelect}>
            <SelectTrigger>
              <SelectValue placeholder="Select certification type" />
            </SelectTrigger>
            <SelectContent>
              {Object.entries(CERTIFICATION_TYPE_MAP).map(
                ([type, config]) =>
                  !currentTypes.has(type as CertificationType) && (
                    <SelectItem key={type} value={type}>
                      {config.selectLabel}
                    </SelectItem>
                  ),
              )}
            </SelectContent>
          </Select>
        </div>
      )}
      {!showTypeSelect &&
        currentTypes.size < Object.keys(CERTIFICATION_TYPE_MAP).length && (
          <Button
            type="button"
            variant="outline"
            onClick={() => setShowTypeSelect(true)}
            className="flex items-center gap-2"
          >
            <Plus className="h-4 w-4" />
            Add Certification
          </Button>
        )}
    </div>
  );

  return (
    <Form {...form}>
      {errorMessage && (
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-6 w-6" />
          <AlertDescription className="ml-2">{errorMessage}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        {formFields}
        <SaveButton saveStatus={saveStatus} />
        <Button
          className="w-full p-6"
          type="button"
          variant="outline"
          onClick={() => onSave()}
        >
          Cancel
        </Button>
      </form>
    </Form>
  );
}
