import React, { useState, useEffect } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Info } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Storage } from "@/common/utils/storage";

interface RememberMeProps {
  onChange?: (checked: boolean) => void;
}

const STORAGE_KEY = "remember_me_enabled";

export const RememberMe: React.FC<RememberMeProps> = ({
  onChange,
}: {
  onChange?: (checked: boolean) => void;
}) => {
  const [checked, setChecked] = useState<boolean>(() => {
    // Initialize from localStorage if available
    if (typeof window !== "undefined") {
      const stored = Storage.getItem(STORAGE_KEY);
      return stored === "true";
    }
    return false;
  });

  useEffect(() => {
    // Update localStorage when checked state changes
    if (typeof window !== "undefined") {
      Storage.setItem(STORAGE_KEY, checked.toString());
    }
  }, [checked]);

  const handleChange = (value: boolean) => {
    setChecked(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <Checkbox
        id="remember-me"
        checked={checked}
        onCheckedChange={handleChange}
      />
      <label
        htmlFor="remember-me"
        className="font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        Remember me
      </label>
      <Popover>
        <PopoverTrigger asChild>
          <button className="inline-flex items-center justify-center text-gray-500 hover:text-gray-700">
            <Info className="h-4 w-4" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="w-80 p-3">
          <div className="space-y-2">
            <h4 className="font-medium">Remember Me</h4>
            <p className="text-sm text-gray-600">
              When enabled, you will remain logged in for 7 days on this device.
              Do not enable this option on public or shared devices.
            </p>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default RememberMe;
