import { callSupabaseFnFromClient } from "@/ajax/utils/callSupabaseFnFromClient";

export type ChatThreadMessage = {
  id: string;
  created_at: string;
  content: any; // JSONB type
  sender: {
    id: string;
    first_name: string;
    last_name: string;
    organization_name: string;
  };
  is_me: boolean;
};

export const getChatThreadMessages = async ({
  threadId,
}: {
  threadId: string;
}) => {
  return callSupabaseFnFromClient<ChatThreadMessage[]>({
    fnName: "get_chat_thread_messages",
    args: {
      thread_id: threadId,
    },
  });
};
