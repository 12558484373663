import { supabase } from "@/ajax/clients/supabase";
import { Database } from "@/common/types/database.types";
import { sleep } from "@/common/utils/sleep";
import { PostgrestError } from "@supabase/supabase-js";

export const callSupabaseRpc = <
  FnName extends keyof Database["public"]["Functions"],
  Args extends Database["public"]["Functions"][FnName]["Args"],
>({
  fnName,
  args,
}: {
  fnName: FnName;
  args?: Args;
}) => {
  return supabase.rpc(fnName, args);
};

export const callSupabaseFnFromClient = async <RT>(
  params: Parameters<typeof callSupabaseRpc>[0] & { retryCount?: number },
): Promise<RT> => {
  const { retryCount = 0, ...rest } = params;

  let currentRetryCount = 0;
  let lastError: PostgrestError | null = null;
  do {
    const { error, data } = await callSupabaseRpc(rest);
    if (!error) {
      return data as RT;
    }
    currentRetryCount++;
    lastError = error;
    if (currentRetryCount < retryCount) {
      await sleep(currentRetryCount * 1000);
    }
  } while (currentRetryCount < retryCount);

  console.log("Error in callSupabaseFnFromClient:", lastError);
  throw lastError;
};

export const callSupabaseFnFromClientNoThrow = async <RT>(
  params: Parameters<typeof callSupabaseRpc>[0],
): Promise<{ data: RT | null; error: PostgrestError | null }> => {
  const { error, data } = await callSupabaseRpc(params).overrideTypes<RT>();

  return { data: data as RT, error };
};
