import { supabase } from "../clients/supabase";

export const updateFile = async ({
  id,
  title,
  typeId,
  expiresAt,
  expiryNotificationsEnabled,
  switchUserId,
}: {
  id: string;
  title: string;
  typeId: number;
  expiresAt: string;
  expiryNotificationsEnabled?: boolean;
  switchUserId?: string;
}) => {
  const { data, error } = await supabase.rpc("update_user_credentials_file", {
    id,
    title,
    type_id: typeId,
    expires_at: expiresAt,
    expiry_notifications_enabled: expiryNotificationsEnabled,
    switch_user_id: switchUserId,
  });
  return { data, error };
};
