import { Capacitor } from "@capacitor/core";
import { Browser } from "@capacitor/browser";

export const ExternalLink = ({
  href,
  children,
  className,
  popover = false,
}: {
  href: string;
  children: React.ReactNode;
  className?: string;
  popover?: boolean;
}) => {
  return (
    <a
      onClick={(e) => {
        const isNative = Capacitor.isNativePlatform();
        if (popover && isNative) {
          e.preventDefault();
          Browser.open({ url: href, presentationStyle: "popover" });
          return false;
        }
      }}
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
