import { supabase } from "@/ajax/clients/supabase";
export const createFiles = async ({
  fileData,
}: {
  fileData: { file_name: string; user_credentials_file_id: string | null }[];
}) => {
  const { data, error } = await supabase
    .rpc("create_or_update_user_credentials_file_versions", {
      file_data: fileData,
    })
    .overrideTypes<
      {
        id: string;
        file_name: string;
      }[]
    >();
  return { data: data as { id: string; file_name: string }[], error };
};
