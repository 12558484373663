import { useJsApiLoader } from "@react-google-maps/api";
import { getEnvVar } from "@/common/utils/environment";
import AsyncSelect from "react-select/async";
import { Input } from "@/components/ui/input";
import { USStateSelect } from "@/components/Selects/USStateSelect";
import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

interface AddressInputProps {
  value: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zipCode: string;
    lat?: number;
    lng?: number;
  };
  onChange: (value: {
    address1: string;
    address2?: string;
    city: string;
    state: string;
    zipCode: string;
    lat?: number;
    lng?: number;
  }) => void;
  error?: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zipCode?: string;
  };
}

export function AddressInput({ value, onChange, error }: AddressInputProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: getEnvVar("VITE_PUBLIC_GOOGLE_MAPS_API_KEY") || "",
    libraries: ["places"],
  });

  const loadAddressOptions = async (inputValue: string) => {
    if (!isLoaded) return [];

    const autocompleteService = new google.maps.places.AutocompleteService();

    try {
      const predictions = await autocompleteService.getPlacePredictions({
        input: inputValue,
        types: ["address"],
        componentRestrictions: { country: "us" },
      });

      return predictions.predictions.map((prediction) => ({
        value: prediction.place_id,
        label: prediction.description,
      }));
    } catch (error) {
      console.error("Error fetching address suggestions:", error);
      return [];
    }
  };

  const handleAddressSelect = async (selected: any) => {
    if (!selected || !isLoaded) return;

    const geocoder = new google.maps.Geocoder();

    try {
      const result = await geocoder.geocode({ placeId: selected.value });

      if (result.results[0]) {
        const addressComponents = result.results[0].address_components;
        let city = "",
          state = "",
          zipCode = "";

        addressComponents.forEach((component) => {
          if (
            component.types.includes("locality") ||
            component.types.includes("sublocality")
          ) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (component.types.includes("postal_code")) {
            zipCode = component.long_name;
          }
        });

        const location = result.results[0].geometry.location;

        onChange({
          ...value,
          address1: result.results[0].formatted_address.split(",")[0],
          city,
          state,
          zipCode,
          lat: location.lat(),
          lng: location.lng(),
        });
      }
    } catch (error) {
      console.error("Error geocoding address:", error);
    }
  };

  return (
    <div className="space-y-4">
      <FormItem>
        <FormLabel>Street Address</FormLabel>
        <FormControl>
          <AsyncSelect
            value={{ label: value.address1, value: value.address1 }}
            loadOptions={loadAddressOptions}
            onChange={(selected) => {
              onChange({
                ...value,
                address1: selected?.label || "",
                lat: undefined,
                lng: undefined,
              });
              handleAddressSelect(selected);
            }}
            placeholder="Enter street address"
            classNames={{
              control: () => "py-2",
            }}
          />
        </FormControl>
        {error?.address1 && <FormMessage>{error.address1}</FormMessage>}
      </FormItem>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <FormItem>
          <FormLabel>Suite, Floor, etc.</FormLabel>
          <FormControl>
            <Input
              value={value.address2 || ""}
              onChange={(e) => onChange({ ...value, address2: e.target.value })}
              placeholder="Suite, Floor, etc."
            />
          </FormControl>
          {error?.address2 && <FormMessage>{error.address2}</FormMessage>}
        </FormItem>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <FormItem>
          <FormLabel>City</FormLabel>
          <div className="py-2 px-3 border rounded-md bg-gray-50">
            {value.city}
          </div>
          {error?.city && <FormMessage>{error.city}</FormMessage>}
        </FormItem>

        <FormItem>
          <FormLabel>State</FormLabel>
          <div className="py-2 px-3 border rounded-md bg-gray-50">
            {value.state}
          </div>
          {error?.state && <FormMessage>{error.state}</FormMessage>}
        </FormItem>

        <FormItem>
          <FormLabel>ZIP Code</FormLabel>
          <div className="py-2 px-3 border rounded-md bg-gray-50">
            {value.zipCode}
          </div>
          {error?.zipCode && <FormMessage>{error.zipCode}</FormMessage>}
        </FormItem>
      </div>
    </div>
  );
}
