import { createBillingSession } from "@/ajax/billing/createBillingSession";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useState, useEffect } from "react";

export default function StripeOnboarding({
  onComplete,
}: {
  onComplete: () => void;
}) {
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const { clientSecret } = await createBillingSession();
      return clientSecret;
    };
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: import.meta.env.VITE_PUBLIC_STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#625afa",
        },
      },
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectAccountOnboarding onExit={onComplete} />
    </ConnectComponentsProvider>
  );
}
