import * as React from "react";
import { User } from "lucide-react";
import { format } from "date-fns";

import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { Input } from "@/components/ui/input";
import { AdminSearchUser, getUsers } from "../../../../ajax/users/getUsers";
import { debounce } from "@/common/utils/debounce";
import { Badge } from "@/components/ui/badge";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useForm } from "react-hook-form";
import { adminUpdateUser } from "@/ajax/users/adminUpdateUser";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

export const Route = createFileRoute("/_admin/admin/users/")({
  component: UsersPage,
});

const editUserSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email address"),
});

type EditUserFormData = z.infer<typeof editUserSchema>;

function EditUserDialog({
  user,
  onSuccess,
}: {
  user: AdminSearchUser;
  onSuccess: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditUserFormData>({
    resolver: zodResolver(editUserSchema),
    defaultValues: {
      email: user.email,
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: adminUpdateUser,
    onSuccess: () => {
      onSuccess();
    },
  });

  const onSubmit = (data: EditUserFormData) => {
    updateUserMutation.mutate({
      email: data.email,
      userId: user.id,
    });
  };

  return (
    <DialogContent className="sm:max-w-[425px] min-w-[425px]">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogHeader>
          <DialogTitle>Edit User</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input id="email" {...register("email")} />
            {errors.email && (
              <p className="text-sm text-red-500">{errors.email.message}</p>
            )}
          </div>
        </div>
        <DialogFooter>
          <Button type="submit" disabled={updateUserMutation.isPending}>
            {updateUserMutation.isPending ? "Saving..." : "Save changes"}
          </Button>
        </DialogFooter>
      </form>
    </DialogContent>
  );
}

function UsersPage() {
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [editingUser, setEditingUser] = React.useState<AdminSearchUser | null>(
    null,
  );
  const [sortConfig, setSortConfig] = React.useState<{
    key: keyof AdminSearchUser | null;
    direction: "asc" | "desc";
  }>({
    key: null,
    direction: "asc",
  });

  const usersQuery = useQuery({
    queryKey: ["users", searchQuery],
    queryFn: () => getUsers({ search: searchQuery }),
    staleTime: 5000,
  });

  const sortedUsers = React.useMemo(() => {
    const users = [...(usersQuery.data ?? [])];
    if (!sortConfig.key) return users;

    return users.sort((a, b) => {
      const key = sortConfig.key;
      if (!key || !a[key] || !b[key]) return 0;

      if (a[key] < b[key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [usersQuery.data, sortConfig]);

  const requestSort = (key: keyof AdminSearchUser) => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  return (
    <div className="w-full p-2 pt-4 md:pt-8 md:p-8 min-h-screen bg-gray-100">
      <div>
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-title-md2 font-bold text-black dark:text-white">
            Users
          </h2>
        </div>

        <div className="rounded-md border bg-white animate-fade-in-delay-2">
          <div className="p-4">
            <Input
              placeholder="Search users..."
              onChange={debounce((e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchQuery(e.target.value);
              }, 500)}
              className="max-w-sm"
            />
          </div>

          <Table className="w-full">
            <TableHeader>
              <TableRow>
                <TableHead className="w-[25%]">
                  <Button
                    variant="ghost"
                    className="p-0"
                    onClick={() => requestSort("first_name")}
                  >
                    Name
                  </Button>
                </TableHead>
                <TableHead className="w-[25%]">
                  <Button
                    variant="ghost"
                    className="p-0"
                    onClick={() => requestSort("email")}
                  >
                    Email
                  </Button>
                </TableHead>
                <TableHead className="w-[15%]">
                  <Button
                    variant="ghost"
                    className="p-0"
                    onClick={() => requestSort("created_at")}
                  >
                    Joined
                  </Button>
                </TableHead>
                <TableHead className="w-[10%] text-center">
                  Credentials
                </TableHead>
                <TableHead className="w-[10%] text-center">
                  Onboarding
                </TableHead>
                <TableHead className="w-[15%]">Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedUsers.length ? (
                sortedUsers.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className="w-[25%]">
                      <div className="flex items-center">
                        <User className="min-w-4 w-4 min-h-4 h-4 mr-2" />
                        <div className="font-medium flex items-center gap-2">
                          {user.first_name} {user.last_name}
                          {user.deleted_at && (
                            <Badge variant="destructive">Deleted</Badge>
                          )}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="w-[25%]">{user.email}</TableCell>
                    <TableCell className="w-[15%]">
                      {format(new Date(user.created_at), "MM-dd-yy h:mm a")}
                    </TableCell>
                    <TableCell className="w-[10%] text-center">
                      {user.credential_count}
                    </TableCell>
                    <TableCell className="w-[10%] text-center">
                      {user.max_onboarding_step === 5 ? "Yes" : "No"}
                    </TableCell>
                    <TableCell className="w-[15%] space-x-2">
                      <Button variant="outline" size="sm" asChild>
                        <Link
                          to="/admin/provider-profile/$userId"
                          target="_blank"
                          params={{ userId: user.id }}
                        >
                          View Profile
                        </Link>
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setEditingUser(user);
                        }}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} className="h-24 text-center">
                    <div className="flex flex-col items-center justify-center h-32 sm:h-64 text-center">
                      <User className="w-12 h-12 sm:w-16 sm:h-16 mb-3 sm:mb-4" />
                      <p className="text-lg sm:text-xl font-medium text-gray-900 mb-1 sm:mb-2">
                        No users found
                      </p>
                      <p className="text-sm">Try adjusting your search query</p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
      {editingUser && (
        <Dialog
          open={!!editingUser}
          onOpenChange={() => {
            setEditingUser(null);
          }}
        >
          <EditUserDialog
            user={editingUser}
            onSuccess={() => {
              queryClient.invalidateQueries({ queryKey: ["users"] });
              setEditingUser(null);
            }}
          />
        </Dialog>
      )}
    </div>
  );
}
