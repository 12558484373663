import { supabase } from "@/ajax/clients/supabase";

export function listenForMessages({
  userId,
  orgBusinessUnitIds,
  onMessage,
}: {
  userId: string;
  orgBusinessUnitIds: string[];
  onMessage: (message: {
    id: string;
    content: {
      text: string;
    };
    threadId: string;
  }) => void;
}) {
  // const channels = [
  //   ...orgBusinessUnitIds.map((orgBusinessUnitId) =>
  //     supabase.channel(`chat.obu.${orgBusinessUnitId}`)
  //   ),
  //   supabase.channel(`chat.user.${userId}`),
  // ];
  // channels.forEach((channel) => {
  //   channel
  //     .on("broadcast", { event: "message" }, ({ payload }) => {
  //       console.log('Received message', payload);
  //       onMessage(payload);
  //     })
  //     .subscribe();
  // });

  return {
    unsubscribe: async () => {
      // await Promise.allSettled(
      //   channels.map((channel) => {
      //     channel.unsubscribe();
      //   })
      // );
    },
  };
}
