import { createFileRoute } from "@tanstack/react-router";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "@/components/ui/card";
import { Link } from "@tanstack/react-router";
import { Users } from "lucide-react";

export const Route = createFileRoute("/_org/org/home/")({
  component: () => {
    return (
      <div className="container mx-auto py-8 px-4">
        <div className="max-w-5xl mx-auto space-y-6">
          <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Users className="h-5 w-5" />
                Shared Provider Profiles
              </CardTitle>
              <CardDescription>
                Healthcare providers have shared their profiles with you
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Link
                to="/org/provider-profiles"
                className="text-blue-600 hover:underline"
              >
                View all shared profiles →
              </Link>
            </CardContent>
          </Card>

          {/* <Card>
            <CardHeader>
              <CardTitle className="flex items-center gap-2">
                <Building2 className="h-5 w-5" />
                Upgrade to Organization Account
              </CardTitle>
              <CardDescription>
                Get more features to manage your healthcare organization
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <ul className="list-disc list-inside space-y-2">
                <li>Manage credentials for all your staff in one place</li>
                <li>
                  Post job opportunities to thousands of qualified providers
                </li>
                <li>Streamlined onboarding and compliance tracking</li>
                <li>Advanced analytics and reporting tools</li>
              </ul>
              <Button>Get Started</Button>
            </CardContent>
          </Card> */}
        </div>
      </div>
    );
  },
});
