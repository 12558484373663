import { HelpCircle, File, FileText } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { ExpirationText } from "@/features/documents/components/Badge/ExpirationText";

interface DocumentFile {
  id: string;
  file_name: string;
  type_id: number | null;
  generated_title: string | null;
  expires_at: string | null;
  renew_url?: string;
  renewals?: Array<{
    expires_at: string;
  }>;
}

interface DocumentListItemProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  files?: DocumentFile[];
  onEditFile: (fileId: string) => void;
  showRequiredMessage?: boolean;
  showGeneratedTitle?: boolean;
}

export const DocumentListItem = ({
  icon,
  title,
  description,
  files,
  onEditFile,
  showRequiredMessage,
  showGeneratedTitle = true,
}: DocumentListItemProps) => {
  return (
    <li>
      <div className="flex items-center">
        {icon}
        <span className="text-gray-600">{title}</span>
        <Popover>
          <PopoverTrigger className="ml-1">
            <HelpCircle className="h-4 w-4 text-gray-400 hover:text-gray-600" />
          </PopoverTrigger>
          <PopoverContent className="max-w-xs text-sm">
            <p>{description}</p>
            {showRequiredMessage && (
              <p className="mt-2 text-red-700 font-bold">
                Required - Please upload this document using the upload area
                above
              </p>
            )}
          </PopoverContent>
        </Popover>
      </div>
      {files && files.length > 0 && (
        <ul className="ml-4 mt-1 space-y-2">
          {files.map((file, fileIndex) => (
            <li
              key={fileIndex}
              className="flex items-start justify-between gap-2"
            >
              <div
                className="flex-1 flex items-center cursor-pointer"
                onClick={() => onEditFile(file.id)}
                tabIndex={0}
                role="button"
                aria-label={`Edit ${file.file_name}`}
              >
                <FileText className="mr-2 m-h-6 m-w-6" />
                <div className="flex flex-col">
                  <span>
                    {file.generated_title
                      ? file.generated_title
                      : file.file_name}
                  </span>
                  <div className="flex items-center gap-2 text-sm">
                    <div>
                      {file.expires_at && (
                        <ExpirationText expiresAt={file.expires_at} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};
