import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import React, { useEffect, useState } from "react";
import { useProviderUser } from "@/hooks/useProviderUser";
import { User, Share, Camera } from "lucide-react";
import { supabase } from "@/ajax/clients/supabase";
import { Link, useRouter } from "@tanstack/react-router";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { SharingProfileModal } from "@/features/profile/components/SharingProfileModal";
import { getProfileImage } from "@/ajax/profile/getProfileImage";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export const UserAvatar = ({ minimal = false }: { minimal?: boolean }) => {
  const providerUser = useProviderUser();
  const router = useRouter();
  const [showSupportDialog, setShowSupportDialog] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showSharingModal, setShowSharingModal] = useState(false);

  const [profileImage, setProfileImage] = useState<string | null>(null);
  useEffect(() => {
    (async () => {
      if (providerUser?.profile_image_path) {
        const url = await getProfileImage(providerUser.profile_image_path);
        setProfileImage(url);
      }
    })();
  }, [providerUser?.profile_image_path]);

  const onSignOutClick = async () => {
    const { error } = await supabase.auth.signOut();
    router.navigate({
      to: "/auth-provider/signin",
    });
  };

  const supportEmail =
    import.meta.env.VITE_SUPPORT_EMAIL || "hello@saileapp.com";

  const name = providerUser.credentials.find(
    (credential) => credential.code === "name",
  )?.value as { first_name: string; last_name: string };

  const handleSupportClick = () => {
    setDropdownOpen(false);
    setShowSupportDialog(true);
  };

  const handleDialogClose = () => {
    setShowSupportDialog(false);
    setDropdownOpen(false);
  };

  return (
    <>
      <Dialog open={showSupportDialog} onOpenChange={handleDialogClose}>
        <DialogContent className="sm:max-w-md w-[calc(100%-2rem)] sm:w-full">
          <DialogHeader className="pr-8">
            <DialogTitle>Support Contact</DialogTitle>
          </DialogHeader>
          <p className="text-center py-4">
            Please email us at:{" "}
            <span className="font-medium">{supportEmail}</span>
          </p>
        </DialogContent>
      </Dialog>

      <SharingProfileModal
        open={showSharingModal}
        onOpenChange={setShowSharingModal}
      />

      <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
        <DropdownMenuTrigger asChild>
          <Button size="icon" variant="ghost" className="rounded-full">
            <Avatar className="h-12 w-12 cursor-pointer">
              {profileImage ? (
                <AvatarImage
                  src={profileImage}
                  alt={
                    providerUser.first_name
                      ? `${providerUser.first_name} ${providerUser.last_name || ""}`
                      : "Profile"
                  }
                />
              ) : (
                <AvatarFallback className="bg-gray-700">
                  {name?.first_name && name?.last_name ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 200"
                      className="w-full h-full"
                    >
                      <text
                        x="100"
                        y="125"
                        fontFamily="Arial, sans-serif"
                        fontSize="80"
                        textAnchor="middle"
                        fill="#ffffff"
                      >
                        {`${name?.first_name[0]}${name?.last_name[0]}`}
                      </text>
                    </svg>
                  ) : (
                    <User className="w-5 h-5" color="white" />
                  )}
                </AvatarFallback>
              )}
            </Avatar>

            <span className="sr-only">Toggle user menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel className="truncate">
            {providerUser.email}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {/* <DropdownMenuItem onClick={handleShareClick}>
            Share Profile
          </DropdownMenuItem> */}
          {!minimal && (
            <DropdownMenuItem asChild>
              <Link to="/provider/settings" className="w-full">
                Settings
              </Link>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={handleSupportClick}>
            Support
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="cursor-pointer" onClick={onSignOutClick}>
            Sign out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
