import { supabase } from "../clients/supabase";

export type Specialty = {
  id: string;
  code: string;
  grouping: string;
  classification: string;
  name: string;
  is_parent: boolean;
};
export const getSpecialtyCodes = async (
  codes: string[],
): Promise<Specialty[]> => {
  const { data, error } = await supabase
    .rpc("get_medical_specialties")
    .in("code", codes);

  if (error) {
    throw error;
  }

  return data;
};
