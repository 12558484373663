import { Capacitor } from "@capacitor/core";
import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "./ui/dialog";
import { Storage } from "@/common/utils/storage";
import AppleLogo from "./icons/AppleLogo";
import { ExternalLink } from "./Button/ExternalLink";

const APP_DOWNLOAD_DISSMISSED_AT_KEY = "appDownloadDismissedAt";
export const DownloadApp = () => {
  const [showAppDialog, setShowAppDialog] = useState(false);

  useEffect(() => {
    const isMobileWeb =
      /iPhone|iPad/i.test(navigator.userAgent) && !Capacitor.isNativePlatform();
    if (!isMobileWeb) return;

    const lastDismissed = Storage.getItem(APP_DOWNLOAD_DISSMISSED_AT_KEY);
    const showDialog =
      !lastDismissed ||
      Date.now() - parseInt(lastDismissed) > 24 * 60 * 60 * 1000;

    if (showDialog) {
      setShowAppDialog(true);
    }
  }, []);

  const handleDismiss = () => {
    Storage.setItem(APP_DOWNLOAD_DISSMISSED_AT_KEY, Date.now().toString());
    setShowAppDialog(false);
  };

  return (
    <Dialog open={showAppDialog} onOpenChange={setShowAppDialog}>
      <DialogContent
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        className="sm:max-w-[425px] rounded-lg border shadow-lg bg-white dark:bg-gray-900"
      >
        <DialogHeader className="space-y-3 pb-2">
          <DialogTitle className="text-2xl font-semibold tracking-tight text-center">
            Use Our iOS App
          </DialogTitle>
          <p className="text-gray-600 dark:text-gray-400 text-center">
            Download our native iOS app for the best experience with enhanced
            features and performance
          </p>
        </DialogHeader>
        <div className="space-y-6 py-4">
          <div className="flex flex-col items-center space-y-4">
            <ExternalLink
              className="w-full md:w-auto bg-gray-800 text-white px-8 py-3 rounded-lg text-lg inline-block hover:bg-gray-900 transition-colors flex items-center justify-center"
              href="https://apps.apple.com/us/app/saile-medical/id6738873421"
            >
              <AppleLogo className="h-5 w-5 mr-2" fill="#fff" />
              Download iOS App
            </ExternalLink>
            <button
              onClick={handleDismiss}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 underline"
            >
              Keep using the web version
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
